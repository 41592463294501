<template>

    <modal>

        <!-- Заголовок -->
        <template v-slot:title>{{ $t('Гайд') }}</template>  

        <!-- Кнопки навбару вікна -->
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <template v-slot:body>

            <div class="" >
                <div class="card-body p-5" >
                    <div class="text-center" >
                        <h3>Гайд/пояснення на створення баг-репорту</h3>
                        <p class="mb-0 text-muted">Останнє оновлення: 13.09.2024</p>
                    </div>
                </div>
            </div>

            <div >
                <h5>Про баг-репорт</h5>
                <p class="text-muted">Баг-репорт — це важливий інструмент для розробників, що допомагає зрозуміти проблему, її джерело та пріоритет. Нижче наведені рекомендації для створення ефективного баг-репорту.</p>
            </div>

            <div class="preconditions mt-1 pb-3" >
                <div class="header-line-container" >
                    <h6 class="fw-semibold text-uppercase" >
                        Баг-репорт складається з
                    </h6>
                </div>
                <ul class="ps-4 vstack gap-2 mt-3 text-muted fs-14" >
                    <li>
                        <p>
                            <strong>Проект</strong>:<br />
                            Проект, в якому була знайдена помилка (наприклад, POS-термінал, адмін-панель, чек по смс та інше).
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Назва репорту</strong>:<br />
                            Назва має бути інформативною і відповідати на питання:
                        </p>

                        <ul>
                            <li>Що? (яка помилка)</li>
                            <li>Де? (в якому модулі/розділі проекту)</li>
                            <li>Коли? (під час якої дії або в якому сценарії)</li>
                        </ul>

                        <p class="mt-2"><strong>Приклад</strong>: "Помилка 'Не вистачає коштів' при проведенні надходження товару в пос-терміналі"</p>
                    </li>

                    <li>
                        <p>
                            <strong>Версія фікс</strong>:<br />
                            Вкажіть версію програми, в якій тестували завдання.
                        </p>

                        <p class="mt-2"><strong>Приклад</strong>: 1.34</p>
                    </li>

                    <li>
                        <p>
                            <strong>Серйозність дефекту</strong>:<br />
                            Виберіть рівень серйозності дефекту:
                        </p>

                        <ul>
                            <li><b>Блокуючий (blocker)</b> - помилка повністю блокує та унеможливлює роботу застосунку, немає жодного способу обійти її. Виправлення багу необхідне для функціонування системи.</li>
                            <li><b>Критична помилка (critical)</b> - блокує частину функціоналу, але є шлях для її обходу. Рішення проблеми необхідне для подальшої роботи з ключовим функціоналом.</li>
                            <li><b>Значна (major)</b> - некоректна робота частини функціональності (функція працює, але неправильно). Така помилка не критична, є можливість роботи з функціоналом із використанням інших вхідних точок.</li>
                            <li><b>Незначна (minor)</b> - незначні огріхи, помилки, які не відноситься до функціональності, не порушують бізнес-логіку. Зазвичай це помилки в UI.</li>
                            <li><b>Тривіальний (trivial)</b> - дефект не зачіпає функціональність, суттєво не впливає на загальну якість продукту, часто пов’язаний із помилкою сторонніх бібліотек або сервісів.</li>
                            <li><b>Незручність (tweak)</b> - проблема стосується суто інтерфейсу, необхідно «підігнати» його.</li>
                            <li><b>Текстова помилка (text)</b> - одрук, орфографічна чи пунктуаційна помилка.</li>
                        </ul>

                    </li>

                    <li class="mt-2">
                        <p>
                            <strong>Пріоритет</strong>:<br />
                            Виберіть пріоритет виправлення:
                        </p>

                        <ul>
                            <li><b>Високий пріоритет (high)</b> - потрібно виправити помилку якомога швидше, адже вона є критичною, блокує тестування і може призвести до великих фінансових втрат.</li>
                            <li><b>Середній пріоритет (medium)</b> - помилка не є критичною, її можна пофіксити у другу чергу, після виправлення багів із високим пріоритетом.</li>
                            <li><b>Низький пріоритет (low)</b> - помилка не потребує термінового усунення, нічого поганого не станеться, якщо виправити її в останню чергу.</li>
                        </ul>
                        
                    </li>

                    <li class="mt-2">
                        <p>
                            <strong>Короткий опис</strong>:<br />
                            Наведіть коротке формулювання проблеми (наприклад, "Помилка при обробці надходження товару в POS-терміналі"). Поле не є обов'язковим. 
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Як зробити</strong>:<br />
                            Опишіть покроково всі дії, які привели до виникнення багу. Інформація має бути деталізованою, щоб її можна було легко відтворити. 
                        </p>

                        <p><strong>Приклад:</strong></p>
                        <ol>
                            <li>Відкрити POS-термінал.</li>
                            <li>Натиснути Меню - Створити документ.</li>
                            <li>Немаж кнопки "Надходження товару"</li>
                        </ol>

                    </li>

                    <li>
                        <p>
                            <strong>Фактичний результат</strong>:<br />
                            Опишіть результат, який виникає після виконання дій.
                        </p>

                        <p class="mt-2"><strong>Приклад</strong>: Після натискання кнопки "Створити документ" відкривається вікно без кнопки "Створити надходження".</p>
                    </li>

                    <li>
                        <p>
                            <strong>Очікуваний результат</strong>:<br />
                            Опишіть, що має відбуватися замість фактичного результату.
                        </p>

                        <p class="mt-2"><strong>Приклад</strong>: Програма повинна надати можливість створення документу надходження, проведення його та відобразити повідомлення про успішне проведення операції.</p>
                    </li>

                    <li>
                        <p>
                            <strong>Середовище тестування</strong>:<br />
                            Вкажіть середовище, на якому прововодилось тестування
                        </p>

                        <ul>
                            <li>
                                <p>
                                    <strong>Операційна система</strong>:<br />
                                    Оберіть ОС, де виникла проблема, наприклад, <strong>Windows</strong>.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Версія ОС</strong>:<br />
                                    Вкажіть точну версію ОС, наприклад, Windows 10.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Застосунок для тестування</strong>:<br />
                                    Вкажіть назву програми або модуль, в якому тестувалася система.
                                </p>
                            </li>
                        </ul>

                    </li>

                    <li>
                        <p>
                            <strong>Додаткові файли</strong>:<br />
                            Прикріпіть до баг-репорту скріншоти, логи або відео, що ілюструють проблему. Це значно допоможе розробникам при відтворенні та пошуку джерела помилки.
                        </p>

                    </li>

                </ul>
            </div>

        </template>

    </modal>

</template>

<script>
import modal from '@/components/modal-small'

export default {
    components: {
        modal
    },
    data() {
        return {
            form: ''
        }
    },
}
</script>
