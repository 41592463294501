<template>    

    <!-- Кнопка ручного оновлення списку чеків -->
    <div class="btn btn-success" style="margin-bottom: 20px;" @click="this.getdata()">{{ this.$t('refresh') }}</div>

    <!-- Таблиця чеків 
        :columns - передаємо колонки
        :rows - передаємо дані
        :showpag - вмикаємо пагінацію
        :pages - передаємо дані про сторінку
        :objParams - передаємо інформацію про існуючі фільтри
        @getdata - подія на отримання даних
        @changelimit - подія на зміну ліміту
        @open - подія відкриття картки документу
    -->
    <tablecustom 
        :columns="columns" 
        :rows="rows" 
        :showpag="true"
        :pages="this.pages"
        :objParams="this.objPages"
        @getdata="this.getdata"
        @changelimit="this.changelimit"
        @open="open"
    />

    <!-- Поле, для завантаження файлів  
        :title - передаємо назву поля
        :obj - передаємо пустий об'єкт
        :objFiles - передаємо пустий масив для файлів 
        :topic - це допоможе нам фільтрувати файли
        @uploadFiles - подія обробки завантаження файлів
    -->
    <files 
        :title="this.$t('Upload_checks')"
        :obj="{}" 
        :objFiles="[]" 
        :topic="'checks'"
        @uploadFiles="uploadFiles"
    />

    <!-- Картка перегляду чеку 
        :check - передаємо чек, котрий хочемо переглянути
        @close - подія закриття картки перегляду чеку
    -->

    <viewCheck
        v-if="this.showCheck === true"
        :check="this.check"
        @close="this.showCheck = false"
    />

</template>

<script>
import viewCheck from './viewCheck.vue'; // комопнент перегляду чеку
import tablecustom from '@/components/globaltable/index.vue'; // комопнент таблиці
import files from '@/components/files/index.vue'; // комопнент завантаження файлів
import { Documents } from "@/API_Dash.js" // API
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiDocs = new Documents()

export default {
    props: ['client', 'obj'],
    components: {
        viewCheck,
        tablecustom,
        files
    },
    data() {
        return {
            showTable: false,
            objPages: {
                page: "1",
                pagelimit: "25",
                companies: "",
                workerId: "",
                time: "",
                page: ''
            },
            rows: [],
            columns: [
                {
                    name: "ID",
                    text: "reportId",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Employee"),
                    text: "",
                    align: "left",
                    status: true,
                    mutate: (item, obj) => this.workerMutate(item, obj)
                },
                {
                    name: this.$t("date_time"),
                    text: "datetime",
                    align: "left",
                    status: true,
                }
            ],
            showCheck: false,
            check: {},
            pages: {}
        }
    },
    created() {

    },
    mounted() {
        this.getdata()
    },
    methods: {
        // Зміна ліміту
        changelimit(limit) {
            this.objPages.pagelimit = limit
            this.getdata()
        },
        // Мутейт співробітника
        workerMutate(item, obj) {
            return obj.worker.workerName
        },
        // Функція завантаження файлів
        uploadFiles(obj, objData){
            // Завантаження файлів

            console.log("obj data", obj, objData)
            console.log("client", this.client, this.obj.companyId)

            apiDocs.reportChecks(this.obj.companyId, objData).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('FileUploadedSuccessfully'));
                    this.getdata()
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка додавання чеків'; 
                    var statusRequest = "error";
                    var api = 'reportChecks';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/activity/checks/index.vue';
                    var params = JSON.stringify([this.obj.companyId]);
                    var response = result.status

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        getdata(page) {
            // функція отримання даних
            if(page) {
                this.objPages.page = page
            }
            this.objPages.companies = []
            this.objPages.companies.push(this.obj.companyId)

            this.objPages.companies = JSON.stringify(this.objPages.companies)

            apiDocs.getChecks(this.objPages).then(result => {
                if(result.status === 'done') {
                    this.rows = result.data.reports
                    this.pages.links = result.data.links
                    this.pages.total = result.data.total
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка отримання чеків'; 
                    var statusRequest = "error";
                    var api = 'getChecks';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/activity/checks/index.vue';
                    var params = JSON.stringify(this.objPages);
                    var response = result.status

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        open(e) {
            apiDocs.getCheck(e.reportId).then(result => {
                if(result.status === 'done') {
                    this.check = result.data
                    this.showCheck = true
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка отримання чеку'; 
                    var statusRequest = "error";
                    var api = 'getCheck';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/activity/checks/index.vue';
                    var params = e.reportId
                    var response = result.status

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        }
    }
};
</script>

<style lang="css">
.filepond--browser, .filepond--drop-label{
    cursor: pointer;
}
</style>