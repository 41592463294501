<template>
    <!-- Компонент обрыззки зображення -->
    <modal>
        <template v-slot:title>{{ $t('image') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="this.$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>

            <!-- Перегляд зображення та його обрізка -->
            <div 
                v-if="this.img.src"
                class="img_crop_fullSize"
                style = "position: absolute; z-index: 1;"
            >
                <div class="img_crop_fullSize_body">
                    <div class="workSpace">
                        <div 
                            v-if="this.img.width && this.img.height && this.img.src"
                            class="black_background"
                            :style="
                                `
                                    width: ${this.img.width}px; 
                                    height: ${this.img.height}px; 
                                `
                            "
                        >
                            <img class="cropImage" :src="img.src">
                            <div
                                class="crop_area"
                                :style="`box-shadow: ${this.findCord().x}px ${this.findCord().y}px 0px 20px rgb(0 0 0 / 60%)`"    
                            >

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </template>
        <template v-slot:footer-bottom >
            <button class="btn btn-success">
                {{ this.$t('save') }}
            </button>
        </template>
    </modal>

</template>

<script>
import modal from '@/components/modal-small'

export default {
    data() {

    },
    props: ['img'],
    components: {
        modal
    },
    methods: {
        findCord() {
            return {
                x: 0,
                y: 0
            }
        }
    },
    created() {
        console.log('img', this.img)
    }
}
</script>

<style>
.img_crop_fullSize {
    width: 90%;
    height: 90%;
}

.img_crop_fullSize_body{
    height: 100%;
    width: 100%;
}

.workSpace {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cropImage{
    position: absolute;
    z-index: 1;
}

.black_background {
    position: relative;
    z-index: 3;
    background-color: antiquewhite;
}

.crop_area{
    width: 120px;
    height: 120px;
    position: absolute;
    z-index: 2;
}

</style>