<template>
    <b-card>
        <div class="d-flex align-items-center border-bottom mb-3 pb-2 mt-3">

            <div class="flex-grow-1">
                <div class="mt-2 mb-3">
                    <!-- Заголовок для секції з загальними параметрами -->
                    <h5 class="card-title mb-0"><b>{{ $t('Опис') }}</b></h5>
                </div>
            </div>

            <div class="flex-shrink-0">
                <div class="text-muted">
                    <!-- Кнопка для видалення блоку -->
                    <button 
                        type="button" 
                        class="btn btn-danger btn-icon waves-effect waves-light" 
                        @click="removeItem"
                    >
                        <i class="ri-delete-bin-5-line"></i>
                    </button>
                </div>
            </div>

        </div>

        <div class="mb-3 mt-3">
            <!-- Опис до завдання -->
            <label for="VertimeassageInput" class="form-label fs-16">{{ $t('Вкажіть опис до завдання') }}</label>
            <QuillEditor v-model:content="form.comment" ref="quill" :modules="modules" theme="snow" contentType="html" toolbar="full"/>
        </div>

    </b-card>

</template>

<script>
import textqe from '@/components/textQE' // компонент текстового редактора

export default{
    props: ['type', 'activeBlocks'],
    components: {
        textqe
    },
    data(){
        return{
            form: {
                comment: ''
            }
        }
    },
    methods: {
        removeItem(){
            // Метод для видалення поточного блоку
            this.activeBlocks.forEach(element => {
                if(element.type == this.type ){
                    element.status = false;
                    this.$emit('saveBlock', this.activeBlocks)
                }
            });
        }
    }
}
</script>