<template>

    <!-- Блок середовища тестування -->

    <div class="preconditions mt-4">
        <div class="header-line-container">
            <h6 class="fw-semibold text-uppercase">Середовище тестування</h6>
            <hr>
        </div>
        <ul class="ps-4 vstack gap-2 mt-3">
            <template v-for="(item, index) in form.testing_environment" :key="item">
               <li>#{{ index+1 }}
                    <ul>
                        <li><b>Операційна система:</b> {{ item.os + " " + item.version_os }} </li>
                        <li><b>Застосунок:</b> {{ item.app_testing == "appskyservice" ? 'додаток SkyService ' + item.ver_app : item.browser + " " + item.ver_app }} </li>
                    </ul>
               </li>
            </template>
        </ul>
    </div>
    
</template>

<script>
import { mutateStatusChecklist } from '@/usabilityScripts/globalMutate'

export default {
    props: ['objCard'],
    components: {

    },
    data() {
        return {
            form: '',
            showNoteCL: false, // прапорець відображення нотатки
            listStatusChecklist: [ // статуси до пунктів чек листа
                { value: 'passed', text: 'Успішно' },
                { value: 'failed', text: 'Не вдалося' },
                { value: 'blocked', text: 'Блокуючий' },
                { value: 'in_progress', text: 'В роботі' },
                { value: 'not_run', text: 'Не перевірено' },
                { value: 'skipped', text: 'Пропущено' },
            ]
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.objCard
        },
        mutateStatusChecklist(e){
            // мутейт статусу пункту чек листа
            return mutateStatusChecklist(e)
        },
    }
}
</script>

<style scoped>
.header-line-container {
    display: flex;
    align-items: center;
}

.header-line-container h6 {
    margin: 0;
    padding-right: 10px; /* Відступ між заголовком і лінією */
}

.header-line-container hr {
    flex-grow: 1;
    border: none;
    /* border-top: 2px solid #000; /* Можна змінити товщину та колір лінії */
    margin: 0;
}
</style>