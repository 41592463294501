<template>
    <!-- Модальне вікно для змінити плати компанії за підписку/змінити коефіцієнт -->
    <!-- Працює лише для нової адмінки (сервера s-2, s-3, s-4) -->
    <modal>
        <template v-slot:title>{{ $t('ChangeSubscriptionFee') }}</template>
        <template v-slot:body>
            <b-row>
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-16 fw-semibold">{{ $t('SpecifyNewCoefficient') }}</h4>
                        <input class="form-control" :placeholder="contentData.coeficient" v-model="form.newСoefficient" @input="changeCoef($event)" >
                    </div>
                    <div class="mb-3">
                        <h4 class="fs-16 fw-semibold">{{ $t('Cause') }}</h4>
                        <input class="form-control" v-model="form.reason" />
                    </div>
                </b-col>
                <b-col lg="12">
                    <div class="card card-animate" style="border-radius: 8px;">
                        <div class="card-body bg-danger" id="infoStep1" style="border-radius: 8px;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1">
                                    <p class="text-uppercase fw-medium text-white mb-0"><b>{{ $t('SubscriptionFee') }}</b></p>
                                    <span class="text-white fs-12">{{ $t('TakingIntoNewCoeff') }}</span>
                                </div>
                            </div>
                            <div class="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                    <h4 class="fs-22 fw-semibold ff-secondary text-white"><span class="counter-value">{{ dataCalc.newPrice ? dataCalc.newPrice.toFixed(2) : contentData.userSumm.toFixed(2) }} {{ contentData.currency }}</span></h4>
                                </div>
                                <!--v-if-->
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <hr />
            <div class="mb-3">
                <h4 class="fs-16 fw-semibold">{{ $t('CurrentCompanyData') }}</h4>
            </div>
            <b-row>
                <b-col lg="12">
                    <div class="card card-animate"  style="border-radius: 8px; cursor: pointer;">
                        <div class="card-body bg-warning"  style="border-radius: 8px;">
                            <div class="d-flex align-items-center" >
                                <div class="flex-grow-1" >
                                    <p class="text-uppercase fw-medium text-white mb-0" ><b>{{ $t('tariffPlan') }}</b></p>
                                </div>
                            </div>
                            <div class="d-flex align-items-end justify-content-between mt-3" >
                                <div class="breakText" >
                                    <h4 class="fs-22 fw-semibold ff-secondary text-white" ><span class="counter-value" >{{ contentData.tariffName }}</span></h4>
                                </div>
                                <!--v-if-->
                            </div>
                        </div>
                    </div>

                </b-col>
                <b-col lg="12">
                    <div class="card card-animate" style="border-radius: 8px;">
                        <div class="card-body bg-danger" id="infoStep1" style="border-radius: 8px;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1">
                                    <p class="text-uppercase fw-medium text-white mb-0"><b>{{ $t('SubscriptionFee') }}</b></p>
                                </div>
                            </div>
                            <div class="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                    <h4 class="fs-22 fw-semibold ff-secondary text-white"><span class="counter-value">{{ contentData.userSumm }} {{ contentData.currency }}</span></h4>
                                </div>
                                <!--v-if-->
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom >
            <button type="button" class="btn btn-danger" @click="$emit('close')">{{ $t('cancel') }}</button>
            <button type="button" class="btn btn-success"  @click="editCoef">{{ $t('Edit') }}</button>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/dialog'
import { Banking } from '@/API'
import { sendTelegramMessage, sendTelegramDangerOperation } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
import { nameWorker } from "@/usabilityScripts/getNameWorker";
import { storeS } from '@/store';

let apiServe = new Banking();

export default{
    props: ['obj', 'companyId'],
    components: {
        modal,
    },
    data(){
        return{
            contentData:'',
            
            form: {
                newСoefficient: '',
                oldCoefficient: '',
                userPhone: '',
                companyId: '',
                reason: ''
            },
            dataCalc: {
                ex_bases: '',
                coeficient: '',
                ratio: '',
                newPrice: ''
            }
        }
    },
    created(){
        this.getdata()
        
        this.form.companyId = this.companyId
        this.form.userPhone = this.obj.ownerPhone
        this.form.oldCoefficient = this.contentData.coeficient
    },
    methods: {
        getdata(){
            this.contentData = this.obj.companyDataObj.license
            this.dataCalc.ex_bases = this.tariff_cash(this.contentData.tariffName)
            this.dataCalc.coeficient = this.contentData.coeficient;
            this.dataCalc.ratio = this.contentData.userSumm/this.contentData.tariffSumm
        },
        tariff_cash:function (thistariff){
            switch(thistariff){
                case "FREE":
                return "0";
                case "ВUSINESSMAN":
                return "20";
                case "CORPORATION":
                return "35";
                case "BIGBOSS":
                return "50";
                case "ENTERPRISE":
                return "50";
            }
        },
        changeCoef(e){
            this.form.newСoefficient = e.target.value.replace(/[^\d,.]*/g, '')
                                .replace(/([,.])[,.]+/g, '$1')
                                .replace(/,/, '.')
                                .replace(/^[^\d]*(\d+([.,]\d{0,5})?).*$/g, '$1');
            this.dataCalc.newPrice = (this.dataCalc.ex_bases * this.form.newСoefficient ) * this.dataCalc.ratio;
        },
        editCoef(){
            console.log(this.form);
            apiServe.setNewCoeficient(this.form).then(result => {
                if(result.status == 'done'){
                    this.$emit('close')

                    var nameMethod = 'Зміна оплати компанії за підписку/змінити коефіцієнт'; 
                    var phoneClient = this.form.userPhone;
                    var companyId = this.form.companyId;
                    var descText = `Старий коефіцієнт - ${this.form.oldCoefficient}\nСтара сума абонплати - ${this.contentData.userSumm}${this.contentData.currency} \nНовий коефіцієнт - ${this.form.newСoefficient}\nНова сума абонплати - ${this.dataCalc.newPrice.toFixed(2)}${this.contentData.currency} \nПричина - ${this.form.reason}`
                    var createWorkerId = this.nameWorker(this.user.userid);

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramDangerOperation(nameMethod, phoneClient, companyId, descText, createWorkerId)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));

                    this.$toast.success(this.$t('success'))
                } else if(result.status == 'error' && result.error[0] == 'empty company id'){
                    this.$toast.error(this.$t('emptyCompanyId'))
                } else if(result.status == 'error' && result.error[0] == 'access denied'){
                    this.$toast.error(this.$t('accessDenied'))
                } else if(result.status == 'error' && result.error[0] == 'enter data'){
                    this.$toast.error(this.$t('enterData'))
                } else if(result.status == 'error' && result.error[0] == 'no auth'){
                    this.$toast.error(this.$t('noAuth'))
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка зміни плати компанії за підписку/змінити коефіцієнт'; 
                    var statusRequest = result.status;
                    var api = 'setNewCoeficient';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/dangerActions/changeSubsfee.vue';
                    var params = this.form;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        nameWorker(item) {
            return nameWorker(item);
        },
    },
    computed: {
        user() {
            return storeS.userbase;
        },
        perms() {
            return storeS.perms;
        },
    },
}
</script>

<style>
.form-control[readonly] {
    background-color: var(--vz-input-bg)!important;
    opacity: 1;
}
</style>