<template>
  <!-- 
    Компонент магазину, головна сторінка
      - дозволяє переглянути список товарів
      - відкрити картку товару
      - викликати створення товару
      - та інше :)
  -->

  <!-- кнопка відображення інфо-блоків -->
  <div class="col-sm-auto" style="margin-bottom:15px;" v-if="this.checks['001'] == true">
    <button 
      v-if="this.checks['001'] == true && perms[1304]" 
      @click="infoblockShow = !infoblockShow" 
      type="button" 
      :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"
    >
      <i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i>
    </button>
  </div>

  <!-- 
    Інформаційні блоки з підсумком по -
    - усього товарів та на яку суму
    - усього товарів та на яку суму по собівартості
    - усього товарів та можливий прибуток
  -->
  <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />

  <!-- 
    Компонент таблиці
    - :dataParams - параметри до кнопки фільтру
    - :objParams - параметри для фільтрації таблиці 
    - :columns - конфігурація стовпців таблиці
    - :rows - масив з даними рядків таблиці
    - :pages - об'єкт із даними про пагінацію таблиці
    - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
    - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
    - - йде перевірка на права доступу 2000 (режим бога) та 1301 (створення товарів)
    - @create - подія, яка спрацьовує при створенні нового товару
    - @open - подія, яка спрацьовує при відкритті картки товару
    - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
    - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
    - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
  -->
  <tablecustom 
    :dataParams="dataParams"
    :objParams="objParams"
    :columns="columns" 
    :rows="rows" 
    :pages="objPages"
    :showpag="true"
    :createdform="this.perms['1301'] || this.perms['2000']" 
    :removeitem="false"
    @search="searchB" 
    @create="create" 
    @open="open" 
    @getdata="getdata"
    @changelimit="changelimit"
    @changeFilter="changeFilter" 
    @clearFilter="clearFilter" 
  />

  <!-- 
    Виклик відкриття картки товару
    - для відкриття застосовується перевірка значення showModal
    - @close - подія, яка спрацьовує при закритті картки
    - :objcard - параметр-об'єкт з даними по товару
    - @remove - подія, яка спрацьовує при видалені товару
    - @edit - подія, яка вмикає режим редагування
  -->
  <viewbox 
    v-if="showModal" 
    :objcard="objcard"  
    @close="closeModal" 
    @remove="remove"
    @edit = 'edit'
    @copyProduct="copyProduct"
  ></viewbox>

  <!-- 
    Виклик вікна створення картки товару
    - для відкриття застосовується перевірка значення modalCreatedBox
    - @close - подія, яка спрацьовує при закритті картки
    - :perm - параметр режим редагування
    - :obj - параметри товару
  -->
  <createProducts 
    v-if="modalCreatedBox" 
    :perm="this.perm"
    :obj="this.form"
    @close="closeCreate"
  ></createProducts> 

</template>
  
<script>
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import createProducts from './createproduct' // картка створення/редагування товару
import infoblocks from '@/components/info/blocks' // інфо-блоки
import viewbox from './view.vue' // картка товару
import { Products, CategoriesProducts } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних
import { titleCategory } from '@/usabilityScripts/globalMutate.js'
    
let apiServe = new Products();
let apiCategory = new CategoriesProducts();

export default {
  components: {
    tablecustom,
    createProducts,
    viewbox,
    infoblocks
  },
  data() {
    return {
      perm: false, // прапорець для редагування
      modalCreatedBox: false, // прапорець відображення вікна створення товару
      infoblockShow: false, // прапорець відображення інфо-блоків
      showModal: false, // прапорець відображення вікна картки товару
      objcard: "", // Об'єкт з даними товару для відображення в модальному вікні
      objPages: "", // Об'єкт з даними про сторінки для розподілу даних по сторінках
      dataParams: { // Параметри для фільтрації таблиці
          status: true, // true - показати кнопку фільтр, false - сховати
          page: "products"
      },
      objParams: { // Параметри для фільтрації даних
        page: '1',
        pagelimit: '10',
        search: '',
        productsCategoryId: ''
      },
      columns: [
        // Конфігурація колонок таблиці з інформацією про кожну колонку
        // Кожен елемент містить:
        // * name - назву колонки для локалізованого відображення,
        // * text - Ключове слово для отримання значення з даних
        // * align - Вирівнювання
        // * status - Статус відображення колонки
        // * mutate - Функція для мутації значення колонки (переклад)
        // * mutateClass - Функція для мутації класу колонки (стилі)
        {
          name: "ID",
          text: "nomenclatureId",
          align: "left",
          status: true,
        },
        {
          name: this.$t("Product"),
          text: "nomenclatureName",
          align: "left",
          status: true,
        },
        {
          name: this.$t("Categories"),
          text: "productsCategoryId",
          align: "left",
          status: true,
          mutate: (item) => this.titleCategory(item)
        },
        {
          name: this.$t("price"),
          text: "price",
          align: "left",
          status: true,
          mutate: (item) => (item*this.currency.course).toFixed(2) + " " + this.currency.iso
        }
      ],
      rows: [], // Дані для відображення у таблиці
      currency: { // параметри валюти та курсу
        iso: '',
        course: ''
      },
      information: [
        // Дані компонента, які відображають інформацію про кількість та суми
        // Кожен елемент масиву містить:
        // * title - назву інформаційного блоку,
        // * value - кількість у відповідній колонці,
        // * icon - клас іконки,
        // * color - колір інформаційного блоку,
        // * sum - загальну суму у відповідній колонці.
        {
            title: this.$t('Total'),
            value: "0",
            icon: "ri-shopping-bag-2-line",
            color: "info",
            sum: "0"
        },
        {
            title: this.$t('costPrice'),
            value: "0",
            icon: "ri-price-tag-2-line",
            color: "secondary",
            sum: "0"
        },
        {
            title: this.$t('possibleProfit'),
            value: "0",
            icon: "ri-price-tag-2-fill",
            color: "success",
            sum: "0"
        }
      ]
    };
  },
  created() {
    // Отримання даних при завантаженні компоненту
    this.getdata();

    // Умова для визначення валюти та курсу при введенні вартості товару в USD та виведенні в UAH
    if(this.currencyShop.enteringTheCostOfGoods == 'USD' && this.currencyShop.conclusionOfGoods == 'UAH'){
      this.currency.iso = '₴';
      this.currency.course = this.currencyShop.course.USD
    } else if(this.currencyShop.enteringTheCostOfGoods == 'USD' && this.currencyShop.conclusionOfGoods == 'USD'){
      this.currency.iso = '$';
      this.currency.course = 1;
    }
  },
  methods: {
    copyProduct(obj) {
      this.form = obj
      this.modalCreatedBox = true
    },
    closeCreate() {
      // Закриття модального вікна створення/редагування товару
      this.modalCreatedBox = false;
      this.perm = false
      this.getdata(this.objParams.page);
    },
    closeModal(){
      // Закриття модального вікна загальної інформації про товар
      this.showModal = false
      this.getdata(this.objParams.page);
    },
    edit(e) {
      // Метод для відкриття форми редагування товару
      this.form = e
      this.perm = true
      this.modalCreatedBox = true
    },
    getdata(page){
      // Метод для отримання даних товарів

      this.objParams.page = page != undefined ? page : '1';
      apiServe.getAllProducts(this.objParams).then(result => {
        if(result.status == 'done') {
          this.rows = result.data.items
          this.objPages = result.data;

          this.information[0].value = result.data.total;
          this.information[1].value = result.data.total
          this.information[2].value = result.data.total
          this.information[0].sum = result.data.sum + " " + this.currency.iso;
          this.information[1].sum = result.data.cost.toFixed(2) + " " + this.currency.iso;
          this.information[2].sum = result.data.profit.toFixed(2) + " " + this.currency.iso

        } else {
          this.$toast.error(this.$t('error') + ` #1300`);
        }
      })
    },
    create: function(){
      // Відкриття модального вікна створення товару
      this.modalCreatedBox = true
    },
    open: function(e){
      // Відкриття модального вікна інформації про товар

      apiServe.getProduct(e.nomenclatureId).then(result => {
        if(result.status == 'done') {
          this.objcard = result.data;
          this.showModal = true;
        } else {
          this.$toast.error(this.$t('err'))
        }
      })
    },
    changeFilter(name, value){
      /*
        Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
      */

      if(!name.type && value != undefined){
        if(name == 'pr'){
          this.objParams['pr1'] = value[0];
          this.objParams['pr2'] = value[1];
        } else {
          this.objParams[name] = value;
        }
        
        this.getdata();
      }
    },
    searchB(e){
      // Пошук товарів за назвою

      this.objParams.search = e
      this.getdata();
    },
    changelimit: function(limit){
      /*
        Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
      */

      this.objParams.pagelimit = limit;
      this.getdata();
    },
    remove: function(e){
      // Видалення товару
      apiServe.deleteProduct(e.nomenclatureId).then(res => {
        if(res){
          this.showModal = false;
          this.getdata();
        }
      })
      this.$toast.success(this.$t('Removed'));
    },
    titleCategory(e){
      // Отримання назви категорії за її ідентифікатором
      return titleCategory(e)
    }
  },
  computed: {
    checks() {
      /*
        Повертає дані з налаштувань юзера з глобального стору.
      */
      return storeS.checks
    },
    perms(){
      /*
        Повертає дані прав доступу користувача з глобального стору.
      */
      return storeS.perms
    },
    currencyShop(){
      /*
        Повертає дані налаштувань валюти та курсу з глобального стору.
      */
      return storeS.currencyShop
    },
    productCategories(){
      /*
        Повертає дані категорій з глобального стору.
      */
      return storeS.productCategories
    }
  },
  mounted() {
    // Cлухання події saveFines для оновлення даних після збереження товарів
    this.eventBus.off('saveProduct')
    this.eventBus.on('saveProduct', (status) => {
      if(status == true) {
        this.getdata();
      }
    })
  }
};
</script>

<style scoped>
.tr_etner:hover {
  cursor: pointer;
  background: #f3f3f9;
}
</style>