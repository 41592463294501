<template>
    <b-card>
        <div class="card-header">
            <h5 class="card-title mb-0"><b>{{ $t('Comments') }}</b></h5>
        </div>
        <div class="card-body">
            <tablecustom 
                :key="this.componentKey"
                :rows="this.rows"
                :columns="this.columns"
                :tabs="othertabs"
                :objParams="this.objParams"
                :pages="objPages"
                :showpag="this.showpag"
                @changeTab="changeFilter"
                @changeFilter="changeFilter"
                @getdata="this.getClientComments"
            />
        </div>
    </b-card>
</template>

<script>
import tablecustom from '@/components/globaltable/index.vue' 
import { Clients } from '../../../../API';
import {nameWorker} from "@/usabilityScripts/getNameWorker.js"

let apiServe = new Clients()

export default{
   props: ['obj', 'number'],
   data(){
       return {
            monitoringComments: [],
            othertabs: [
                {
                    title: this.$t('Коментарі по картці'),
                    tab: "comments",
                    color: "#3577f1",
                    obj: "comments",
                    value: "client",
                    checked: true
                },
            ],
            rows: '',
            columns: [
            ],
            firstColumns: [
                {
                    name: "ID",
                    text: "id",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Comment"),
                    text: "comment",
                    align: "left",
                    status: true,
                    needFixed: true
                },
                {
                    name: this.$t("Manager"),
                    text: "createWorkerName",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("date_time"),
                    text: "createDatetime",
                    align: "left",
                    status: true,
                },
            ],
            secondColumns: [
                {
                    name: "ID",
                    text: "commentId",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Comment"),
                    text: "text",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Manager"),
                    text: "workerId",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutateWorker(item)
                },
                {
                    name: this.$t("date_time"),
                    text: "date",
                    align: "left",
                    status: true,
                },
            ],
            objParams: {
                pagelimit: 25,
                page: 1
            },
            componentKey: 0,
            objPages: {},
            showpag: false,
        }
   },
   created(){
       this.rows = this.monitoringComments = this.obj       
       this.columns = this.firstColumns
       console.log(this.form)
   },
    methods: {
        changeFilter(label, value) {
            console.log(label, value)
            if(value === "client") {
                this.monitoringComments = this.rows
                this.getClientComments()
            } else if(label === 'all'){
                this.getMonitoringComments()
            }
        },
        getMonitoringComments() {
            this.rows = this.monitoringComments
            this.columns = this.firstColumns
            this.objPages = {};
            this.showpag = false
            this.componentKey++
        },
        getClientComments(page){
            this.objParams.page = page ? page : "1"
            apiServe.getComments(this.number, this.objParams).then(result => {
                if(result.status === 'done'){
                    this.rows = result.data.items
                    this.objPages = result.data;
                    this.showpag = true
                    this.columns = this.secondColumns
                    this.componentKey++
                }
            })
        },
        mutateWorker(item) {
            return nameWorker(item)
        }
    },
   components: {
    tablecustom
   }
}
</script>

<style lang="css" scoped>
.card-body {
    padding: 0.5rem !important;
}
</style>