<template>

    <!-- 
        Модальне вікно для додавання пристрою в картку тестінгу
    -->

    <modal @close="this.$emit('close')">

        <!-- Заголовок -->
        <template v-slot:title>{{ $t('addDevice') }}</template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>
            <b-card>

                <b-row>

                    <b-col lg="6">
                        <b-card-title>
                            <!-- Назва пристрою -->
                            <h4><b>{{$t('Point_name')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" v-model="form.deviceName">
                        </b-card-text>
                    </b-col>

                    <b-col lg="6">
                        <b-card-title>
                            <!-- Тип пристрою -->
                            <h4><b>{{$t('type')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <select
                                class="form-select mb-3"
                                v-model="form.type"
                                :disabled="this.editform == false"
                                >
                                <option v-for="item in types" :key="item" :value="item.value">{{ item.title }}</option>
                            </select>
                        </b-card-text>
                    </b-col>

                </b-row>
            </b-card>

            <h4><b>{{$t('connectionDevice')}}</b></h4>

            <b-card>

                <b-row>

                    <b-col lg="6">
                            <b-card-title>
                                <!-- Виробник -->
                                <h4><b>{{$t('manufacturer')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.manufacturer">
                            </b-card-text>
                    </b-col>

                    <b-col
                    lg="6">
                            <b-card-title>
                                <!-- Модель банківського терміналу -->
                                <h4><b>{{$t('bank_terminal_model')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.model">
                            </b-card-text>
                    </b-col>
                    <b-col
                    lg="6">
                            <b-card-title>
                                <!-- ОС -->
                                <h4><b>{{$t('operating_system')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <select
                                    class="form-select mb-3"
                                    v-model="form.os"
                                    :disabled="this.editform == false"
                                    >
                                    <option v-for="item in os" :key="item" :value="item.value">{{ item.title }}</option>
                                </select>
                            </b-card-text>
                    </b-col>
                    <b-col
                    lg="6">
                            <b-card-title>
                                <!-- Метод з'єднання -->
                                <h4><b>{{$t('connection_method')}} / {{$t('protocol_connect')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <!-- Протокол з'єдання -->
                                <h5>{{this.mutateProtocol(form.protocol)}}</h5>
                                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.protocol">
                            </b-card-text>
                    </b-col>


                </b-row>

            </b-card>

        </template>

    </modal>
    
</template>

<script>
import modal from '@/components/modal/modal-lg' // компонент модального вікна

export default {
    props: [''],
    components: {
        modal,
    },
    data(){
        return {
            form: {
                deviceName: '',

            }
        }
    },
}
</script>
