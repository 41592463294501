<template>
    <!-- Модальне вікно по повторногому зверненню -->

    <dialogBox >
        <template v-slot:title>{{ $t('AddAppeal') }}</template>
        <template v-slot:body>
            <div class="mt-1 text-left">
                <h4 class="mb-3"><b>{{ $t('DescribetheNewRequest') }}</b></h4>
                
                <b-card>
                    <QuillEditor v-model:content="form.text" ref="quill" :modules="modules" theme="snow" contentType="html" toolbar="full"/>
                </b-card>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <button class="btn btn-danger" @click="$emit('close')">{{ $t('cancel') }}</button>
                <button class="btn btn-success" @click="add()">{{ $t('Add') }}</button>
            </div>
        </template>
    </dialogBox>
</template>

<script>
// import dialogBox from '@/components/modal/dialog'
import dialogBox from '@/components/modal/modal-lg'
import { ticketAppeals } from '@/API'
import BlotFormatter from 'quill-blot-formatter'

let apiServe = new ticketAppeals()

export default{
    props: ['objCard', 'clientPhone'],
    components: {
        dialogBox,
    },
    setup: () => {
        const modules = {
            name: 'blotFormatter',  
            module: BlotFormatter, 
            options: {/* options */}
        }
        return { modules }
    },
    data(){
        return{
            form: {
                text: ''
            },
        }
    },
    created(){
        this.getdata()
        
    },
    methods: {
        getdata() {
            this.form = this.objCard
        },
        add(){
            // Додавання нового звернення

            apiServe.addAppeal(this.form.ticketId, this.clientPhone, this.form).then(result => {

                if(result.status == "done"){
                    this.$toast.success(this.$t('RequestAddedSuccessfully'))
                    this.$emit('close')
                } else {
                    this.$toast.error(result.error[0])
                }
                
            
            })
        }
    }
}
</script>