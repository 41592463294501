<template>
    <!-- Вікно редагування інформації до пункту в оновленнях -->

    <modal :title="$t('desc')" @close="$emit('close')" >

        <template v-slot:body>

            <b-card>
                <b-row>
                    <b-col lg="6" md="12">
                        <h5 class="fs-14 mb-3 text-black fw-semibold">{{ $t('UpdateItem') }}</h5>
                        <input class="form-control" v-model="newForm.text" />
                    </b-col>
                </b-row>
            </b-card>

            <b-card>
                <!-- <b-row> -->
                    <b-col
                        lg="6"
                        v-if="
                            this.objCard?.tickets ||
                            this.objCard?.tickets.length > 0
                        "    
                    >
                        <h5 class="fs-14 mb-3 text-black fw-semibold">{{ $t('Testing') }}</h5>

                        <div class="mb-3">

                            <label class="form-label">{{ $t('HastheUpdatebeenTested') }}?</label>

                            <!-- Вибір так чи ні (чи проводилось тестування) -->
                            <div>
                                <b-form-radio-group
                                    v-model="newForm.passedTesting"
                                    :options="optionsBeforeProblem"
                                    class="mb-3"
                                    value-field="item"
                                    text-field="name"
                                    disabled-field="notEnabled"
                                ></b-form-radio-group>
                            </div>
                            
                            <!-- Якщо не проводилось -->
                            <div class="mb-3 mt-2" v-if="this.newForm.passedTesting === false">
                                <b-alert show variant="danger">
                                    {{ $t('decision_dangerAlert') }}
                                    <br/>
                                    <span class="fw-semibold"> {{ $t('decision_dangerAlert_pm') }}</span>
                                </b-alert>
                            </div>

                            <!-- Якщо проводилось -->
                            <div class="mb-3 mt-2" v-if="this.newForm.passedTesting === true">

                                <label class="form-label">{{ $t('WhoConductedtheTesting') }}?</label> <br/>

                                <!-- Ким пройшло тестування (вибір співробітників) -->
                                <template v-for="item in qaWorkerList" :key="item"> 
                                    <span 
                                        :class="`fs-12 badge bg-${checkWorker(item) ? 'danger' : 'success'} copy`" 
                                        style="margin-right: 5px;"
                                        @click="checkWorker(item) ? '' : addWorkers(item)"
                                    >
                                        {{ item.label }}
                                    </span>
                                </template>
                                
                                <Multiselect 
                                    class="mt-3"
                                    v-model="newForm.workerIds"
                                    :close-on-select="true"
                                    :searchable="true"
                                    mode="tags"
                                    :options="this.workersList"
                                    :placeholder="$t('enterSearchTerm')"
                                />

                            </div>

                            <div class="mb-3 mt-2" v-if="this.newForm.passedTest == false">
                                <b-alert show variant="danger">
                                    {{ $t('decision_dangerAlert') }}
                                    <br/>
                                    <span class="fw-semibold"> {{ $t('decision_dangerAlert_pm') }}</span>
                                </b-alert>
                            </div>

                        </div>
                    </b-col>
                    <b-col 
                        lg="6"
                        v-if="
                            this.objCard?.tickets ||
                            this.objCard?.tickets.length > 0
                        "    
                    >
                        <h5 class="fs-14 mb-3 text-black fw-semibold">{{ $t('tickets') }}</h5>

                        <div class="mb-3">

                            <label class="form-label">{{ $t("IsUpdateItemRelatedTicket") }}?</label>

                            <!-- Вибір так чи ні (чи є тікети пов'язані з пунктом) -->
                            <div>
                                <b-form-radio-group
                                    v-model="newForm.associatedWithTicket"
                                    :options="optionsBeforeProblem"
                                    class="mb-3"
                                    value-field="item"
                                    text-field="name"
                                    disabled-field="notEnabled"
                                ></b-form-radio-group>
                            </div>

                            <!-- Якщо є -->
                            <div class="mb-3 mt-2" v-if="this.newForm.associatedWithTicket == true">

                                <label class="form-label">{{ $t('ChooseTickets') }}</label>

                                <Multiselect 
                                    class="mt-1"
                                    v-model="newForm.ticketIds"
                                    :close-on-select="true"
                                    :create-option="true"
                                    :searchable="true"
                                    mode="tags"
                                    :options="this.ticketsList"
                                    :placeholder="$t('enterSearchTerm')"
                                />

                                <!-- Якщо тікети є, але разраби ліниві і не додали всі, то мають можливість цю функцію включити -->
                                <!-- <div
                                    v-if="
                                        this.objCard?.tickets ||
                                        this.objCard?.tickets.length > 0
                                    "
                                    class="form-check"
                                    style="margin-top: 20px;"
                                >
                                    <input 
                                        class="form-check-input" 
                                        type="checkbox" 
                                        v-model="this.findTicket" 
                                        id="flexCheckDefault"
                                    >
                                    <label class="form-check-label" for="flexCheckDefault">
                                        {{ this.$t('addTicketsManualy') }}
                                    </label>
                                </div> -->

                                <!-- 
                                    Коли тікетів не було, або розробник включив функцію додавання вручну
                                    Форма для пошуку та додаванню тікетів 
                                -->
                                <!-- <div 
                                    v-if="
                                        !this.objCard?.tickets ||
                                        this.objCard?.tickets.length === 0 ||
                                        this.findTicket === true
                                    "
                                    class="input-group mb-3" 
                                    style="margin-top: 10px;"
                                >
                                    <input type="text" 
                                        class="form-control" 
                                        :placeholder="this.$t('test_ticket')" 
                                        :aria-label="this.$t('test_ticket')" 
                                        aria-describedby="basic-addon2"
                                        v-model="this.ticketSearch"
                                    >
                                    <div class="input-group-append">
                                        <button 
                                            class="btn btn-outline-success" 
                                            type="button"
                                            @click="findTickets()"
                                        >{{ this.$t('getTickets') }}</button>
                                    </div>
                                </div> -->

                            </div>

                        </div>

                    </b-col>

                    <b-col lg="12">
                        <h4 class="text-left"><b>{{$t('desc')}}</b></h4>
                        <QuillEditor 
                            v-model:content="newForm.description" 
                            theme="snow" 
                            :modules="modules" 
                            contentType="html" 
                            toolbar="full"
                            @selection-change="selectFunc"
                        />
                    </b-col>
                <!-- </b-row> -->
            </b-card>
            
            <b-card>

                <files 
                    :obj="newForm" 
                    :objFiles="newForm.files" 
                    @uploadFiles="uploadFiles"
                    @downloadFile="downloadFile"
                    @deleteFile="deleteFile"
                    @getFile="viewFile"
                />

                <!-- <modallg v-if="showImg" @close="showImg = false">
                    <template v-slot:body>
                        <template v-if="gFile.type == 'image/jpeg' || gFile.type == 'image/png' || gFile.type == 'image/heic'">
                            <img  :src="`${gFile.src}`" style="">
                        </template>
                        <template v-if="gFile.type == 'video/quicktime' || gFile.type == 'video/mp4'">
                            <video controls>
                                <source :src="`${gFile.src}`" type="video/mp4">
                            </video>
                        </template>
                    </template>
                    <template v-slot:buttons>
                        <button class="gdownload gbtn" @click="this.downloadFile(gFile)" ><i class="ri-download-cloud-2-fill"></i></button>
                        <button class="gdelete gbtn" @click="showRemDialog(gFile)" ><i class="ri-delete-bin-fill"></i></button>
                        <button :class="`gprev gbtn ${ifPrevimg == true ? '' : 'disabled'}`" @click="prevOpen(gFile)" aria-label="Previous" data-taborder="2"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"><g><path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"></path></g></svg></button>
                        <button :class="`gnext gbtn ${ifNextimg == true ? '' : 'disabled'}`" @click="nextOpen(gFile)" aria-label="Next" data-taborder="1"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"> <g><path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z"></path></g></svg></button>
                    </template>
                </modallg>

                <modalremove v-if="dialogShow" @close="dialogShow = false" @remove="this.deleteFile(gFile)" /> -->
            </b-card>
        </template>
        <template v-slot:footer-bottom>
            <!-- <a class="btn btn-link link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i>{{ $t('close') }}</a> -->
            
            <!-- Кнопка редагування -->
            <b-button 
                @click="editElement()" 
                variant="success" 
                class="waves-effect waves-light"
            >
                <i class="align-bottom me-1"></i>{{ $t('Edit') }}
            </b-button>
        
        </template>
    </modal>

    <!-- модальне вікно перегляду файлу -->
    <modallg v-if="showImg" @close="showImg = false">
        <template v-slot:body>
            <template v-if="gFile.type == 'image/jpeg' || gFile.type == 'image/png' || gFile.type == 'image/heic'">
                <img  :src="`${gFile.src}`" style="">
            </template>
            <template v-if="gFile.type == 'video/quicktime' || gFile.type == 'video/mp4'">
                <video controls>
                    <source :src="`${gFile.src}`" type="video/mp4">
                </video>
            </template>
        </template>
        <template v-slot:buttons>
            <button class="gdownload gbtn" @click="this.downloadFile(gFile)" ><i class="ri-download-cloud-2-fill"></i></button>
            <button class="gdelete gbtn" @click="dialogShow = true" ><i class="ri-delete-bin-fill"></i></button>
            <button :class="`gprev gbtn ${ifPrevimg == true ? '' : 'disabled'}`" @click="prevOpen(gFile)" aria-label="Previous" data-taborder="2"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"><g><path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"></path></g></svg></button>
            <button :class="`gnext gbtn ${ifNextimg == true ? '' : 'disabled'}`" @click="nextOpen(gFile)" aria-label="Next" data-taborder="1"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"> <g><path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z"></path></g></svg></button>
        </template>
    </modallg>

    <!-- Модальне вікно підтвердження видалення -->
    <modalremove v-if="dialogShow" @close="dialogShow = false" @remove="this.deleteFile(gFile)" />

</template>

<script >
import modal from '@/components/modal/modal-lg'
import files from '@/components/files/index'
import modallg from '@/components/modal/img'
import modalremove from '@/components/modal/modalremove'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import BlotFormatter from 'quill-blot-formatter'
import { storeS } from '@/store'; // глобальне сховище даних
import { Updates, Tickets } from '@/API.js'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
import { sendNotTestedUpdate } from '@/usabilityScripts/logsTelegram.js'

let apiServe = new Updates();
let apiTickets = new Tickets()

export default {
    props: ['objCard', 'objElement', 'type'],
    components: {
        modal,
        files,
        modallg,
        modalremove,
        Multiselect
    },
    setup: () => {
        const modules = {
            name: 'blotFormatter',  
            module: BlotFormatter, 
            options: {/* options */}
        }
        return { modules }
    },
    data() {
        return {
            // ticketSearch: "",
            // findTicket: false,
            closeFileGate: false,
            newForm: {
                text: "",
                opportunityId: "",
                passedTesting: false,
                associatedWithTicket: false,
                description: "",
                ticketIds: [],
                workerIds: [],
                files: []
            },
            optionsBeforeProblem: [ // опції до чи була відома проблема
                { item: true, name: this.$t('y') },
                { item: false, name: this.$t('n') },
            ],
            dialogShow: false, // Прапорець для відображення діалогового вікна видалення файлу
            showImg: false, // Прапорець для відображення зображення
            gFile: { // Дані про обраний файл
                fileId: '',
                src: '',
                type: ''
            },
            tabIndex: 0,
            ifPrevimg: false,
            ifNextimg: false,
            urlCaches: [], // для відео яке завантажували вже
            workersList: [{}], // список співробітників
            qaWorkerList: [], // список qa
            ticketsList: [], // список тікетів
        }
    },
    created() {

        this.getdata()

        // Заповнення списку співробітників для вибору в формі
        for(var item in this.workers){
            this.workersList.push({
                label: this.workers[item].workerName,
                value: this.workers[item].workerId,
            })
        }

        // Заповнення списку співробітників для вибору в формі (QA)
        for(var item in this.workers){
            if(this.workers[item].workerJob == "tester") {
                this.qaWorkerList.push({
                    label: this.workers[item].workerName,
                    value: this.workers[item].workerId,
                })
            }
        }


        if(this.objCard?.tickets) {
            if(this.objCard.tickets.length > 0) {
                for(var item in this.objCard.tickets){
                    this.ticketsList.push({
                        label: `${this.objCard.tickets[item].ticketId}` + " - " + `${this.objCard.tickets[item].ticketName}`,
                        value: this.objCard.tickets[item].ticketId
                    })
                }
            }
        }
        
    },
    watch: {
        // Слідкуємо за змінами в інсексі і кидаємо його в стор
        tabIndex (newIdx) {
            storeS.tabIndex = newIdx
        }
    },
    methods: {
        // шукаємо тікети по полю пошуку
        findTickets() {
            let objParams = {
                pagelimit: "5",
                search: this.ticketSearch,
            }
            
            apiTickets.getTickets(objParams).then(result => {
                // Якщо все ок, додаємо тікети в список вибору, по 5 штук
                if(result.status === 'done') {
                    // this.ticketsList = []
                    result.data.items.forEach(el => {
                        this.ticketsList.push({
                            label: `${el.ticketId}` + 
                            " - " + `${el.ticketName}` + "\n" +
                            `(${this.$t('searc_inp')}: ${this.ticketSearch})`,

                            value: el.ticketId,
                        })
                        document.querySelectorAll("multiselect-option")
                        [document.querySelectorAll("multiselect-option").length - 1].classList.add = "option-from-search"    
                    })
                } else {
                    // Кидаєм лог
                    this.$toast.error(this.$t('error'));
                    
                    var errorText = 'Помилка отримання тікетів'; 
                    var statusRequest = result.status;
                    var api = 'getTickets';
                    var fileRequest = 'src/views/changelog/view/editItem/index.vue';
                    var params = objParams
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        selectFunc(range, oldRange, source) {
            if(range.range === null) {
                this.tabIndex = 6
                // Блок на завантаження файлів в "Файли". Юзаю тут, для того, щоб не вводити окрему зміну. 
                // @/views/newtickets/view/formleft/activity/index.vue - принцип роботи взятий звідси
                // @/components/files/index.vue - блок на файли тут
            } else {
                this.tabIndex = 0
            }
        },
        addWorkers(e){
            // Швидке додавання співробітника за межами списку в масив тестерів рішення
            this.newForm.workerIds.push(e.value)
            // console.log("workerIds", this.newForm);
            // console.log("e", e);
            
        },
        checkWorker(e){
            // перевірка наявності співробітника в списку обраних співробітників
            return this.newForm.workerIds.includes(e.value);
        },
        getdata(){
            // Правки Бориса
            // console.log("this.objElement", this.objElement);
            
            this.newForm.text = this.objElement.text
            this.newForm.passedTesting = this.objElement?.passedTesting
            this.newForm.associatedWithTicket = this.objElement?.associatedWithTicket
            this.newForm.description = this.objElement?.description
            this.newForm.files = this.objElement?.files

            if(this.objElement?.workers) {
                // тестувальники
                for(var index in this.objElement.workers) {
                    var item = this.objElement.workers[index].workerId
                    console.log("item", item);
                    this.newForm.workerIds.push(item)
                }
            }

            if(this.objElement?.tickets) {
                // тікети
                for(var index in this.objElement.tickets) {
                    var item = this.objElement.tickets[index].ticketId
                    console.log("item", item);
                    this.newForm.ticketIds.push(item)
                }
            }

            // Знаходимо id
            
            switch(this.type) {
                case 'opportunities':
                    this.newForm.opportunityId = this.objElement?.opportunityId
                break;
                case 'improvements':
                    this.newForm.improvementId = this.objElement?.improvementId
                break;
                case 'fixed':
                    this.newForm.fixedId = this.objElement?.fixedId
                break;
            }
        },

        editElement(){
            console.log(this.newForm)
            // Перевірка чи вказали тестувальників
            if(this.newForm.passedTesting == true && this.newForm.workerIds.length == 0){
                this.$toast.error('Оберіть будь ласка тестувальників')
                return
            }

            // Очищаємо тестувальників
            if(this.newForm.passedTesting == false){
                this.newForm.workerIds = [];
            }

            // Очищаємо тікети
            if(this.newForm.associatedWithTicket == false){
                this.newForm.ticketIds = [];
            }
            
            switch (this.type) {

                case 'opportunities': 

                    apiServe.editOpportunity(this.newForm.opportunityId, this.newForm).then(result => {

                        if(result.status == 'done') {
                            this.array = result.data;
                            this.$emit('close')

                            if((this.objCard?.tickets || this.objCard?.tickets.length > 0) && this.passedTest === false) {
                                // Викликаємо функцію для відправки повідомлення в телеграм
                                sendNotTestedUpdate(this.$t('opportunity'), this.newForm.opportunityId)
                                .then(response => {
                                    console.log('Telegram API response:', response.data)
                                    // this.$toast.success(this.$t('msgSent'))
                                    // this.$emit('close')
                                })
                                .catch(error => console.error('Telegram API error:', error)); 
                            }
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка редагування пункту секції нові можливості'; 
                            var statusRequest = result.status;
                            var api = 'editOpportunity';
                            var fileRequest = 'src/views/changelog/view/editItem/index.vue';
                            var params = {
                                form: this.newForm,
                                opportunityId: this.newForm.opportunityId
                            };
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;
                case 'improvements': 

                    apiServe.editImprovement(this.newForm.improvementId, this.newForm).then(result => {

                        if(result.status == 'done') {
                            this.array = result.data;
                            this.$emit('close')

                            if((this.objCard?.tickets || this.objCard?.tickets.length > 0) && this.passedTest === false) {
                                // Викликаємо функцію для відправки повідомлення в телеграм
                                sendNotTestedUpdate(this.$t('improvement'), this.newForm.improvementId)
                                .then(response => {
                                    console.log('Telegram API response:', response.data)
                                    // this.$toast.success(this.$t('msgSent'))
                                    // this.$emit('close')
                                })
                                .catch(error => console.error('Telegram API error:', error)); 
                            }
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка редагування пункту секції покращення'; 
                            var statusRequest = result.status;
                            var api = 'editImprovement';
                            var fileRequest = 'src/views/changelog/view/editItem/index.vue';
                            var params = {
                                form: this.newForm,
                                opportunityId: this.newForm.improvementId
                            };
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;
                case 'fixed': 

                    apiServe.editFixed(this.newForm.fixedId, this.newForm).then(result => {

                        if(result.status == 'done') {
                            this.array = result.data;
                            this.$emit('close')

                            if((this.objCard?.tickets || this.objCard?.tickets.length > 0) && this.passedTest === false) {
                                // Викликаємо функцію для відправки повідомлення в телеграм
                                sendNotTestedUpdate(this.$t('fix'), this.newForm.fixedId)
                                .then(response => {
                                    console.log('Telegram API response:', response.data)
                                    // this.$toast.success(this.$t('msgSent'))
                                    // this.$emit('close')
                                })
                                .catch(error => console.error('Telegram API error:', error));
                            }

                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка редагування пункту секції виправлення'; 
                            var statusRequest = result.status;
                            var api = 'editFixed';
                            var fileRequest = 'src/views/changelog/view/editItem/index.vue';
                            var params = {
                                form: this.newForm,
                                opportunityId: this.newForm.fixedId
                            };
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;

                default: 
                    console.log('log');
                break

            }

        },
        uploadFiles(obj, formData){
            
            switch (this.type) {

                case 'opportunities': 

                    // const fileType = formData.get('file').type; // Якщо передається файл через formData
                    // console.log("fileType", fileType);


                    apiServe.uploadFilesOpportunities(this.newForm.opportunityId, formData).then(result => {

                        if(result.status == 'done') {
                            this.newForm.files = result.data.files;
                            storeS.uploadPercentage = 0;
                        } else {
                            console.log(result);
                            this.$toast.error(this.$t('error') + " " + result.error[0]);
                        }

                    })

                break;

                case 'improvements': 

                    // const fileType = formData.get('file').type; // Якщо передається файл через formData
                    // console.log("fileType", fileType);


                    apiServe.uploadFilesImprovement(this.newForm.improvementId, formData).then(result => {

                        if(result.status == 'done') {
                            this.newForm.files = result.data.files;
                            storeS.uploadPercentage = 0;
                        } else {
                            console.log(result);
                            this.$toast.error(this.$t('error') + " " + result.error[0]);
                        }

                    })

                break;

                case 'fixed': 

                    // const fileType = formData.get('file').type; // Якщо передається файл через formData
                    // console.log("fileType", fileType);


                    apiServe.uploadFilesFixed(this.newForm.fixedId, formData).then(result => {

                        if(result.status == 'done') {
                            this.newForm.files = result.data.files;
                            storeS.uploadPercentage = 0;
                        } else {
                            console.log(result);
                            this.$toast.error(this.$t('error') + " " + result.error[0]);
                        }

                    })

                break;

                default: 
                    console.log('log');
                break

            }
        },
        viewFile(e) {
            // Перегляд файлу
            if(e.type == 'image/jpeg' || e.type == 'image/png' || e.type == 'image/heic' || e.type == 'video/quicktime' || e.type == 'video/mp4'){
                // this.$emit('getFile', e)

                var id = e.fileId ? e.fileId : e;
                this.ifPrevimg = id == this.newForm.files[0].fileId ? false : true; // чи показувати стрілку на попередній док
                this.ifNextimg = id == this.newForm.files[this.newForm.files.length-1].fileId ? false : true; // чи показувати стрілку на наступний док
                
                if(storeS.blob != undefined){
                    var foundName = 'urlFrom' + this.deadlineId + "fileId" + id;
                    var urlCache = ''
                    var statusCache = this.urlCaches.some(function(e){ 
                        urlCache = e.url
                        return e.name == foundName;
                    });
                    if(statusCache){
                        console.log(urlCache);
                        // якщо відео є в локалстор
                        this.gFile.fileId = id;
                        this.gFile.src = urlCache;
                        this.gFile.type = e.type;
                        this.gFile.name = e.name
                        this.showImg = true;
                    } else{
                        // якщо відео немає в локалстор - додаємо
                        this.switchGetFile(e, id);
                    }

                } else {
                   this.switchGetFile(e, id);
                }

            } else {
                // this.$toast.error(this.$t('fileNotSupported'));
                this.downloadFile(e)
            }
        },
        switchGetFile(e, id){
            switch (this.type) {

                case 'opportunities': 

                    apiServe.getFileOpportunities(e.fileId).then(result => {

                        var blob = new Blob([result.data])
                        const url = window.URL.createObjectURL(blob);
                        
                        let opUrl = {
                            name: 'urlFrom' + this.deadlineId + "fileId" + id,
                            url: url
                        }

                        this.urlCaches.push(opUrl)
                        this.saveUrlFile(this.urlCaches);

                        this.gFile.fileId = id;
                        this.gFile.src = url;
                        this.gFile.type = e.type;
                        this.gFile.name = e.name

                        this.showImg = true;
                        storeS.uploadPercentage = 0;

                    })

                break;
                case 'improvements': 

                    apiServe.getFileImprovement(e.fileId).then(result => {
                        
                        var blob = new Blob([result.data])
                        const url = window.URL.createObjectURL(blob);
                        
                        let opUrl = {
                            name: 'urlFrom' + this.deadlineId + "fileId" + id,
                            url: url
                        }

                        this.urlCaches.push(opUrl)
                        this.saveUrlFile(this.urlCaches);

                        this.gFile.fileId = id;
                        this.gFile.src = url;
                        this.gFile.type = e.type;
                        this.gFile.name = e.name

                        this.showImg = true;
                        storeS.uploadPercentage = 0;

                    })

                break;
                case 'fixed': 

                    apiServe.getFileFixed(e.fileId).then(result => {

                        var blob = new Blob([result.data])
                        const url = window.URL.createObjectURL(blob);
                        
                        let opUrl = {
                            name: 'urlFrom' + this.deadlineId + "fileId" + id,
                            url: url
                        }

                        this.urlCaches.push(opUrl)
                        this.saveUrlFile(this.urlCaches);

                        this.gFile.fileId = id;
                        this.gFile.src = url;
                        this.gFile.type = e.type;
                        this.gFile.name = e.name

                        this.showImg = true;
                        storeS.uploadPercentage = 0;

                    })

                break;

                default: 
                    console.log('log');
                break
            }
        },
        downloadFile(e){
            // Завантажити файл

            switch (this.type) {

                case 'opportunities': 

                    apiServe.getFileOpportunities(e.fileId)
                        .then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', e.name);
                            document.body.appendChild(link);
                            link.click();
                            storeS.uploadPercentage = 0;
                        })
                        .catch((error) => console.log(error));

                break;
                case 'improvements': 

                    apiServe.getFileImprovement(e.fileId)
                        .then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', e.name);
                            document.body.appendChild(link);
                            link.click();
                            storeS.uploadPercentage = 0;
                        })
                        .catch((error) => console.log(error));

                break;
                case 'fixed': 

                    apiServe.getFileFixed(e.fileId)
                        .then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', e.name);
                            document.body.appendChild(link);
                            link.click();
                            storeS.uploadPercentage = 0;
                        })
                        .catch((error) => console.log(error));

                break;

                default: 
                    console.log('log');
                break
            }

        },
        saveUrlFile(e){
            // Збереження файлу в локал
            storeS.blob = e;
        },
        prevOpen(e){
            // Попередній файл

            var current_id = e.fileId;
            var prev_id = ""; 

            for(var value in this.newForm.files){
                if(this.newForm.files[value].fileId == current_id){
                    prev_id = this.newForm.files[Number(value)-Number('1')];
                }
            }
            this.viewFile(prev_id);
        },
        nextOpen(e){
            // Наступний файл

            var current_id = e.fileId;
            var next_id = "";
            for(var value in this.newForm.files){
                if(this.newForm.files[value].fileId == current_id){
                    next_id = this.newForm.files[Number(value)+Number('1')];
                }
            }
            this.viewFile(next_id);
        },
        deleteFile(e){
            // Видалення файлу
            console.log("type", this.type);
            console.log("file", e);
            

            switch (this.type) {

                case 'opportunities': 

                    apiServe.deleteFileNewOpportunities(e.fileId).then(result => {

                        if(result.status == 'done') {
                            // this.form.opportunities = result.data;
                            this.newForm.files = result.data.files;
                            this.$toast.success(this.$t("Removed"))
                        } else {
                            
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка видалення пункту нових можливостей'; 
                            var statusRequest = result.status;
                            var api = 'deleteFileNewOpportunities';
                            var fileRequest = 'src/views/changelog/view/editItem/index.vue';
                            var params = {
                                id: e.fileId
                            };
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;
                case 'improvements': 

                    apiServe.deleteFileImprovement(e.fileId).then(result => {

                        if(result.status == 'done') {
                            this.newForm.files = result.data.files;
                            this.$toast.success(this.$t("Removed"))
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка видалення пункту покращення'; 
                            var statusRequest = result.status;
                            var api = 'deleteFileImprovement';
                            var fileRequest = 'src/views/changelog/view/editItem/index.vue';
                            var params = {
                                id: e.fileId
                            };
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;
                case 'fixed': 

                    apiServe.deleteFileDeadlinesFixed(e.fileId).then(result => {

                        if(result.status == 'done') {
                            this.newForm.files = result.data.files;
                            this.$toast.success(this.$t("Removed"))
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка видалення пункту виправлення'; 
                            var statusRequest = result.status;
                            var api = 'deleteFileDeadlinesFixed';
                            var fileRequest = 'src/views/changelog/view/editItem/index.vue';
                            var params = {
                                id: e.fileId
                            };
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;

                default: 
                    console.log('log');
                break
            }

            this.dialogShow = false
            
        },
        mutateImg(e) {
            // Функція для отримання кольору та іконки файлу

            return mutateImgFiles(e)
        },
    },
    mounted(){
        this.urlCaches = storeS.blob != undefined ? storeS.blob : [];
        // if(localStorage.getItem('urlFiles')) {
        //     this.urlCaches = this.urlCaches != null ? JSON.parse(localStorage.getItem('urlFiles')) : [] ;
        // }
    },
    computed: {
        dealines(){
            /*
                Повертає дані оновлень з глобального стору.
            */
            return storeS.dealines
        },
        workers() {
            /*
                Повертає список з усіма користувачами з глобального стору.
            */
            return storeS.workers
        },
        uploadPercentage(){
            return storeS.uploadPercentage
        }
    }
}
</script>
