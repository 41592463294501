<template>

    <!-- 
        Таб Файли
    -->

    <div class="card" >
        <div class="card-body" >
            <div class="mb-4 card-header border-bottom-dashed" >
                <h5 class="card-title flex-grow-1">Файли</h5>
            </div>
            <div class="row" >
                <div class="col-lg-12" >

                    <template v-for="item in form.attachments" :key="item">
                        <div class="d-flex align-items-center border border-dashed p-2 rounded mb-2 mt-3" >
                            <div class="avatar-sm copy" >
                                <div class="avatar-title bg-light text-info rounded fs-24" ><i class="ri-video-fill"></i></div>
                            </div>
                            <div class="flex-grow-1 ms-3 copy" >
                                <h6 class="mb-1"><a>{{ item.filename }}</a></h6>
                                <small class="text-muted">{{ item.date }}</small>
                            </div>
                            <div class="hstack gap-3 fs-16" >
                                <button class="copy btn btn-soft-info btn-icon"><i class="ri-download-2-line"></i></button><button class="copy btn btn-soft-danger btn-icon"><i class="ri-delete-bin-line"></i></button>
                            </div>
                        </div>
                    </template>

                </div>
            </div>
        </div>
    </div>


</template>

<script>

export default {
    props: ['objCard'],
    components: {

    },
    data() {
        return {
            form: ''
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.objCard
        }
    }
}
</script>
