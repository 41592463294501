import { storeS } from '@/store'
import { number } from 'echarts';
import i18n from '@/i18n'

function nameWorker(item) {
    var name = "";
    if(String(item).length <= 3) {
        storeS.workers.forEach((el) => {
            if(el.workerId === item) {
                name = el.workerName
            }
        })
    } else {
        storeS.workers.forEach((el) => {
            if(el.workerPhone === item) {
                name = el.workerName
            } 
            if(name == '') {
                name = item
            }
        })
    }
    return name
}

function tgAccWorker(item) {
    var tgNick = "";

    storeS.workers.forEach((el) => {
        if(el.workerId === item) {
            tgNick = "@"+el.telegramUsername
        }
    })

    return { tgNick }
}

//по accid
function nameWorkerAcc(item) {
    var name = "";
    storeS.workers.forEach((el) => {
        if(el.accountId === item) {
            name = el.workerName
        }
    })
    return name
}


export { nameWorker, nameWorkerAcc, tgAccWorker }