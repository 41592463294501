<template>
    <modal>
        <template v-slot:title>{{ $t('Add') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col>
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('version') }}</h4>
                        <input type="text" class="form-control" id="contactNumber" v-model="form.version" >
                    </div>
                </b-col>
                <b-col>
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('project') }}</h4>
                        <select
                            class="form-select mb-3"
                            data-plugin="choices"
                            v-model="form.update"
                            >
                            <option v-for="item in list" :key="item" :value="item.value">{{ item.label }}</option>
                        </select>
                    </div>
                </b-col>
                <b-col>
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('dateDeadLine') }}</h4>
                        <flat-pickr
                            v-model="form.deadlineDate"
                            :config="config"
                            class="form-control"
                        ></flat-pickr>
                    </div>
                </b-col>
            </b-row>

            <div class="" >
                <!-- Кнопка додавання опису -->
                <button 
                    type="button" 
                    v-if="showDescDeadline == false"
                    @click="showDescDeadline = true"
                    class="mt-2 btn btn-soft-info waves-effect waves-light w-100 fw-semibold" 
                    style="height: 48px;"
                >
                    {{ $t('AddDescription') }}
                </button>
            </div>

            <template
                v-if="showDescDeadline == true"    
            >
                <h5 class="text-left"><b>{{$t('desc')}}</b></h5>
                <hr />

                <div class="mb-4">
                    <h4 class="text-left"><b>🔥 {{ $t('NewOpportunities') }}: </b></h4>

                    <!-- Новий інпут -->
                    <b-col lg="6">
                        <div class="flex-grow-1 mb-2 mt-3">
                            <div class="input-group">
                                <input 
                                    type="text"
                                    v-model="newOpportunity" 
                                    class="form-control"
                                    @keydown.enter="addElement('opportunity', newOpportunity)"
                                >
                                <button 
                                    @click="addElement('opportunity', newOpportunity)"
                                    class="btn btn-success" 
                                    type="button"
                                >
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                    </b-col>

                    <!-- Список можливостей -->
                    <template v-if="descForm.new_opportunities.length > 0">

                        <label class="fs-13 text-muted mb-3 mt-2">{{ $t('AddedItems') }}</label>

                        <ul class="list-unstyled vstack gap-3 mb-0">

                            <li v-for="(item, index) in this.descForm.new_opportunities" :key="index">

                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <div class="avatar-xs flex-shrink-0 me-1">
                                            <div class="avatar-title rounded bg-soft-success text-success">#{{index+1}}</div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <div class="input-group">
                                            <input type="text" class="form-control" v-model="item.text" disabled>
                                            
                                            <!-- Редагувати пункт -->
                                            <button 
                                                @click="edit('opportunity', index)" 
                                                class="btn btn-info" 
                                                type="button"
                                            >
                                                <i class="ri-pencil-line"></i>
                                            </button>

                                            <!-- Видалити пункт -->
                                            <button 
                                                @click="showRemoveItem.item = index;showRemoveItem.type = 'opportunities';showRemoveItem.show = true;"
                                                class="btn btn-danger" 
                                                type="button"
                                            >
                                                <i class="ri-delete-bin-2-line"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </li>

                        </ul>

                    </template>

                </div>

                <div class="mb-4">
                    <h4 class="text-left"><b>🙌 {{ $t('Improved') }}: </b></h4>

                    <!-- Новий інпут -->
                    <b-col lg="6">
                        <div class="flex-grow-1 mb-2 mt-3">
                            <div class="input-group">
                                <input 
                                    type="text"
                                    v-model="newImprovement" 
                                    class="form-control"
                                    @keydown.enter="addElement('improved', newImprovement)"
                                >
                                <button 
                                    @click="addElement('improved', newImprovement)"
                                    class="btn btn-success" 
                                    type="button"
                                >
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                    </b-col>

                    <!-- Список покращень -->
                    <template v-if="descForm.improved.length > 0">

                        <label class="fs-13 text-muted mb-3 mt-2">{{ $t('AddedItems') }}</label>

                        <ul class="list-unstyled vstack gap-3 mb-0">

                            <li v-for="(item, index) in this.descForm.improved" :key="index">

                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <div class="avatar-xs flex-shrink-0 me-1">
                                            <div class="avatar-title rounded bg-soft-success text-success">#{{index+1}}</div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <div class="input-group">
                                            <input type="text" class="form-control" v-model="item.text" disabled>
                                            
                                            <!-- Редагувати пункт -->
                                            <button 
                                                @click="edit('improved', index)" 
                                                class="btn btn-info" 
                                                type="button"
                                            >
                                                <i class="ri-pencil-line"></i>
                                            </button>

                                            <!-- Видалити пункт -->
                                            <button 
                                                @click="showRemoveItem.item = index;showRemoveItem.type = 'improved';showRemoveItem.show = true;"
                                                class="btn btn-danger" 
                                                type="button"
                                            >
                                                <i class="ri-delete-bin-2-line"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </li>

                        </ul>

                    </template>

                </div>

                <div class="mb-4">
                    <h4 class="text-left"><b>💪 {{ $t('Corrected') }}: </b></h4>

                    <!-- Новий інпут -->
                    <b-col lg="6">
                        <div class="flex-grow-1 mb-2 mt-3">
                            <div class="input-group">
                                <input 
                                    type="text"
                                    v-model="newCorrected" 
                                    class="form-control"
                                    @keydown.enter="addElement('corrected', newCorrected)"
                                >
                                <button 
                                    @click="addElement('corrected', newCorrected)"
                                    class="btn btn-success" 
                                    type="button"
                                >
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                    </b-col>

                    <!-- Список виправлень -->
                    <template v-if="descForm.corrected.length > 0">

                        <label class="fs-13 text-muted mb-3 mt-2">{{ $t('AddedItems') }}</label>

                        <ul class="list-unstyled vstack gap-3 mb-0">

                            <li v-for="(item, index) in this.descForm.corrected" :key="index">

                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <div class="avatar-xs flex-shrink-0 me-1">
                                            <div class="avatar-title rounded bg-soft-success text-success">#{{index+1}}</div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <div class="input-group">
                                            <input type="text" class="form-control" v-model="item.text" disabled>
                                            
                                            <!-- Редагувати пункт -->
                                            <button 
                                                @click="edit('corrected', index)" 
                                                class="btn btn-info" 
                                                type="button"
                                            >
                                                <i class="ri-pencil-line"></i>
                                            </button>

                                            <!-- Видалити пункт -->
                                            <button 
                                                @click="showRemoveItem.item = index;showRemoveItem.type = 'corrected';showRemoveItem.show = true;"
                                                class="btn btn-danger" 
                                                type="button"
                                            >
                                                <i class="ri-delete-bin-2-line"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </li>

                        </ul>

                    </template>

                </div>

            </template>

            <!-- <b-row>
                <b-col>
                    <div class="mb-3">
                        <h5 class="form-label">{{ $t('Comment') }}</h5>
                        <textqe @input="inputText" :objText="this.form.comment" />
                    </div>
                </b-col>
            </b-row> -->
        </template>

        <template v-slot:footer-bottom>
            <!-- <button class="btn link-success fw-medium" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button> -->
            <button type="button" class="btn btn-success" v-on:click="add">{{ $t('Add') }}</button>
        </template>

    </modal>

    <!-- Модальне вікно підтвердження видалення -->
    <removeBox 
        v-if="showRemoveItem.show"
        @close="showRemoveItem.show = false"
        @deleteItem="removeElement();"
    />

    <!-- Модальне вікно редагування детальної інформації -->
    <editWindow 
        v-if = "this.showEditModal.show === true" 
        @close = "this.showEditModal.show = false"
        :objElement="this.showEditModal.dataElement"
        :type = "this.currentType"
        @getdata="this.updateCard(); this.$emit('getdata')"
    ></editWindow>
    
    
</template>
 
<script>
import editWindow from '@/views/changelog/view/editItem/index.vue'
import textqe from '@/components/textQE' // компонент текстового редактора
import modal from '@/components/modal-small'
import removeBox from './view/action/removeItem'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import { Updates } from '@/API.js';
import { storeS } from '@/store.js' 
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
 
 let apiServe = new Updates();
 
export default ({
    props:['type', 'date'],
    data(){
        return {
            config: {
                mode: "single",
                minDate: "today",
                altInput: true,
                altFormat: "F j, Y",
                enableTime: false,
                dateFormat: "Y-m-d",
                locale: ""
            },
            showDescDeadline: false,
            showRemoveItem: {
                show: false,
                item: '',
                type: ''
            },
            newOpportunity: "",
            newImprovement: "",
            newCorrected: "",
            showEditModal: {
                dataElement: '',
                show: false
            },
            form: {
                version: "",
                deadlineDate: "",
                update: "",
                comment: '',
                improvements: [],
                fixed: [],
                opportunities: []
            },
            descForm: {
                new_opportunities: [],
                improved: [],
                corrected: []
            },// тест
            list: [
                { value: 'all', label: this.$t('all') },
                { value: 'skyservice', label: 'SkyService' },
                { value: 'posterminal', label: 'Pos-terminal' },
                { value: 'dashboard', label: 'Dashboard' },
                { value: 'skymarket', label: 'Sky Market' },
                { value: 'sellup', label: 'SellUP' },
                { value: 'hallscreen', label: this.$t('orderBoard') },
                { value: 'inside', label: 'Inside' },
                { value: 'android', label: this.$t('onlyForAndroid') },
                { value: 'ios', label: this.$t('onlyForIOS') },
            ],
            showEdit: false
        }
    },
    components: { 
        modal, 
        flatPickr, 
        textqe,
        removeBox,
        editWindow
    },
    created(){
        if(this.type) {
            this.form.update = this.type
        }
        if(storeS.lang == 'uk'){
            this.config.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.config.locale = Russian
        }

        if(this.date) {
            this.form.deadlineDate = this.date
        }
    },
    methods: {
        edit(path, idx) {
            switch(path) {
                case 'opportunity':
                    this.currentType = "opportunities"
                    this.currentEl = this.descForm.new_opportunities[idx]
                    
                    apiServe.getOpportunity(this.currentEl.opportunityId).then(result => {

                        if(result.status == 'done') {
                            this.showEditModal.dataElement = result.data
                            this.showEditModal.show = true
                            
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);
                        }

                    })

                break;
                case 'improved':
                    this.currentType = "improvements"
                    this.currentEl = this.descForm.improved[idx]

                    apiServe.getImprovement(this.currentEl.improvementId).then(result => {

                        if(result.status == 'done') {
                            this.showEditModal.dataElement = result.data
                            this.showEditModal.show = true
                            
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);
                        }

                    })

                break;
                case 'corrected':
                    this.currentType = "fixed"
                    this.currentEl = this.descForm.corrected[idx]

                    apiServe.getFixed(this.currentEl.fixedId).then(result => {

                        if(result.status == 'done') {
                            this.showEditModal.dataElement = result.data
                            this.showEditModal.show = true
                            
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);
                        }

                    })

                break;
            }
        },
        inputText(text) {
            // Оновлення текстового поля форми з введеним текстом
            this.form.comment = text.target ? text.target.innerHTML : text;
        },
        add() {
            if (!this.form.version) {
                //перевіряємо чи вказана версія
                this.$toast.error(this.$t('EnterVersion'));
            }
            if (!this.form.deadlineDate) {
                //перевіряємо чи вказана дата
                this.$toast.error(this.$t('specifyDate'));
            }
            // if (!this.form.comment) {
            //     //перевіряємо чи вказаний опис 
            //     this.$toast.error(this.$t('EnterDesc'));
            // }

            if(this.form.version && this.form.deadlineDate){
                apiServe.addUpdate(this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('сreatedT'));
                        this.$emit('getData') //для тікетів
                        this.$emit('close')
                        this.eventBus.emit('saveUpdate', true)
                    } else {
                        this.$toast.error(this.$t("error"))
                    }
                })
            }
        },   
        addElement(array, desc) {

            // Перевірка на порожній пункт
            if(desc == ""){
                this.$toast.error(this.$t('Вкажіть, будь ласка, пункт опису'))
                return
            }

            let obj = {
                text: desc
            }

            switch(array) {
                case 'opportunity':
                    apiServe.addOpportunity(obj).then(result => {
                        if(result.status === 'done') {
                            this.$toast.success(this.$t('createdOpportunity'))
                            this.descForm.new_opportunities.push({
                                text: obj.text,
                                opportunityId: result.data,
                                checked: 0
                            })
                            this.newOpportunity = "";
                            this.form.opportunities.push(result.data)
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);
                        }
                    })                   
                break;
                case 'improved':
                    apiServe.addImprovement(obj).then(result => {
                        if(result.status === 'done') {
                            this.$toast.success(this.$t('createdImprovement'))
                            this.descForm.improved.push({
                                text: obj.text,
                                improvementId: result.data,
                                checked: 0
                            })
                            this.newImprovement = "";
                            this.form.improvements.push(result.data)
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);
                        }
                    })
                break;
                case 'corrected':
                    apiServe.addFixed(obj).then(result =>{
                        if(result.status === 'done') {
                            this.$toast.success(this.$t('createdFixed'))
                            this.descForm.corrected.push({
                                text: obj.text,
                                fixedId: result.data,
                                checked: 0
                            })
                            this.newCorrected = "";
                            this.form.fixed.push(result.data)
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);
                        }
                    })
                break;
            }
        },
        removeElement(array, index) {
            // Видалення елементу

            switch(this.showRemoveItem.type){

                case 'opportunities':

                    this.descForm.new_opportunities.splice(this.showRemoveItem.item, 1);
                    this.form.opportunities.splice(this.showRemoveItem.item, 1)
                    this.showRemoveItem.show = false;

                break;
                case 'improved':

                    this.descForm.improved.splice(this.showRemoveItem.item, 1);
                    this.form.improvements.splice(this.showRemoveItem.item, 1)
                    this.showRemoveItem.show = false;
                 
                break;

                case 'corrected':

                    this.descForm.corrected.splice(this.showRemoveItem.item, 1);
                    this.form.fixed.splice(this.showRemoveItem.item, 1)
                    this.showRemoveItem.show = false;
                 
                break;

                default: 
                break;
            }
        }
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
 })
</script>