<template>
    <div class="">
        <b-tabs 
            pills 
            :nav-wrapper-class="`nav-custom nav-custom-success`"
        >  
            <b-tab :title="$t('applications')" active>
                <div style="padding: 20px 0px;margin: 10px auto;border-radius: 7px;" >
                    <requests v-if="form.requests" :obj="this.form.requests" />
                </div>
            </b-tab>
            <b-tab :title="$t('tickets')">
                <div style="padding: 20px 0px;margin: 10px auto;border-radius: 7px;" >
                    <tickets v-if="form.tickets" :obj="this.form.tickets" />
                </div>
            </b-tab>
            <b-tab :title="$t('Comments')">
                <div style="padding: 20px 0px;margin: 10px auto;border-radius: 7px;" >
                    <comments :number="this.form.userPhone" :obj="this.form" @getdata="this.getdata()" :sklad="this.form.tradepoints" :requestId="this.specialId"/>
                </div>
            </b-tab>
            <b-tab :title="$t('Story')">
                <div style="padding: 20px 0px;margin: 10px auto;border-radius: 7px;" >
                    <history :number="this.form.userPhone" :obj="this.form" @getdata="this.getdata()"/>
                </div>
            </b-tab>

        </b-tabs>
    </div>
</template>

<script>
import comments from './comments/index.vue'
import requests from './requests/index'
import tickets from './tickets/index'
import history from './history/index.vue'

export default({
    props: ['obj', 'specialId'],
    components: {
        requests,
        tickets,
        comments,
        history
    },
    data() {
        return {
            form: '',
            pay: '',
            isOpenPay: false,
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj
        },
    }
})
</script>