<template>
    <chat :key="componentKey" :messages="this.messages" v-if="objMsg.length != 0" :msg="objMsg" @sendMsg="sendMsg" :type="this.type" @sendReplyMsg="sendReplyMsg" @removeMsg="removeMsg" @getdata="getdata"/>
    <chat v-else :msg="objMsg" @sendMsg="sendMsg" :type="this.type" @updateMessages="updateMessages" @sendReplyMsg="sendReplyMsg" @removeMsg="removeMsg" @getdata="getdata" />
</template>

<script>
import chat from '@/components/chat/index'
import { Tickets, Messages } from '@/API'
import { storeS } from '@/store';

let apiChats = new Tickets();
let apiMessages = new Messages();

export default{
    props: ['id', 'numberIdx', 'messages'],
    components: {
        chat
    },
    data(){
        return{
            type: 'ticket',
            objMsg: [],
            newMsg: [],
            form: {
                message: ""
            },
            componentKey: ''
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            apiChats.getMessages(this.id).then(result => {
                if(result.status == 'done'){
                    this.objMsg = result.data.reverse()
                    this.componentKey += 1;
                    this.ifMsg();
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        sendMsg(text){
            console.warn("text> ", text);
            
            apiChats.sendMessage(this.id, text).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('msgSent'));
                    this.getdata();
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        sendReplyMsg(objReply){
            apiChats.replyOnMessage(this.id, objReply.id, objReply).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('msgSent'));
                    this.getdata();
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        removeMsg(e){
            apiChats.deleteMessage(e.id).then(result=>{
                if(result.status == 'done') {
                    this.$toast.success(this.$t('Removed'));
                    this.getdata()
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        ifMsg(){
            var newMsg = storeS.readMsg.filter(item => item.ticketId == this.id && item.read == false)
            var arr = [];
            for(var item in newMsg) {
                arr.push(newMsg[item].id);
            }
            let obj = {ids: arr}
            if(arr.length > 0){
                // apiMessages.readMessages(obj).then(result => {
                //     if(result.status == 'done'){
                //         var data = {
                //             status: true
                //         }
                //         this.$toast.success(this.$t('statusViewed'))
                //         this.eventBus.emit('updateMessage', data);
                //         this.$emit('refreshCount')
                //     } else {
                //         this.$toast.error(this.$t('err'))
                //     }
                // })
            }
        }
    },
    mounted() {
        this.eventBus.off('newMsgticket');
        this.eventBus.on('newMsgticket', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    }
}
</script>