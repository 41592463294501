<template>

    <!-- Модальне вікно картки заявки -->
    <viewRequest 
      v-if="showRequestModal"
      @close="closeModal('request')" 
      :shortapplic="items"
      :closeOne="closeOnePerm"
      :client = 'this.client'
    >
    </viewRequest>

    <!-- Модальне вікно перегляду смс-повідомлення -->
    <viewSmsBox 
        v-if="showSmsModal"
        @close="showSmsModal = false"
        :obj="this.form"
    />

    <!-- Модальне вікно для відображення картки клієнта -->
    <viewbox 
        v-if="showModalUser == true" 
        @close="showModalUser = false;" 
        @call = "call"
        :objcard="this.objcard" 
        :companyId="this.searchComp.companyId"
    ></viewbox>

</template>

<script>
import viewRequest from '@/views/application/view/viewapplic' // картка заявки
import viewSmsBox from '@/components/sms/view' // картка перегляду смс
import viewbox from '@/views/accounts/allAccounts/viewnew/index.vue' // картка клієнта
import { SuperAdmin, Tickets } from "@/API.js" 
import { storeS } from '@/store' // глобальний стор

let apiServe = new SuperAdmin();
let apiTickets = new Tickets();

export default{
    components: {
        viewRequest,
        viewSmsBox,
        viewbox
    },
    data(){
        return{
            form: '',
            showRequestModal: false,
            showSmsModal: false,
            showModalUser: false,
            objcard: '',
            searchComp: {
                companyTitle: '',
                number: '',
                companyId: '',
            }
        }
    },
    mounted() {

        // Слухання події sendSmsLog (відкриття вікна перегляду смс)
        this.eventBus.off('sendSmsLog')
        this.eventBus.on('sendSmsLog', (data) => {
            this.form = data.data
            this.showSmsModal = true
        })

        // Слухання події getCard (відкриття вікна карти клієнта)
        this.eventBus.off('openCardClient')
        this.eventBus.on('openCardClient', (data) => {
            this.getClient(data.phone, data.method)
        })
    },
    methods: {
        getClient(num, method) {
            // Метод отримання клієнта за номером та методом пошуку

            // Додаємо можливість пошуку, якщо номер вказаний без 38
            var customNumber = num.toString();
            
            apiServe.getCardAcc(method, customNumber).then(result => {
                if(result.status == 'done'){
                    if(result.data.accountId != false && result.data.accountId != undefined){
                        this.objcard = result.data
                        this.$toast.success(this.$t('Account') + ` - ` + result.data.personalData.phone);
                        this.showModalUser = true;
                    } else {
                        this.objcard = result.data;
                        this.showModalUnUser = true
                    }
                } 
            })
        },
    },
    computed: {
        perms() {
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        checks(){
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
    },

}

</script>