<template>
    <dialogBox >
        <template v-slot:title>{{ $t('Comment') }}</template>
        <template v-slot:body>
            <div class="mb-3">
                <label class="form-label">{{ $t('Comment') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" ></textarea>
            </div>
            <div class="mb-3" v-if="this.obj.createWorkerId !== this.user.userid">
                <div class="form-check">
                    <input class="form-check-input" v-model="this.form.noticeAuthor" type="checkbox" value="" id="invalidCheck2" required>
                    <label class="form-check-label" for="invalidCheck2">
                        {{ $t('noticeAuthor') }}
                    </label>
                </div>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium"  @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button>
            <button 
                type="button" 
                class="btn btn-primary" 
                @click="addComment"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import { Monitoring } from '../../../../API';
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
import {storeS} from "@/store"

let apiServe = new Monitoring()

export default{
    components: {
        dialogBox
    },
    props: ['id', 'obj'],
    data(){
        return{
            form: {
                comment: '',
                noticeAuthor: false,
            }
        }
    },
    created() {
        console.log(this.user)
    },
    methods: {
        addComment() {
            apiServe.addComment(this.id, this.form).then(result => {
                if(result.status === 'done') {
                    this.$emit('close')
                    this.$emit('updateCard')
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Не вийшло відправити коментар на моніторинг'; 
                    var statusRequest = result.status;
                    var api = 'addComment';
                    var fileRequest = 'src/views/monitoring/view/actions/addComment.vue';
                    var params = `{ id: ${this.id}, form: ${this.form}}`;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
}
</script>