<template>

    <!-- 
        Таб Баг-репорти
    -->

    <div class="flex-shrink-0 mb-3" >
        <button 
            @click="showNoteCL = !showNoteCL"
            id="info" 
            type="button" 
            class="btn btn-soft-info waves-effect waves-light"
        >
            <i class="bx bxs-info-circle"></i> Нотатка
        </button>
    </div>

    <!-- Нотатка по дефекту та пріоритету -->
    <b-col lg="10 mt-2" v-if="showNoteCL">
        <b-alert show variant="info">
            <b-row>
                <b-col>
                    <b>Нотатка по серйозності дефекту:</b>
                    <ul class="ps-4 vstack gap-2 mt-3">
                        <li><b>Блокуючий (blocker)</b> - помилка повністю блокує та унеможливлює роботу застосунку, немає жодного способу обійти її. Виправлення багу необхідне для функціонування системи.</li>
                        <li><b>Критична помилка (critical)</b> - блокує частину функціоналу, але є шлях для її обходу. Рішення проблеми необхідне для подальшої роботи з ключовим функціоналом.</li>
                        <li><b>Значна (major)</b> - некоректна робота частини функціональності (функція працює, але неправильно). Така помилка не критична, є можливість роботи з функціоналом із використанням інших вхідних точок.</li>
                        <li><b>Незначна (minor)</b> - незначні огріхи, помилки, які не відноситься до функціональності, не порушують бізнес-логіку. Зазвичай це помилки в UI.</li>
                        <li><b>Тривіальний (trivial)</b> - дефект не зачіпає функціональність, суттєво не впливає на загальну якість продукту, часто пов’язаний із помилкою сторонніх бібліотек або сервісів.</li>
                        <li><b>Незручність (tweak)</b> - проблема стосується суто інтерфейсу, необхідно «підігнати» його.</li>
                        <li><b>Текстова помилка (text)</b> - одрук, орфографічна чи пунктуаційна помилка.</li>
                    </ul>
                </b-col>
                <b-col>
                    <b>Нотатка по пріоритету:</b>
                    <ul class="ps-4 vstack gap-2 mt-3">
                        <li><b>Високий пріоритет (high)</b> - потрібно виправити помилку якомога швидше, адже вона є критичною, блокує тестування і може призвести до великих фінансових втрат.</li>
                        <li><b>Середній пріоритет (medium)</b> - помилка не є критичною, її можна пофіксити у другу чергу, після виправлення багів із високим пріоритетом.</li>
                        <li><b>Низький пріоритет (low)</b> - помилка не потребує термінового усунення, нічого поганого не станеться, якщо виправити її в останню чергу.</li>
                    </ul>
                </b-col>
            </b-row>
        </b-alert>
    </b-col>
    
     <!-- 
        Табличка
        :columns - колонки таблиці 
        :rows - строки таблиці, дані
        @open - відкрити
    -->
    <tablecustom 
        :columns="columns" 
        :rows="rows"
        @open="open"
    />

    <!-- Картка перегляду репорту -->
    <viewReport 
        v-if="viewReport.show == true"
        @close="viewReport.show = false"
        :objCard="this.viewReport.obj"
    />

</template>


<script>
import tablecustom from '@/components/globaltable/index'; // Таблиця
import viewReport from './view/index' // Компонент перегляду баг-репорту
import { mutateSeriousnessDefect, mutatePriorityTicket } from '@/usabilityScripts/globalMutate'

export default {
    props: ['objCard'],
    components: {
        tablecustom,
        viewReport 
    },
    data() {
        return {
            form: '',
            showNoteCL: false, // прапорець відображення нотатки
            listStatusChecklist: [ // статуси до пунктів чек листа
                { value: 'passed', text: 'Успішно' },
                { value: 'failed', text: 'Не вдалося' },
                { value: 'blocked', text: 'Блокуючий' },
                { value: 'in_progress', text: 'В роботі' },
                { value: 'not_run', text: 'Не перевірено' },
                { value: 'skipped', text: 'Пропущено' },
            ],
            viewReport: {
                show: false,
                obj: {}
            },
            rows: [],
            columns: [
                {
                    name: this.$t("ID"),
                    text: "id",
                    align: "right",
                    status: true,
                    copy: true,
                    mutate: (item) => item ? item : '-'
                },
                {
                    name: this.$t("Назва"),
                    text: "title",
                    align: "right",
                    status: true,
                    copy: true,
                    mutate: (item) => item ? item : '-'
                },
                {
                    name: this.$t("Серйозність дефекту"),
                    text: "seriousness_defect",
                    align: "right",
                    status: true,
                    copy: true,
                    mutate: (item) => item ? this.mutateSeriousnessDefect(item).name : '-'
                },
                {
                    name: this.$t("Пріоритет"),
                    text: "priority",
                    align: "right",
                    status: true,
                    copy: true,
                    mutate: (item) => this.mutatepriority(item).name,
                    mutateClass: (item) => this.mutatepriority(item).color,
                }
            ]
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.objCard
            this.rows = this.objCard.bug_reports
        },
        open(e){
            this.viewReport.obj = e
            this.viewReport.show = true;
        },
        mutateSeriousnessDefect(e){
            // мутейт серйозності дефекту
            return mutateSeriousnessDefect(e)
        },
        mutatepriority(e){
            // мутейт пріорітету
            return mutatePriorityTicket(e)
        },
    }
}
</script>