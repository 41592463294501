<template>
    <b-card>
        <div class="card-header">
            <h5 class="card-title mb-0"><b>{{$t("Synchronized")}}</b></h5>
        </div>
        <div class="card-body">
            <div class="mb-3">
                <!-- Таблиця айдішників -->
                <table class="table" v-if="this.data.items.length > 0">
                    <tbody>
                        <tr v-for="item, i in this.data.items" :key="i" >
                            <th style="cursor: pointer;" @click="this.copy(item)" scope="row">{{item}}</th>
                        </tr>
                    </tbody>
                </table>
                <!-- Результатів нема -->
                <div v-else class="noresult" style="display: block;">

                    <div class="text-center" v-if="checks['024'] == false">
                        <h5 class="mt-2">{{ $t('SorryNoResultFound') }}</h5>
                        <p class="text-muted mb-0">{{ $t('WeveSearchedMoreThan2') }}</p>
                    </div>
                    <div class="text-center" v-else>
                        <img class="gallery-img img-fluid mx-auto" style="max-width: 30%;" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Naruto_Shiki_Fujin.svg/1200px-Naruto_Shiki_Fujin.svg.png" alt="">
                        <h5 class="mt-2">{{ $t('pashNotFound') }}</h5>
                        <p class="text-muted mb-0">{{ $t('pashNotFoundDesc') }}</p>
                    </div>

                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
import {copy} from '@/usabilityScripts/copy.js'
import { storeS } from '@/store'

export default{
    props: ['data'],
    data(){
        return {

        }
    },
    components: {
        
    },
    created(){
        
    },
    methods: {
        // Функція копіювання
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
    },
    computed: {
        checks(){
            return storeS.checks
        }
    }
}
</script>
