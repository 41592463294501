<template>
    <!-- модальное окно закрытия проекта -->
    <dialogBox @close="this.$emit('close')">
        <template v-slot:title>{{$t('editDeadline')}}</template>
        <template v-slot:body>
            <div class="mb-3">
                <p class="text-muted mb-2">
                    <a @click="showModalCreate = true" class="link copy float-end text-decoration-underline">{{ $t('Add') }}</a
                    >{{ $t('project') }}
                </p>
                <b-row>
                    <b-col lg="6">
                        <Multiselect
                            v-model="type"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="typeUpdates"
                            @select="getUpdates"
                            :placeholder="this.$t('project')"
                        />
                    </b-col>
                    <b-col lg="6">
                        <Multiselect
                            v-model="deadline"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="deadlinesList"
                            :disabled="type == ''"
                        />
                    </b-col>
                </b-row>
            </div>
            <!-- <div class="mb-3">
                <Multiselect 
                    v-model="deadline"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    :options="this.deadlinesList"
                    :placeholder="'Deadline'"
                />
            </div> -->
            <!-- <div class="row align-items-center">
                <button type="button" class="btn btn-info" @click="showModalCreate = true">{{ this.$t('AddDeadLine') }}</button>   
            </div> -->
            <createDead v-if="showModalCreate == true" @close="showModalCreate = false" @getData="this.getData()"></createDead>
        </template>
        <template v-slot:footer-bottom>
            <!-- <button class="btn link-danger fw-medium" @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button> -->
            <button 
                type="button" 
                class="btn btn-success" 
                @click="toDec()"
            >
                {{ $t('Edit') }}
            </button>
        </template>
        
    </dialogBox>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import dialogBox from '@/components/modal/dialog'
import { storeS } from '@/store'
import { Tickets, Updates } from '../../../../API';
import createDead from '../../../changelog/new.vue'

let apiServe = new Tickets();
let apiUpdates = new Updates()

export default {
    props: ['obj'],
    components: {
        dialogBox,
        Multiselect,
        createDead
    },
    data(){
        return {
            deadlines: '',
            showModalCreate: false,
            deadlinesList: [],
            deadline: '',
            form: {},
            type: '',
            typeUpdates: [
                // {
                //     label: 'All (for Test)',
                //     value: "all"
                // },
                {
                    label: 'SkyService',
                    value: "skyservice"
                },
                {
                    label: 'Pos-terminal',
                    value: "posterminal"
                },
                {
                    label: 'Dashboard',
                    value: "dashboard"
                },
                {
                    label: 'Sky Market',
                    value: "skymarket"
                },
                {
                    label: 'SellUP',
                    value: "sellup"
                },
                {
                    label: this.$t('orderBoard'),
                    value: "hallscreen"
                },
                {
                    label: 'Inside',
                    value: "inside"
                },
                {
                    label: this.$t('onlyForAndroid'),
                    value: "android"
                },
                {
                    label: this.$t('onlyForIOS'),
                    value: "ios"
                },
            ]
        }
    },
    methods: {
        toDec(){
            this.form = {
                userPhones: this.obj.userPhones,
                ticketName: this.obj.ticketName,
                priority: this.obj.priority,
                status: this.obj.status,
                type: this.obj.type,
                comment: this.obj.createComment,
                deadlineId: this.deadline
            }
            if(this.form.deadlineId != '' && this.form.deadlineId != null) {
                apiServe.editTicket(this.obj.ticketId, this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('alertChanged'))
                        this.$emit('close')
                    } else {
                        this.$toast.error(this.$t('error'))
                    }
                })
            } else {
                this.$toast.error(this.$t(
                    'Null'
                ))
            }
        },
        getUpdates(){
            this.dealinesList = []
            // отримання списку оновлень

            if(this.showModalCreate == true) {
                this.showModalCreate = false
            }

            // todo dev (додати вікно "обрати оновлення"), щоб одразу не пушити 1000 і більше в ліміт
            let objParams = {
                pagelimit: '1000',
                update: this.type,
                status: 'new'
            }
            apiUpdates.getAllUpdates(objParams).then(result => {
                if(result.status == 'done'){
                    this.deadlinesList = []
                    this.deadlines = result.data.items
                    this.deadlines.forEach(element => {
                        console.log(element)
                        if(new Date(element.deadlineDate) - new Date() >= 0 && element.status != 'in_release' && element.status != 'pre_release') { // фільтр по даті
                            this.deadlinesList.push({
                                label: `${this.$t('app')}: ` + element.update.charAt(0).toUpperCase() + element.update.slice(1) + ` | ${this.$t('version')}: ` + element.version + ` | ${this.$t('date')}: ` + element.deadlineDate,
                                value: element.deadlineId
                            })
                        } 
                    });
                } else {
                    this.$toast.error(`error`);
                }
            })
        },
        currentDate() {
            var date = new Date();
            var dateStr =
            date.getFullYear() + "-" +
            ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
            ("00" + (date.getDate())).slice(-2) + " " +
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2);
            return dateStr
        },

        getData() {
            apiUpdates.getAllUpdates({pagelimit: 100}).then(result => {
                if(result.status === 'done'){
                    this.deadlines = result.data.items
                    this.modalEdit = true
                }
            })
        }
    },
    created() {
        this.getData()
    }
}
</script>