<template>

    <!-- Блок з відображенням причин критичності -->
    <b-card v-if="form.reason">
        <h5 class="fw-semibold text-uppercase mb-3 border-bottom pb-2">{{ $t('ReasonsforCriticality') }}</h5>
        <div class="ql-container">
            <div class="ql-editor" v-html="form.reason">
            </div>
        </div>
    </b-card>

    <!-- Блок з описом тікету -->
    <b-card>
        <h5 class="fw-semibold text-uppercase mb-3 border-bottom pb-2">{{ $t('descTicket') }}</h5>
        <div class="ql-container">
            <div class="ql-editor" v-html="form.createComment">
            </div>
        </div>
    </b-card>

    <b-row>

        <!-- Коментар закриття тікету від автора -->
        <b-col lg="6" v-if="form.closeComment != null">
            <div class="card card-animate">
                <div class="card-body">
                    <div class="d-flex align-items-center border-bottom pb-2">
                        <div class="flex-grow-1">
                            <p class="text-uppercase fw-medium text-muted mb-0">{{ $t('ticketClose') }}</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-end justify-content-between mt-4">
                        <div>
                            <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                                <p>{{form.closeComment}}</p>
                            </h4>
                            <a class="text-muted">{{ form.closeDatetime }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </b-col>

        <!-- Коментар перевідкриття тікету від автора -->
        <b-col lg="6" v-if="form.reopenComment != null">
            <div class="card card-animate">
                <div class="card-body">
                    <div class="d-flex align-items-center border-bottom pb-2">
                        <div class="flex-grow-1">
                            <p class="text-uppercase fw-medium text-muted mb-0">{{ $t('reopenTicket') }}</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-end justify-content-between mt-4">
                        <div>
                            <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                                <p>{{form.reopenComment}}</p>
                            </h4>
                            <a class="text-muted">{{ form.reopenDatetime }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </b-col>

        <!-- Блок з рішенням тікету -->
        <b-col lg="6" v-if="form.decidedComment != null && this.form.status != 'jobs'">
            
            <div class="card card-animate">
                <div class="card-body">
                    <div class="d-flex align-items-center border-bottom pb-2">
                        <div class="flex-grow-1">
                            <p class="text-uppercase fw-medium text-muted mb-0">{{ $t('Decision') }}</p>
                        </div>
                        <div class="flex-shrink-0">
                            
                            <!-- Статус рішення тікету (в релізі, чекає на реліз та інше) -->
                            <h5 :class="`fs-12 mb-0 ${this.mutateStatus(form.decidedStatus).color}`">
                                {{ this.mutateStatus(form.decidedStatus).name }}
                            </h5>

                            <!-- Переглянути інші дані по рішенню -->
                            <!-- <button 
                                type="button" 
                                style="margin-left: 10px;"
                                class="btn btn-info btn-icon btn-sm fs-16 close-btn-email"
                            >
                                <i class="ri-eye-fill align-bottom"></i>
                            </button> -->
                        </div>
                    </div>
                    <div class="d-flex align-items-end justify-content-between mt-4">
                        <div>
                            <!-- Коментар рішення до тікету від розробника -->
                            <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                                <p>{{form.decidedComment}}</p>
                            </h4>
                            <!-- <a class="text-muted">{{ form.decidedDatetime }}</a>
                            <a class="text-muted">{{ getDeadline(form) }}</a> -->
                        </div>
                    </div>
                    <div class="d-flex align-items-center pb-2" >

                        <!-- Дата та час додавання рішення -->
                        <div class="flex-grow-1">
                            <p class="text-uppercase fw-medium text-muted mb-0">
                                {{form.decidedDatetime}}
                            </p>
                        </div>
                        
                        <!-- Дедлайн -->
                        <div class="flex-shrink-0">
                            <h5 
                                class="fs-12 mb-0 badge bg-info" 
                                v-if="form.deadlineId"
                            >
                                {{ $t('dateRelease') }} : {{ getDeadline(form) }}
                            </h5>
                        </div>
                    </div>

                    <!-- <div class="d-flex mt-3" v-if="form.status != 'closed'"> -->
                    <div class="d-flex mt-3" >

                        <!-- 
                            Кнопка "Деталі рішення"
                        -->
                        <button 
                            type="button" 
                            :class="`btn btn-soft-primary ${checkWidthDetail() ? '' : 'w-100'} `" 
                            style="margin-top: 10px;margin-right: 5px;"
                            v-if="checkShowDetail() || perms[2000]"
                            @click="showDetailBox = true"
                        >
                        <!-- v-if="checkShowDetail()" -->
                            <i class="ri-eye-fill align-bottom"></i> <span v-if="!checkWidthDetail()" style="margin-left: 3px;">Деталі рішення</span>
                        </button>

                        <!-- 
                            Кнопка "Підтвердити тестування"
                            - йде перевірка на автора тікету
                            - йде перевірка на статус тікету
                            - - статус має бути "очікує тестування"
                        -->
                        <button 
                            type="button" 
                            class="btn btn-success w-100" 
                            style="margin-top: 10px;margin-right: 5px;" 
                            @click="inRelease()" 
                            v-if="(perms[2000] == true || form.createWorkerId == this.user.userid) && this.form.decidedStatus == 'waittesting' && form.status != 'closed'"
                        >
                            {{ this.$t('Confirm') }}
                        </button>

                        <!-- 
                            Кнопка "Закрити тікет"
                            - йде перевірка на права доступу 301 (додати тікет) та 2000(режим бога)
                            - йде перевірка на автора тікету
                            - йде перевірка на статус тікету
                            - - статус має бути "в релізі"
                        -->
                        <button 
                            type="button" 
                            class="btn btn-success w-100" 
                            style="margin-top: 10px;margin-right: 5px;" 
                            @click="showClose()" 
                            v-if="(perms[2000] == true || form.createWorkerId == this.user.userid) && (this.form.decidedStatus == 'inrelease' || this.form.decidedStatus == 'in_release' || this.form.decidedStatus == 'noProblem') && form.status != 'closed'"
                        >
                            {{ this.$t('closeTicket') }}
                        </button>

                        <!-- 
                            Кнопка "Перевідкрити тікет"
                            - йде перевірка на права доступу 301 (додати тікет) та 2000(режим бога)
                            - йде перевірка на автора тікету
                            - йде перевірка на статус тікету
                            - - статус має бути "очікує тестування" або "в релізі"
                        -->
                        <button 
                            type="button" 
                            class="btn btn-danger w-100" 
                            style="margin-top: 10px;" 
                            @click="showReopen()" 
                            v-if="(perms[2000] == true || form.createWorkerId == this.user.userid) && (this.form.decidedStatus == 'waittesting' || this.form.decidedStatus == 'inrelease' || this.form.decidedStatus == 'in_release' || this.form.decidedStatus == 'noProblem')"
                        >
                            {{ this.$t('ReopenTicket') }}
                        </button>

                    </div>
                </div>
            </div>
        </b-col>
    </b-row>

    <boxDetailDecision 
        v-if="showDetailBox"
        @close="showDetailBox = false"
        :objCard="obj"
    />
</template>

<script>
import boxDetailDecision from './boxDetailDecision.vue';
import { mutateDecidedStatusTicket } from '@/usabilityScripts/globalMutate.js'
import { storeS } from '@/store'

export default{
    props: ['obj'],
    components: {
        boxDetailDecision
    },
    data(){
        return{
            form:'',
            showDetailBox: false
        }
    },
    created(){
        this.getdata();
    },
    mounted() {
        // for(let i = 0; i<document.querySelector('.ql-editor').querySelectorAll('img').length; i++) {
        //     document.querySelector('.ql-editor').querySelectorAll('img')[i].addEventListener('click', () => {
        //         this.eventBus.emit('fullImageSize', {
        //             url: document.querySelector('.ql-editor').querySelectorAll('img')[i].getAttribute("src") + ''
        //         })

                //  don`t uncomment 

                // document.querySelector('.ql-editor').querySelectorAll('img')[i].getAttribute("src")
                // let newDiv = document.createElement('div')
                // newDiv.className = 'fullSizeImage'
                // newDiv.style.top = '5vh'
                // newDiv.style.left = '5vw'
                // newDiv.style.zIndex = '99999'
                // newDiv.style.position = 'absolute'
                // newDiv.style.backgroundImage = `url(${document.querySelector('.ql-editor').querySelectorAll('img')[i].getAttribute("src")})`
                // newDiv.style.backgroundRepeat = 'no-repeat'
                // let height = document.querySelector('.ql-editor').querySelectorAll('img')[i].offsetHeight * 3
                // let width = document.querySelector('.ql-editor').querySelectorAll('img')[i].offsetWidth * 3
                // console.log(height, width)
                // newDiv.style['height'] = `${height}`
                // newDiv.style['width'] = `${width}`
                // newDiv.style.backgroundColor = 'black'
                // let currentDiv = document.querySelector('#app')
                // document.body.insertBefore(newDiv, currentDiv)

                //  don`t uncomment 

        //     })
        // }
    },
    methods: {
        inRelease() {
            this.$emit('inRelease', this.form.ticketId)
        },
        showReopen() {
            this.$emit('showReopen', this.form.ticketId)
        },
        showClose(){
            this.$emit('showClose', this.form.ticketId)
        },
        getdata(){
            this.form = this.obj
        },
        mutateStatus(e){
            return mutateDecidedStatusTicket(e)
        },
        getDeadline(e){
            const deadline = this.deadlines.find(el => el.deadlineId === e.deadlineId);

            if (deadline) {
                // Видаляємо час, залишаємо лише дату
                const dateOnly = deadline.deadlineDate.split(' ')[0];
                console.log("deadlineDate", dateOnly);
                return dateOnly;
            } else {
                console.log("deadlineDate", "-");
                return "-";
            }
        },
        checkShowDetail(){
            if(
                this.form.problemIn == '' 
                && this.form.sinceProblemKnown == ''
                && this.form.solutionReason == ''
                && this.form.problemWas == false
                && this.form.passedTest == false
                && this.form.type == "problem"
            ) {
                return false
            } else {
                return true
            }
        },
        checkWidthDetail(){
            if(
                (this.perms[2000] == true || this.form.createWorkerId == this.user.userid)
                && (this.form.decidedStatus == 'inrelease' || this.form.decidedStatus == 'in_release' || this.form.decidedStatus == 'noProblem' || this.form.decidedStatus == 'waittesting')
            ) {
                return true
            } else {
                return false
            }
        }
    },
    computed: {
        deadlines(){
            return storeS.dealines
        },
        user(){
            return storeS.userbase
        },
        perms(){
            return storeS.perms
        }
    }
}
</script>

<style scoped>
</style>