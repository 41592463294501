<template>
    <!-- модальное окно просмотра заявки -->
    <modal :key="componentKey" :ifPrevopen="ifPrevopen" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen" @close="closeModal" :styleAdd="'backdrop-filter: blur(0px) !important;'">
        <template v-slot:title>{{ form.status == 'new' ? $t('newApplication') : $t('Application') }} #{{ this.form.requestId }}</template>
        <template v-slot:status>
            <span v-if="form.action" :class="`badge badge ${this.mutateType(this.form.action).color} align-middle fs-14`"><i :class="this.mutateType(this.form.action).icon"></i></span>
            <span :style="form.action ? `margin-left: 5px;` : ''" :class="`badge badge ${this.mutateStatus(this.form.status, this.form).color} align-middle fs-14`">{{this.mutateStatus(this.form.status, this.form).name}}</span>
        </template>
        <template v-slot:head-button>
            <div class="dropdown" v-if="(form.status !== 'deleted' && (perms[103] || perms[102] || perms[105]) && (form.inJobWorkerId == this.user.userid || form.inJobWorkerId == null)) || this.form.status =='callNow' || perms[2000]">
                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                    <button class="dropdown-item"  v-if='((perms[103] || perms[2000]) && (form.status === "wait_comment" || form.status === "jobs") && (form.inJobWorkerId == this.user.userid || form.inJobWorkerId == null))' @click="showModalClose = true">{{ $t('close') }}</button> <!-- закрыть заявку -->
                    <button class="dropdown-item" v-if="(form.status === 'new' && (perms[105] === true || perms[2000] === true))" @click="this.$emit('edit', this.form)">{{ $t('Edit') }}</button> <!-- редактировать заявку -->
                    <button class="dropdown-item" v-if="((form.status === 'jobs' || form.status === 'delayed' || form.status === 'wait_comment') && (form.inJobWorkerId == this.user.userid || form.inJobWorkerId == null)) || this.form.status=='callNow'" @click="showModalDelay = true">{{ $t('postpone')}}</button>  <!-- отложить заявку -->
                    <button class="dropdown-item" v-if="((form.status === 'jobs'  || form.status === 'wait_comment') && (perms[102]) || perms[2000])" @click="showModalTransfer = true">{{ $t('Submit')}}</button> <!-- передать заявку -->
                    <button class="dropdown-item" v-if="(perms[103] || perms[2000])" @click="dialogshow = true">{{ $t('Delete') }}</button> <!-- удалить заявку -->
                    <button class="dropdown-item" v-if="((perms[105] || perms[2000]) && form.status !== 'canceled' && this.form.type !== 'request' && form.status !== 'closed' && form.status !== 'callNow' && form.type=='requestByCall') || this.user.workerJob == 'supportmanager' || this.user.workerJob == 'manager'" @click="callNow(this.form.requestId)">{{ $t('CallNow') }}</button>
                </div>
            </div>
        </template>
        <template v-slot:body>
            <b-alert v-if="form.status == 'transfered'" show variant="warning" class="text-center">
                <h5 class="alert-heading" v-if="this.user.userid != form.transferWorkerId">
                    <b>{{ this.form.inJobWorkerName }} {{ $t('submittedRequest') }} {{ nameWorker(form.transferWorkerId) }}</b>
                </h5>
                <h5 class="alert-heading" v-else>
                    <b>{{ this.form.inJobWorkerName }} {{ $t('sentYouRequest') }}</b>
                </h5>
            </b-alert>
            <b-row>
                <b-col lg="4">
                    <!-- <block
                        :blocks="this.aboutBlock"
                        :title="this.$t('aboutApplication')"
                        @copy="copy"
                        @getClient="getClient"
                    /> -->
                    <aboutBlock 
                        :obj="this.form"
                        @getClient="this.getClient"
                    />
                </b-col>

                <b-col lg="4">
                    <clientBlock
                        :obj="this.form"
                        @getClient="this.getClient"
                    />
                </b-col>

                <b-col lg="4" v-if="form.inJobWorkerId != null">
                    <jobsBlock
                        :obj="this.form"
                    />
                </b-col>

                <b-col lg="4" v-if="form.closeWorkerId != null">
                    <closedBlock 
                        :obj="this.form"
                    />
                </b-col>
                <b-col lg="4" v-if="form.cancelWorkerId != null">
                    <canceledBlock 
                        :obj="this.form"
                    />
                </b-col>
            </b-row>
                
        </template>
        <template v-slot:footer-bottom>
                <!-- <button 
                    type="button"
                    @click="this.getClient(this.form.userPhone)"
                    :class="`btn btn-info ss waves-effect waves-light`">
                    {{ $t('infoAboutClient')}}
                </button> -->

                <button 
                    type="button" 
                    v-if="(form.status != 'new' && (perms[103] || perms[2000]) && form.inJobWorkerId == this.user.userid)"
                    @click="showModalCancel = true" 
                    class="btn btn-danger r waves-effect waves-light">
                    {{ $t('cancel') }}
                </button> <!-- отмена заявки -->

                <button 
                    type="button" 
                    v-if="(form.status != 'new' && (perms[103] || perms[2000]) && form.inJobWorkerId == this.user.userid)"
                    @click="showModalRepCancel = true" 
                    class="btn btn-danger r waves-effect waves-light">
                    {{ $t('cancelReport') }} 
                </button> <!-- додати репорт на скасування заявки -->

                <button 
                    type="button" 
                    v-if="this.form.inJobWorkerName === this.user.name && (this.form.status=='jobs' || this.form.status=='wait_comment')"
                    @click="showModalClose = true"
                    class="btn btn-success r waves-effect waves-light">
                    {{ $t('done') }}
                </button> <!-- выполнить/закрыть заявку -->

                <button 
                    type="button"
                    v-if="perms[104] && (form.status == 'new'  || this.form.status=='callNow' || this.form.status=='delayed')"
                    @click="toWork()"
                    :class="`btn btn-success ss waves-effect waves-light`">
                    {{ $t('takeToWork') }}
                </button> <!-- взять в работу заявку -->
                
                <button 
                    type="button"
                    v-if="perms[114] && form.status == 'transfered' && form.transferWorkerId == this.user.userid"
                    @click="rejectTransfer()"
                    :class="`btn btn-danger ss waves-effect waves-light`">
                    {{ $t('Refusal') }}
                </button> <!-- відмовитись від передачі заявки -->

                <button 
                    type="button"
                    v-if="perms[104] && form.status == 'transfered' && form.transferWorkerId == this.user.userid"
                    @click="confirmTransfer()"
                    :class="`btn btn-success ss waves-effect waves-light`">
                    {{ $t('ConfirmTransfer') }}
                </button> <!-- підтвердити передачу -->

        </template>
    </modal>

    <!-- показати не зареєстровані -->
    <!-- <viewUnRegist
        v-if="showViewUnRegist == true"
        :objCard="this.objClient"
        @close = "showViewUnRegist = false"
    ></viewUnRegist> -->
    <!-- показати не зареєстровані -->
    
    <!-- модалка отмены -->
    <modalCancel 
        v-if="showModalCancel == true" 
        @close="showModalCancel = false"
        :id="this.form.requestId"
        @toCancel="toCancel"
    />
    <!-- модалка отмены -->

    
    <!-- модалка репорта скасування -->
    <modalCancelReport 
        v-if="showModalRepCancel == true" 
        @close="showModalRepCancel = false"
        :id="this.form.requestId"
        @toCancelReport="toCancelReport"
    />
    <!-- модалка репорта скасування -->

    <!-- отложить заявку -->
    <modalDelay 
        v-if="showModalDelay"
        @close="showModalDelay = false"
        :id="this.form.requestId"
        @toDelay="toDelay"
    />
    <!-- отложить заявку -->

    <!-- закрити заявку -->
    <modalClose 
        v-if="showModalClose == true"
        @close="showModalClose = false"
        :obj="this.form"
        @toClose="toClose"
    />
    <!-- закрити заявку -->

    <!-- передати заявку -->
    <modalTransfer 
        v-if="showModalTransfer == true"
        @close="showModalTransfer = false"
        :id="this.form.requestId"
        :obj = "this.form"
        @transferRequest="transferRequest"
    />
    <!-- передати заявку -->

    <!-- Видалити заявку -->
    <dialodel
        v-if="this.dialogshow == true" 
        @close="this.dialogshow = false" 
        :obj="item" 
        @remove="remove" 
    />

</template>

<script>
import { mutatePriority } from '@/usabilityScripts/globalMutate.js'
import {copy} from '@/usabilityScripts/copy.js'
import block from '../../../components/modal/modal_parts/block.vue'
import viewUnRegist from '@/views/accounts/unregistered/view/index'
import modal from '@/components/modal/modal-lg'
import aboutBlock from './blocks/about'
import clientBlock from './blocks/client'
import jobsBlock from './blocks/jobs'
import closedBlock from './blocks/closed'
import canceledBlock from './blocks/canceled'
import viewboxnew from '@/views/accounts/allAccounts/viewnew/index.vue'
// import viewboxnew from '@/views/clientnew/index.vue'
import dialogBox from '@/components/modal/dialog'
import dialodel from '@/components/globaltable/actions/dialogdelete.vue'
import modalCancel from './actions/cancel'
import modalClose from './actions/close'
import modalDelay from './actions/delay'
import modalTransfer from './actions/transfer'
import modalCancelReport from './actions/cancelReport'
import { SuperAdmin, Applications, OtherCalls, Unregistered } from "@/API.js"
import { storeS } from '@/store.js'
import Multiselect from "@vueform/multiselect";
import { mutateStatus, mutateTypeRequest } from '@/usabilityScripts/globalMutate.js'
import { nameWorker } from '@/usabilityScripts/getNameWorker'

let apiServe = new SuperAdmin();
let apiOther = new OtherCalls()
let apiApp = new Applications();

export default ({
    data(){
        return {
            aboutBlock: [
                {
                    show: true,
                    class: 'info',
                    icon: 'ri-hashtag',
                    text: this.$t("phone_number"),
                    textColor: '',
                    value: '1',
                    event: {
                        name: 'getClient',
                        value: ''
                    },
                    copy: {
                        status: true,
                        value: ''
                    }
                },
                {
                    show: true,
                    class: 'info',
                    icon: 'ri-hashtag',
                    text: this.$t("priority"),
                    textColor: '',
                    value: '1',
                    event: {
                        name: 'getClient',
                        value: ''
                    },
                    copy: {
                        status: false,
                        value: ''
                    }
                },
                {
                    show: true,
                    class: 'info',
                    icon: 'bx bxs-user-account',
                    text: this.$t("created"),
                    textColor: '',
                    value: '',
                    event: {
                        name: '',
                        value: ''
                    },
                    copy: {
                        status: true,
                        value: ''
                    }
                },
                {
                    show: true,
                    class: 'info',
                    icon: 'bx bx-time',
                    text: this.$t("createDate"),
                    textColor: '',
                    value: '',
                    event: {
                        name: '',
                        value: ''
                    },
                    copy: {
                        status: true,
                        value: ''
                    }
                },
                {
                    show: true,
                    class: 'info',
                    icon: 'ri-hashtag',
                    text: this.$t("Comment"),
                    textColor: '',
                    value: '1',
                    event: {
                        name: 'getClient',
                        value: ''
                    },
                    copy: {
                        status: true,
                        value: ''
                    }
                },
                {
                    show: true,
                    class: 'info',
                    icon: 'ri-hashtag',
                    text: this.$t("Comment") + " (2)",
                    textColor: '',
                    value: '1',
                    event: {
                        name: 'getClient',
                        value: ''
                    },
                    copy: {
                        status: true,
                        value: ''
                    }
                }
            ],
            modals: [],
            showViewUnRegist: false,
            showModalRepCancel: false,
            showModalCancel: false,
            showModalDelay: false,
            toWorkerName: '',
            modalConfirmSecond: false,
            modalConfirm: false,
            toWorker: '',
            modalDialogSnooze: false,
            date1:"",
            modalDialogThrow: false,
            checked: false,
            modalDialog: false,
            dialogshow: false,
            dateAndTime: {
                delayedTime: this.currentDateForApplic().minTime,
                delayedDate: this.currentDateForApplic().minDate,
            },
            mainmodal: false,
            dateTimeConfig: {
                enableTime: true,
                dateFormat: "d.m.y H:i",
            },
            testshowModalclient: false,
            objClient: {},
            form: {
                userPhone: "",
                // user_email: "",
                priority: "",
                // received: "",
                status: "",
            },
            showModal: false,
            showModalClose: false,
            showModalTransfer: false,
            number: "",
            listWorkers: [],
            componentKey: '',
            count: 0
        }
    },
    props: ["phone", "shortapplic", 'ifPrevopen', 'ifNextopen', 'closeOne', 'client'],
    components: {
        block,
        Multiselect,
        modal,
        dialogBox,
        viewboxnew,
        dialodel,
        aboutBlock,
        clientBlock,
        jobsBlock,
        closedBlock,
        modalCancel,
        modalClose,
        modalDelay,
        modalTransfer,
        modalCancelReport,
        viewUnRegist,
        Unregistered,
        canceledBlock
    },
    created(){        
        // set info in block
        this.setAboutBlock()
        // set info in block

        this.form = this.shortapplic;
        storeS.workers.forEach( (el)=> {
            if(el.workerJob === 'managercallcenter') {
                this.listWorkers.push({
                    value: el.workerId,
                    label: el.workerName
                })
            } //список работников
        })

        storeS.checkModal.type = 'request';
        storeS.checkModal.id = this.form.requestId;
    },
    methods: {
        setAboutBlock() {
            this.aboutBlock[0].value = this.shortapplic.userPhone
            this.aboutBlock[0].event.value = this.shortapplic.userPhone
            this.aboutBlock[0].copy.value = this.shortapplic.userPhone
            this.aboutBlock[1].textColor = this.mutatePriorityApplic(this.shortapplic.priority).bg
            this.aboutBlock[1].value = this.mutatePriorityApplic(this.shortapplic.priority).name
            
            if(
                this.shortapplic.createWorkerName && 
                this.shortapplic.createWorkerName != ""
            ) {
                this.aboutBlock[2].value = this.shortapplic.createWorkerName
                this.aboutBlock[2].copy.value = this.shortapplic.createWorkerName
            } else {
                this.aboutBlock[2].show = false
            }

            this.aboutBlock[3].value = this.shortapplic.createDatetime
            this.aboutBlock[3].copy.value = this.shortapplic.createDatetime

            if(
                (this.shortapplic.createComment && 
                this.shortapplic.createComment != "") ||
                this.form.ticketName
            ) {
                if(!this.form.ticketName) {
                    this.aboutBlock[4].value = this.$t('createdFromTicket') +
                    + ": " + this.form.ticketName
                    this.aboutBlock[4].copy.value = this.$t('createdFromTicket') +
                    + ": " + this.form.ticketName
                } else {
                    this.aboutBlock[4].value = this.shortapplic?.createComment
                    this.aboutBlock[4].copy.value = this.shortapplic?.createComment
                }
            } else {
                this.aboutBlock[4].show = false
            }

            if(this.form.fromSite == 1) {
                this.aboutBlock[5].value = this.$t('fromSite')
                this.aboutBlock[5].copy.value = this.$t('fromSite')
            } else {
                this.aboutBlock[5].show = false
            } 
        }, 
        setCancelBlock() {

        },
        setClientBlock() {

        },
        setClosedBlock() {

        },
        setJobsBlock() {

        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        toCancel(e, form){
            if(this.form.type == 'call'  || this.form.type == 'request') {
                apiApp.cancelApplic(e, form).then(result=>{
                    if(result.status=='done') {
                        this.$toast.success(this.$t('canceled'))
                        // this.$emit('getdata')
                        this.$emit('close')
                    } else {
                        this.$toast.error(this.$t('error'))
                    }
                })
            } else {
                apiOther.cancelApplic(e, form).then(result=>{
                    if(result.status=='done') {
                        this.$toast.success(this.$t('canceled'))
                        // this.$emit('getdata')
                        this.$emit('close')
                    } else {
                        this.$toast.error(this.$t('error'))
                    }
                })
            }
        },
        toCancelReport(e, form) {
            apiApp.addReportOnCancelRequest(e, form).then(result=>{
                if(result.status=='done') {
                    this.$toast.success(this.$t('report_sent'))
                    this.$emit('getdata')
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        callNow(id) {
            if(this.form.type == "requestByCall")  {
                apiOther.callNow(id).then(result=>{
                    if(result.status=='done') {
                        this.$emit('close')
                        this.$toast.success(this.$t('successfully'))
                        this.$emit('getdata')
                    }
                })
            } else {
                apiApp.callNow(id).then(result=>{
                    if(result.status=='done') {
                        this.$emit('close')
                        this.$toast.success(this.$t('successfully'))
                        this.$emit('getdata')
                    }
                })
            }
        },
        call(e) {
            localStorage.setItem('actualCall', e)
            this.eventBus.emit('actualCall', {
                status: true
            })
        },
        closeModal() {
            //закрытие заявки
            storeS.checkModal.type = '';
            storeS.checkModal.id = '';
            this.$emit('close');
            // this.$emit('getdata');
        },
        prevOpen(){
            //пред заявка
            this.$emit('close');
            this.$emit('prevOpen', this.form.requestId)
        },
        nextOpen(){
            //след заявка
            this.$emit('close');
            this.$emit('nextOpen', this.form.requestId)
        },
        updateCard(){
            //обновить данные
            if(this.form.type == 'call' || this.form.type == 'request') {
                apiApp.getRequest(this.form.requestId).then(result => {
                    if(result.status == 'done'){
                        this.form = result.data;
                        this.componentKey += 1
                    }
                })
            } else {
                apiOther.getRequest(this.form.requestId).then(result => {
                    if(result.status == 'done'){
                        this.form = result.data;
                        this.componentKey += 1
                    }
                })
            }
        },
        toClose(e, obj) {
            //закрыть заявку
            if(this.form.type == 'call'  || this.form.type == 'request') { 
                apiApp.closeRequest(e, obj).then(result => {
                    if(result.status === 'done') {
                        this.$toast.success(this.$t('closed'))
                        this.updateCard()
                        this.$emit('close')
                    }
                })
            } else {
                apiOther.closeRequest(e, obj).then(result => {
                    if(result.status === 'done') {
                        this.$toast.success(this.$t('closed'))
                        this.updateCard()
                        this.$emit('close')
                    }
                })
            }
        },
        toWork: function(e){
            //взять в работу
            try {
                navigator.clipboard.writeText(this.form.userPhone)
                this.$toast.success(this.$t('Copied'));
            } catch {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            if(this.form.type == 'call' || this.form.type == 'request') {
                if(this.perms['104']== true || this.perms['2000'] == true) {
                    apiApp.addRequestInJob(this.form.requestId).then(res => {
                        if(res.status === 'done') {
                            // this.$emit('toWork') //инициализация события
                            this.updateCard();
                            this.$toast.success(this.$t('statusInWork'));
                            // this.getClient(this.form.userPhone);
                        }   
                    })
                }
            } else {
                apiOther.addRequestInJob(this.form.requestId).then(res => {
                    if(res.status === 'done') {
                        // this.$emit('toWork') //инициализация события
                        this.updateCard();
                        this.$toast.success(this.$t('statusInWork'));
                        this.getClient(this.form.userPhone);
                    }   
                })
            }
        },
        toDelay: function(id,dateAndTime) {
            let  form = {
                delayedTime: dateAndTime.delayedTime,
                delayedDate: dateAndTime.delayedDate,
                comment: dateAndTime.comment
            }
            if(this.form.type == 'call' || this.form.type == 'request') {
                apiApp.delayRequest(id, form).then(result => {
                    if(result.status === 'done') {
                        this.$toast.success(this.$t('Delayed'))
                        this.showModalDelay = false
                        this.$emit('close') //отмена заявки
                        this.$nextTick().then(this.$emit('toSnooze'))
                    } else {
                        if(result.error[0] == "Request already exists") {
                            this.$toast.error(this.$t('aplic_already_yet'))
                        } else {
                            this.$toast.error(this.$t('error'))
                        }
                    }
                })
            } else {
                apiOther.delayRequest(id, form).then(result => {
                    if(result.status === 'done') {
                        this.$toast.success(this.$t('Delayed'))
                        this.showModalDelay = false
                        this.$emit('close') //отмена заявки
                        this.$nextTick().then(this.$emit('toSnooze'))
                    } else {
                        if(result.error[0] == "Request already exists") {
                            this.$toast.error(this.$t('aplic_already_yet'))
                        } else {
                            this.$toast.error(this.$t('error'))
                        }
                    }
                })
            }
        },
        cancelThrow() {
            this.modalConfirmSecond = false;
            this.$toast.error(this.toWorkerName + " " + this.$t('didn`t accept')); // отмена переачи
        },
        transferRequest(id, toWorker, obj){
            if(this.form.type=='requestByCall') {
                apiOther.transferRequest(id, toWorker, obj).then(result => {
                    if(result.status === 'done') {
                        this.$toast.success('TransferRequestSent');
                        this.$emit('getdata')
                        this.$emit('close') //передача заявки
                    } else {
                        this.$toast.error(this.$t('err'))
                    }
                })
            } else { 
                apiApp.transferRequest(id, toWorker, obj).then(result => {
                    if(result.status === 'done') {
                        this.$toast.success('TransferRequestSent');
                        this.$emit('getdata')
                        this.$emit('close') //передача заявки
                    } else {
                        this.$toast.error(this.$t('err'))
                    }
                })
            }
        },
        confirmTransfer(){
            if(this.form.type == 'requestByCall') {
                apiOther.fixationTransferRequest(this.form.requestId, this.form.transferWorkerId).then(result => {
                    if(result.status === 'done') {
                        this.$toast.success(this.$t('threw'));
                        this.$emit('close') //передача заявки
                    } else {
                        this.$toast.error(this.$t('err'))
                    }
                })
            } else {
                apiApp.fixationTransferRequest(this.form.requestId, this.form.transferWorkerId).then(result => {
                    if(result.status === 'done') {
                        this.$toast.success(this.$t('threw'));
                        // this.$emit('close') //фікасація отримання заявки
                        this.updateCard()
                    } else {
                        this.$toast.error(this.$t('err'))
                    }
                })
            }
            
        },
        rejectTransfer(){
            if(this.form.type == 'requestByCall') {
                apiOther.rejectTransferRequest(this.form.requestId).then(result => {
                    if(result.status === 'done') {
                        this.$toast.success(this.$t('didn`t accept'));
                        this.$emit('close')
                        this.$emit('getdata')
                    } else {
                        this.$toast.error(this.$t('err'))
                    }
                })
            } else {
                apiApp.rejectTransferRequest(this.form.requestId).then(result => {
                    if(result.status === 'done') {
                        this.$toast.success(this.$t('didn`t accept'));
                        this.$emit('close')
                        this.$emit('getdata')
                    } else {
                        this.$toast.error(this.$t('err'))
                    }
                })
            }
        },
        // toThrow: function() {
        //     this.modalConfirm = false;
        //     let foo = [];
        //     this.modalConfirmSecond = true; //создание передачи
        // },
        // toThrowConfirm: function() {
        //     apiApp.transferRequest(this.form.requestId, this.toWorker).then(result => {
        //         if(result.status === 'done') {
        //             apiApp.fixationTransferRequest(this.form.requestId, this.toWorker).then(result=> {
        //                 if(result.status === 'done') {
        //                     this.$emit('close') //передача заявки
        //                     this.$nextTick().then(this.$emit('toThrow', this.toWorkerName))
        //                 } else {
        //                     this.$toast.error(this.$t('err'))
        //                 }
        //             })
        //         } else {
        //             this.$toast.error(this.$t('applic_not_in_work'));  //подвтверждение передачи
        //         }
        //     })
        // },
        remove: function(){
            //удалить
            apiApp.deleteRequest(this.form.requestId).then(result=>{
                if(result.status=='done') {
                    this.$toast.success(this.$t('Removed'))
                    this.$emit('get')
                    this.$emit('close')
                }
            })
            
            
        },
        getClient(e) {
            
            //открыть карточку клиента
            if(this.count !== 1) {
                this.count = 1
                apiServe.getCard(e).then(result => {
                    if(result.status == 'done'){
                        this.objClient = result.data
                        if(result.data.accountId == false) {
                            this.modals.push({
                                form: this.form,
                                name: 'applic',
                            })
                            this.$emit('openUnregistered',this.objClient, this.modals)
                            this.count = 0
                            // this.$emit('close')
                        } else {
                            this.modals.push({
                                form: this.form,
                                name: 'applic',
                            })
                            this.$emit('openRegistered',this.objClient, this.modals)
                            this.count = 0
                        }
                        
                    }
                })
            }
            
        },
        currentDate() {
            var date = new Date();
            var dateStr =
            ("00" + date.getMonth()).slice(-2) + "." +
            ("00" + (date.getDate() + 1)).slice(-2) + "." +
            date.getFullYear() + " " +
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2);
            return dateStr
        },
        currentDateForApplic() {
            return {
                minTime: "" + new Date().getHours()+':'+new Date().getMinutes(),
                minDate: "" +  new Date().getFullYear() + '-' + this.mutateData(String(new Date()).split(' ')[1]) + '-' +  new Date().getDate()
            }
            //дата для переноса
        },
        nameWorker(item){
            return nameWorker(item)
        },
        mutatePriorityApplic(priority) {
            return mutatePriority(priority)
        },
        mutateData(month) {
            switch(month){
                case "Jan": 
                    month = '1'
                    break;
                case "Feb": 
                    month = '2'
                    break;
                case "Mar": 
                    month = '3'
                    break;
                case "Apr": 
                    month = '4'
                    break;
                case "May": 
                    month = '5'
                    break;
                case "Jun": 
                    month = '6'
                    break;
                case "Jul": 
                    month = '7'
                    break;
                case "Aug": 
                    month = '8'
                    break;
                case "Sep": 
                    month = '9'
                    break;
                case "Oct": 
                    month = '10'
                    break;
                case "Nov": 
                    month = '11'
                    break;
                case "Dec": 
                    month = '12'
                    break;
            }
            return month //по месяцам
        },
        mutateStatus(e, obj){
            return mutateStatus(e, obj);
        },
        mutateType(e){
            return mutateTypeRequest(e)
        }
    },
    mounted() {

        console.log(this.scale)
        // this.eventKey.on('remove', () => {
        //     if(this.form.status == 'statuswait_delete'){
        //         this.dialogshow = true
        //     }
        // })
        this.eventBus.off('updateRequest');
        this.eventBus.on('updateRequest', (status) => {
            if(status == true) {
                this.updateCard();
            }
        })

        this.eventB.off('sendToApplic')
        this.eventB.on('sendToApplic', status => {
            if(status.status.requestId == this.form.requestId) {
                console.log(status)
            }
        })
    },
    computed:{
        perms() {
            return storeS.perms
        },
        user() {
            return storeS.userbase
        },
        workers(){
            return storeS.workers //база работников
        },
        scale(){
            return window.devicePixelRatio
        }
    }
})
</script>


<style scoped>
.copybtn {
    cursor: pointer;
    margin-top: -3px;
    margin-left: 5px;
    opacity: 0;
    transition: opacity .3s;
}
.c_block:hover .copybtn {
    opacity: 1;
    transition: opacity .3s;
}

.copybtn:checked {
    width: 500px;
}
</style>
