<template>

    <!-- 
        Правий блок модального вікна
        - деталі
        - тікет (якщо є)
        - учасники
        - файли
    -->

    <div class="col-lg-3" >

        <!-- Деталі картки -->
        <div class="card" >
            <div class="card-header align-items-center d-flex border-bottom-dashed" >
                <h4 class="card-title mb-0 flex-grow-1">Деталі</h4>
            </div>
            <div class="card-body">
                <div class="table-responsive table-card" >
                    <table class="table table-borderless align-middle mb-0">
                        <tbody>
                            <tr>
                                <td class="fw-medium">Тестування</td>
                                <td>#<span id="t-no">666</span> </td>
                            </tr>
                            <tr>
                                <td class="fw-medium">Пріоритет</td>
                                <td id="t-client">Високий</td>
                            </tr>
                            <tr>
                                <td class="fw-medium">Статус</td>
                                <td>Новий</td>
                            </tr>
                            <tr>
                                <td class="fw-medium">Версія фікс:</td>
                                <td>
                                    1.22
                                </td>
                            </tr>
                            <tr>
                                <td class="fw-medium">Проект</td>
                                <td>
                                    Pos-термінал
                                </td>
                            </tr>
                            <tr>
                                <td class="fw-medium">Дата створення</td>
                                <td id="c-date">10 вересня, 2024</td>
                            </tr>
                            <tr>
                                <td class="fw-medium">Термін виконання</td>
                                <td id="d-date">10 Жовтня, 2024</td>
                            </tr>
                            <tr>
                                <td class="fw-medium">Мітки</td>
                                <td class="hstack text-wrap gap-1">
                                    <span class="badge bg-info">Мітка</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Підрахунок по таскам -->
        <div class="card" v-if="form.fromTicket.ticketId != ''">
            <div class="card-header align-items-center d-flex border-bottom-dashed" >
                <h4 class="card-title mb-0 flex-grow-1">Тестова інформація</h4>
            </div>
            <div class="card-body" >
                <b-row>
                    <b-col lg="6 border-end-dashed" style="border-color: var(--vz-border-color)!important;">
                        <div class="card-body" >
                            <div class="d-flex justify-content-center text-center" >
                                <div >
                                    <h2 class="mt-4 text-warning fw-semibold"><span class="counter-value">{{ testInfo.waitItem }}</span></h2>
                                    <h2 class="mt-4 text-warning fw-semibold"><span class="counter-value">Очікує</span></h2>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <div class="card-body" >
                            <div class="d-flex justify-content-between text-center" >
                                <div >
                                    <h2 class="mt-4 text-info fw-semibold"><span class="counter-value">{{ testInfo.executed.done }}</span></h2>
                                    <h2 class="mt-4 text-info fw-semibold"><span class="counter-value">Виконано</span></h2>
                                    <p class="fw-medium text-danger fw-semibold fs-14 mb-0">{{ testInfo.executed.failed }} не успішно</p>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col class="mt-3">
                        <div class="progress animated-progress">
                            <div class="progress-bar bg-info" role="progressbar" :style="`width: ${testInfo.progressBar}%`" :aria-valuenow="testInfo.progressBar" aria-valuemin="0" aria-valuemax="100"><span class="fs-12 fw-semibold">{{ testInfo.progressBar }}%</span></div>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>

        <!-- Тікет (якщо є) -->
        <div class="card" v-if="form.fromTicket.ticketId != ''">
            <div class="card-header align-items-center d-flex border-bottom-dashed" >
                <h4 class="card-title mb-0 flex-grow-1">Тікет</h4>
            </div>
            <div class="card-body" >
                <ul class="list-unstyled vstack gap-3 mb-0">
                    <li class="click">
                        <div class="d-flex align-items-center" >
                            <div class=" flex-shrink-0 me-3" >
                                <span class="fs-14 badge bg-info">#{{ form.fromTicket.ticketId }}</span>
                            </div>
                            <div class="flex-grow-1" >
                                <h5 class="fs-14 mb-0">{{ form.fromTicket.title }}</h5>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Учасники тікету -->
        <div class="card" >
            <div class="card-header align-items-center d-flex border-bottom-dashed" >
                <h4 class="card-title mb-0 flex-grow-1">Учасники</h4>
            </div>

            <div class="card-body" >

                <ul class="list-unstyled vstack gap-3 mb-0">

                    <template v-for="item in form.testingWorker" :key="item">

                        <li class="click">
                            <div class="d-flex align-items-center" >
                                <div class="avatar-xs flex-shrink-0 me-3" >
                                    <div class="avatar-title bg-success rounded-circle" >
                                        БГ
                                    </div>
                                </div>
                                <div class="flex-grow-1" >
                                    <h5 class="fs-13 mb-0">Богдан Гніденко testing</h5>
                                </div>
                                <div class="flex-shrink-0" >
                                    <div class="d-flex align-items-center gap-1" >
                                        <div class="dropdown" >
                                            <button class="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="ri-more-fill"></i>
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li>
                                                    <a class="dropdown-item">
                                                        <i class="ri-eye-fill text-muted me-2 align-bottom"></i>
                                                        Відкрити профіль
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                    </template>

                </ul>
            </div>
        </div>

        <!-- Файли тікету -->
        <div class="card" >
            <div class="card-header align-items-center d-flex border-bottom-dashed" >
                <h4 class="card-title mb-0 flex-grow-1">Файли</h4>
            </div>

            <div class="card-body" >

                <template v-for="item in form.attachments" :key="item">
                    <div class="vstack gap-2" >
                        <div class="border rounded border-dashed p-2" >
                            <div class="d-flex align-items-center" >
                                <div class="flex-shrink-0 me-3" >
                                    <div class="avatar-sm" >
                                        <div class="avatar-title bg-light text-secondary rounded fs-24" >
                                            <i class="ri-folder-zip-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-grow-1 overflow-hidden" >
                                    <h5 class="fs-13 mb-1"><a href="#" class="text-body text-truncate d-block">{{ item.filename }}</a></h5>
                                    <div >{{ item.size }}MB</div>
                                </div>
                                <div class="flex-shrink-0 ms-2" >
                                    <div class="d-flex gap-1" >
                                        <button type="button" class="btn btn-icon text-muted btn-sm fs-18"><i class="ri-download-2-line"></i></button>
                                        <div class="dropdown" >
                                            <button class="btn btn-icon text-muted btn-sm fs-18 dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="ri-more-fill"></i>
                                            </button>
                                            <ul class="dropdown-menu">
                                                <!-- <li><a class="dropdown-item" href="#"><i class="ri-pencil-fill align-bottom me-2 text-muted"></i> Перейменувати</a></li> -->
                                                <li><a class="dropdown-item" href="#"><i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Видалити</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </template>
            </div>
            
        </div>

    </div>
    
</template>

<script>

export default {
    props: ['objCard'],
    components: {

    },
    data() {
        return {
            form: '',
            testInfo: {
                waitItem: '0',
                executed: {
                    done: '',
                    failed: ''
                },
                progressBar: ''
            }
        }
    },
    created(){
        this.getdata();
        this.checkTestInfo()
    },
    methods: {
        getdata(){
            this.form = this.objCard
        },
        checkTestInfo(){
            console.log("test", this.form);
            
            if(this.form.checklist && this.form.checklist.length > 0) {
                // Підрахунок кількості виконаних пунктів
                this.testInfo.executed.done = this.form.checklist.filter(item => item.status != 'not_run').length

                // Підрахунок кількості пунктів зі статусом "failed"
                this.testInfo.executed.failed = this.form.checklist.filter(item => item.status == 'failed').length

                // Підрахунок кількості пунктів, які ще не виконані ("not_run")
                this.testInfo.waitItem = this.form.checklist.filter(item => item.status == 'not_run').length

                // Загальна кількість елементів
                const totalItems = this.form.checklist.length;

                // Прогрес бар: частка виконаних елементів (у відсотках)
                this.testInfo.progressBar = Math.round((this.testInfo.executed.done / totalItems) * 100);
            }

        }
    }
}
</script>
