<template>
    <!-- модальное окно просмотра заявки -->
    <modal :key="componentKey" :ifPrevopen="ifPrevopen" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen" @close="closeModal" :styleAdd="'backdrop-filter: blur(0px) !important;'">
        <template v-slot:title>Документ #{{ this.check.reportId }}</template>
        <template v-slot:status>

        </template>
        <template v-slot:head-button>
            
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="12">
                    <!-- Блок з не синхронізованими документами -->
                    <unSyncDocs :data="this.form" @getReport="getReport"/>
                </b-col>
                <b-col lg="12">
                    <!-- Блок з не синхронізованими документами -->
                    <syncDocsAfterReport :data="this.form.unsyncDocuments"/>
                </b-col>
                <b-col lg="12">
                    <!-- Блок з синхронізованими документами -->
                    <syncDocs :data="this.form.alreadySyncDocs"/>
                </b-col>
                <b-col lg="12">
                    <!-- Блок з інформацією -->
                    <info :data="this.form"/>
                </b-col>
            </b-row>
                
        </template>
        <template v-slot:footer-bottom>
            
        </template>
    </modal>

</template>

<script>
import { storeS } from "@/store.js" 
import modal from '@/components/modal/modal-lg'
import syncDocs from "./blocks/syncDocs.vue";
import unSyncDocs from "./blocks/unSyncDocs.vue";
import info from "./blocks/info.vue";
import syncDocsAfterReport from "./blocks/syncAfterReport.vue";
import { Documents } from "@/API_Dash.js" // API

let apiDocs = new Documents()

export default ({
    data(){
        return {
            form: {},
            componentKey: 0
        }
    },
    props: ['check'],
    components: {
        modal,
        syncDocs,
        unSyncDocs,
        info,
        syncDocsAfterReport
    },
    created(){ 
        this.form = this.check
        console.log(this)
    },
    methods: {
        getReport(reportId) {
            apiDocs.getCheck(reportId).then(result => {
                if(result.status === 'done') {
                    this.form = result.data
                    this.componentKey++
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка отримання чеку'; 
                    var statusRequest = "error";
                    var api = 'getCheck';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/activity/checks/viewCheck.vue';
                    var params = reportId
                    var response = result.status

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
    },
    mounted() {

    },
    computed:{
        perms() {
            return storeS.perms
        },
        user() {
            return storeS.userbase
        },
        workers(){
            return storeS.workers //база работников
        },
        scale(){
            return window.devicePixelRatio
        }
    }
})
</script>


<style scoped>
.copybtn {
    cursor: pointer;
    margin-top: -3px;
    margin-left: 5px;
    opacity: 0;
    transition: opacity .3s;
}
.c_block:hover .copybtn {
    opacity: 1;
    transition: opacity .3s;
}

.copybtn:checked {
    width: 500px;
}
</style>
