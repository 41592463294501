<template>
    <modallg @close="this.$emit('close')">
        <template v-slot:title>{{ $t('report') }} #{{ form.reportId }}</template>
        <template v-slot:body>
            <b-row>
                <b-col>
                    <b-card>
                        <b-card-title class="border-bottom pb-1 mb-2">
                            <b>{{ $t('Point_name') }}</b>
                        </b-card-title>
                        <h5>- {{ form.reportName }}</h5>
                    </b-card>
                    <b-card>
                        <b-card-title class="mb-1">
                            <b>{{ $t('desc') }}</b>
                        </b-card-title>
                        <h5>-{{ form.description }}</h5>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card>
                        <b-card-title class="mb-1">
                            <b>{{ $t('actualResult') }}</b>
                        </b-card-title>
                        <h5>-{{ form.actualResult }}</h5>
                    </b-card>
                    <b-card>
                        <b-card-title class="mb-1">
                            <b>{{ $t('expectedResult') }}</b>
                        </b-card-title>
                        <h5>-{{ form.expectedResult }}</h5>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-card>
                        <b-card-title class="mb-1">
                            <b>{{ $t('howtoProduce') }}</b>
                        </b-card-title>
                        <div class="ql-container">
                            <div class="ql-editor" v-html="form.howProduce">
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6">
                    <files 
                        :obj="this.obj" 
                        :objFiles="this.obj.files" 
                        @uploadFiles="uploadFiles"
                        @downloadFile="downloadFile"
                        @deleteFile="deleteFile"
                        @getFile="getFile"
                    />
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
            <div>
                <button 
                    type="button" 
                    class="btn btn-danger" 
                    @click="remove(form)"
                >
                    {{ $t('Delete') }}
                </button>
            </div>
        </template>
    </modallg>

    <modalImg v-if="showImg" @close="showImg = false">
        <template v-slot:body>
            <template v-if="gFile.type == 'image/jpeg' || gFile.type == 'image/png' || gFile.type == 'image/heic'">
                <img  :src="`${gFile.src}`" style="">
            </template>
            <template v-if="gFile.type == 'video/quicktime' || gFile.type == 'video/mp4'">
                <video controls>
                    <source :src="`${gFile.src}`" type="video/mp4">
                </video>
            </template>
        </template>
        <template v-slot:buttons>
            <button class="gdownload gbtn" @click="this.downloadFile(gFile)" ><i class="ri-download-cloud-2-fill"></i></button>
            <button class="gdelete gbtn" @click="showRemDialog(gFile)" ><i class="ri-delete-bin-fill"></i></button>
            <button :class="`gprev gbtn ${ifPrevimg == true ? '' : 'disabled'}`" @click="prevOpen(gFile)" aria-label="Previous" data-taborder="2"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"><g><path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"></path></g></svg></button>
            <button :class="`gnext gbtn ${ifNextimg == true ? '' : 'disabled'}`" @click="nextOpen(gFile)" aria-label="Next" data-taborder="1"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"> <g><path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z"></path></g></svg></button>
        </template>
    </modalImg>

    <modalremove v-if="dialogShow" @close="dialogShow = false" @remove="this.deleteFile(gFile)" />

</template>

<script>
import modallg from '@/components/modal/modal-lg'
import modalImg from '@/components/modal/img'
import files from '@/components/files/index'
import { ReportsTickets } from '@/API'
import { storeS } from '@/store.js'

let apiServe = new ReportsTickets();

export default {
    props:['obj'],
    components: { 
        modallg, 
        modalImg,
        files 
    },
    watch: {
        // Слідкуємо за змінами в інсексі і кидаємо його в стор
        tabIndex (newIdx) {
            storeS.tabIndex = newIdx
        }
    },
    data() {
        return {
            form:{
                files: []
            },
            gFile: {
                fileId: '',
                src: '',
                type: '',
                name: ''
            },
            showImg: false,
            dialogShow: false,
            ifPrevimg: false,
            ifNextimg: false,
            tabIndex: 0,
        };
    },
    created(){
        this.form = this.obj

        this.tabIndex = 6 // редагуємо таб, щоб можна було завантажити файл
    },
    methods: {
        getFiles(){
            apiServe.getFiles(this.obj.reportId).then(result => {
                if(result.status == 'done'){
                    this.form.files = result.data
                }
            })
        },
        uploadFiles(obj, formData){
            apiServe.uploadFiles(obj.reportId, formData).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('FileUploadedSuccessfully'));
                    storeS.uploadPercentage = 0;
                    this.getFiles();
                }
            })
        },
        downloadFile(item){
            apiServe.getFile(item.fileId, item.type)
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', item.name);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => console.log(error));
        },
        prevOpen(e){
            var current_id = e.fileId;
            var prev_id = ""; 

            for(var value in this.form.files){
                if(this.form.files[value].fileId == current_id){
                    prev_id = this.form.files[Number(value)-Number('1')];
                }
            }
            this.getFile(prev_id);
        },
        nextOpen(e){
            var current_id = e.fileId;
            var next_id = "";
            for(var value in this.form.files){
                if(this.form.files[value].fileId == current_id){
                    next_id = this.form.files[Number(value)+Number('1')];
                }
            }
            this.getFile(next_id);
        },
        getFile(item){
            var id = item.fileId ? item.fileId : item;
            this.ifPrevimg = id == this.form.files[0].fileId ? false : true;
            this.ifNextimg = id == this.form.files[this.form.files.length-1].fileId ? false : true;

            apiServe.getFile(id, item.type)
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    this.gFile.fileId = id;
                    this.gFile.src = url;
                    this.gFile.type = item.type;
                    this.gFile.name = item.name
                    this.showImg = true;
                    storeS.uploadPercentage = 0;
                })
        },
        deleteFile(e){
            apiServe.deleteFile(e.fileId).then(result => {
                if(result.status == 'done'){
                    this.showImg = false
                    this.dialogShow = false
                    this.$toast.success(this.$t('FileDeleted'));
                    this.getFiles();
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);
                }
            })
        },
        showRemDialog(e){
            this.gFile.fileId = e.fileId
            this.dialogShow = true;
        },
        remove(e){
            apiServe.deleteReport(e.reportId).then(result => {
                if(result.status == 'done'){
                    this.$emit('close')
                    this.$toast.success(this.$t('Removed'));
                    this.eventBus.emit('getReports', true)
                } else {
                    this.$toast.error(this.$t('error'));
                }
            })
        }
    },
    computed: {
        user() {
            return storeS.userbase
        },
        uploadPercentage(){
            return storeS.uploadPercentage
        }
    }
}
</script>