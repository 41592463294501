<template>
    <modal @close="this.$emit('close')">
        <template v-slot:title>{{ this.perm === true ? this.$t('Edit') : this.$t('Add') }}</template>
        <template v-slot:status></template>
        <template v-slot:head-bottom>
            <!-- Кнопка закриття вікна, "x" -->
            <button 
                type="button" 
                class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  
                @click="this.$emit('close')" 
                style="margin-right: 5px;"
            >
                <i class="ri-close-fill align-bottom"></i>
            </button>
        </template>
        <template v-slot:body>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="mb-3">
                            <label v-if="this.perm === false" for="exampleFormControlInput1" class="form-label">{{ this.$t('phone_num') }}</label>
                            <input v-if="this.perm === false" type="text" class="form-control" v-model="this.form.number" id="exampleFormControlInput1" placeholder="+380960000000">
                            <div class="form-check" style="margin-top: 8px" v-if="this.perm === false">
                                <input class="form-check-input" type="checkbox" v-model="this.selectorStatus" @change="this.chooseComp()" id="flexCheckIndeterminate">
                                <label class="form-check-label" for="flexCheckIndeterminate">
                                    {{ this.$t('ChooseCompany') }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" v-show="showAddComp === true">
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">{{ this.$t('company') }}</label>
                            <input type="text" v-model="this.form.companyId" class="form-control" id="exampleFormControlInput1">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('choose_a_priority') }}</h4>
                                <Multiselect 
                                    v-model="this.form.priority"
                                    :close-on-select="true"
                                    :searchable="true"
                                    :create-option="false"
                                    :options="this.listPriority"
                                    :placeholder="$t('priority')"
                                />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('type') }}</h4>
                                <Multiselect 
                                    v-model="this.form.type"
                                    :close-on-select="true"
                                    :searchable="true"
                                    :create-option="false"
                                    :options="this.listTypes"
                                    :placeholder="$t('type')"
                                />
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">{{ this.$t('Cause') }}</label>
                            <textarea class="form-control" v-model="this.form.reason" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div v-if="this.perm === false">
                <!-- <button class="btn link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button> -->
                <button type="button" class="btn btn-success" v-on:click="add">{{ $t('Add') }}</button>
            </div>
            <div v-else>
                <!-- <button class="btn link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button> -->
                <button type="button" class="btn btn-success" v-on:click="edit">{{ $t('Edit') }}</button>
            </div>
        </template>
    </modal>

    <!-- 
        Модалка "Вибір компанії"
            @close - дія закриття після створення 
            @choseComp - дія, після створення компанії
            :numbers - пропс номерів клієнтів
    -->

    <enterCompany 
        v-if="showEnterComp === true" 
        @close="showEnterComp = false; this.selectorStatus = false" 
        @choseComp="addComp"
        :number="this.form.number" 
    >
    </enterCompany>
</template>

<script>
import enterCompany from '@/views/newtickets/view/actions/enterCompany.vue';
import modal from '@/components/modal-small'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { mutatePhone } from '@/usabilityScripts/globalMutate';
import {Monitoring} from '@/API'
import {mutateMonitoringType} from '@/usabilityScripts/globalMutate'


let apiServe = new Monitoring()

export default {
    data() {
        return {
            showAddComp: false,
            form: {
                number: '',
                companyId: '',
                reason: '',
                priority: 0,
                type: ''
            },
            listPriority: [ //тип приоритета
                {
                    "label": this.$t('priorityHigh'),
                    "value": "2"
                },
                {
                    "label": this.$t('priorityMedium'),
                    "value": "1"
                },
                {
                    "label": this.$t('priorityLow'),
                    "value": "0"
                }
            ],
            listTypes: [
                {
                    "label": this.$t('trouble_client'),
                    "value": "problems"
                },
                {
                    "label": this.$t('Payment'),
                    "value": "payments"
                },
                {
                    "label": this.$t('desagree_from_program'),
                    "value": "desagree"
                },
                {
                    "label": this.$t('Other'),
                    "value": "another"
                }
            ],
            showEnterComp: false,
            selectorStatus: false,
        }
    },
    props: ['perm', 'obj'],
    components: {
        modal,
        Multiselect,
        enterCompany
    },
    created() {
        // Вставляємо необхідні дані, якщо перейшли з картки клієнта
        if(this.obj.number && this.perm === false) {
            this.form.number = this.obj.number 
        }
        if(this.obj.compId && this.perm === false) {
            this.form.companyId = this.obj.compId
            this.showAddComp = true // показуємо поле з id компанії
            this.selectorStatus = true
        }
        // Вставляємо необхідні дані, якщо перейшли з картки клієнта

        // Якщо редагування
        if(this.perm === true) {
            this.form = JSON.parse(JSON.stringify(this.obj))
            this.form.type = this.obj.monitoring_type
        }
        // Якщо редагування
    },
    methods: {
        mutateType(e) {
            return mutateMonitoringType(e)
        },
        add() {
            apiServe.addToMonitoring(this.form).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('done'))
                    this.$emit('getdata')
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error'))


                    var errorText = 'Не вийшло створити моніторинг'; 
                    var statusRequest = result.status;
                    var api = 'addToMonitoring';
                    var fileRequest = 'src/views/monitoring/create.vue';
                    var params = this.form;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        edit() {
            console.log(this.obj.type, this.form.type, this.obj)
            this.form["from_type"] = this.mutateType(this.obj.monitoring_type).name
            this.form["to_type"] =  this.mutateType(this.form.type).name
            
            apiServe.editMonitoring(this.form, this.obj.monitoringId).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('done'))
                    this.$emit('getdata')
                    this.$emit('close')
                    this.eventBus.emit('updateMonitoringCard', {})
                } else {
                    this.$toast.error(this.$t('error'))


                    var errorText = 'Не вийшло змінити моніторинг'; 
                    var statusRequest = result.status;
                    var api = 'editMonitoring';
                    var fileRequest = 'src/views/monitoring/create.vue';
                    var params = `{id: ${this.obj.monitoringId}, form: ${this.form} }`;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        chooseComp() {
            if(this.selectorStatus === true) {
                this.form.number = this.phoneReset(Number(this.form.number))
                this.showEnterComp = true
            }
        },
        // додавання компанії
        addComp(e) {
            if(e.companyName != 'none') {
                this.$toast.success(this.$t('company') + ` ${e.companyName}`)
                this.form.companyId = e.companyId // в масив номерів кидаємо компанію з номером, або просто номер
                this.showAddComp = true // показуємо поле з id компанії
            }
            this.showEnterComp = false // закриваємо вікно вибору
        },
        // подія модифікації номера для апі
        phoneReset(e) {
            return mutatePhone(e)
        },
    }
}
</script>