<template v-touch:swipe="swipeHandler">
    <!-- trolling for Mykyta -->    
    <!-- <div style="position: absolute; z-index: 1; left: 20%;" v-if="this.troll == true && this.checks['024'] == true && this.user.userid == true">
        <figure>
            <img src="https://pbs.twimg.com/media/F-ca7FkXUAA1Eck?format=jpg&name=small">
            <figcaption @click="">{{this.$t('close')}}</figcaption>
        </figure>
    </div> -->
    <!-- trolling for Mykyta -->
    <preloader v-if="preloader" />
    <preloaderTable v-if="preloaderTable" />
    <alertUpdate v-if="alertU" :reason="this.reason" @close="this.alertU"/>
    <div id="app" v-touch:swipe="swipeHandler">
        <Leftmenu v-if="ifMenu" @changeSize="changeSize"/>
        <div 
            class="main-content" 
            :class="this.changeSizeContent === true ? '' : 'adaptive-burger'" 
            :style="this.changeSizeContent === true ? 'margin-left: 0px' : (this.fullMenu !== true ? '' : 'margin-left: 250px')">
            <!-- <button style="font-size: 150px;" @click="this.onMusic('all');">check aud</button> -->
            <div class="page-content">
                <!-- Start Content-->
                <!-- <b class="text-black fs-16"><connectSIP /></b> -->
                <div class="container-fluid">
                    <RouterView :key="keyForReload"/>
                </div>
            </div>
            <!-- <footerblock /> -->
        </div>
    </div>

    <!-- карточка заявки -->
    <viewRequest 
      v-for="items, i in showModalRequest"
      :key="i"
      @close="closeModal('request')" 
      :shortapplic="items"
      :closeOne="closeOnePerm"
      :client = 'this.client'
    >
    </viewRequest>

    <!-- карточка заявки -->
    <viewTicket
      v-if="showModalTicket"
      @close="closeModal('ticket')" 
      :activeTab="activeTab"
      :obj="objCard"
      :closeOne="closeOnePerm"
      :client = 'this.client'
    >
    </viewTicket>

    <!-- карточка презентації -->
    <viewPresentation 
      v-if="showModalPresent"
      @close="closeModal('present')" 
      :shortapplic="objCard"
      :closeOne="closeOnePerm"
      :client = 'this.client'
    >
    </viewPresentation>

    <!-- карточка завдання -->
    <viewTask 
      v-if="showModalTask"
      @close="closeModal('task')" 
      :shortapplic="objCard"
      :closeOne="closeOnePerm"
      :client = 'this.client'
    >
    </viewTask>

    <!-- карточка відвалу -->
    <viewDump 
        v-for="items, i in showModalDump"
        :key="i"
        :shortapplic="this.showModalDump[i]"
        :closeOne="closeOnePerm"
        :client = 'this.client'
        @close="this.showModalDump.pop()" 
    >
    </viewDump>
    <!-- карточка відвалу -->

    <!-- картка холодного дзвінку -->
    <coldRequest
        v-for="items, i in showModalColdRequest"
        :key="i"
        :shortapplic="this.showModalColdRequest[i]"
        :closeOne="closeOnePerm"
        :client = 'this.client'
        @close="this.showModalColdRequest.pop()" 
    ></coldRequest>
    <!-- картка холодного дзвінку -->

    <!-- картка тріггера дзвінку -->
    <triggerRequest
        v-for="items, i in showModalTrigger"
        :key="i"
        :shortapplic="this.showModalTrigger[i]"
        :closeOne="closeOnePerm"
        :client = 'this.client'
        @close="this.showModalTrigger.pop()" 
    ></triggerRequest>
    <!-- картка тріггера дзвінку -->

    <!-- модалка карточки клиента -->
    <viewRegist 
        v-for="items, i in testshowModalclient" 
        :key="i"
        :companyId = "this.searchedComp"
        :requestId="this.specialId[this.specialId.length - 1]"
        :obj="this.objcard"
        :objcard="this.testshowModalclient[i]"
        :form_applic="this.shortapplic"
        :parrent = "this.clientParrent"
        @call = "call"
        @close="testshowModalclient.pop()" 
    ></viewRegist>
    <!-- модалка карточки клиента -->
    
    <!-- модалка незарегистрированного клиента -->
    <viewUnregBox 
        v-for="items, i in showModalUnUser" 
        :key="i"
        :requestId="this.specialId[this.specialId.length - 1]"
        @close="showModalUnUser.pop()" 
        :objCard="this.showModalUnUser[i]" 
        @call = "call"
    ></viewUnregBox>
    <!-- модалка незарегистрированного клиента -->

    <!-- для незавершенних форм -->
    <dialogChooseVue
        v-if = "this.haveNotFinish == true"
        :notFinish = "this.notFinish"
        @close = "this.haveNotFinish = false"
        @openOld = "openOld"
        @createNew = "createNew"
    ></dialogChooseVue> 
    <!-- для незавершенних форм -->

    <createTimeModal
        v-if="this.createTime === true"
        @close="this.createTime = false"
    >

    </createTimeModal>
    
    <!-- модальне вікно посту -->
    <post
        :obj="this.item"
        v-if="showPost == true"
        @close="showPost = false"
    >

    </post>

    <!-- модальне вікно уроку -->
    <lesson
        v-if="showLesson == true"
        @close="showLesson = false"
        :obj="this.item"
    > </lesson>

    <dialogModal v-if="showCompClient" @close="showCompClient = false;" >
        <template v-slot:title>{{ $t('CompanyFound') }}</template>
        <template v-slot:body>
            <div class="mt-4 text-center">
              <h3><b>{{ $t('warningDescSearchByCompid') }}</b></h3>
              <p class="text-muted fs-15 mb-4">{{ $t('CompanyFound') }} - {{ searchComp.companyTitle }}, {{ $t('byNumber') }} - {{ searchComp.number }}. 
                <br />{{ $t('actionOpenCardbtn') }}</p>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <button class="btn btn-danger" @click="showCompClient = false">{{ $t('close') }}</button>
                <button class="btn btn-success" @click="this.getClient(this.searchComp.number)">{{ $t('open') }}</button>
            </div>
        </template>
    </dialogModal>

    <enterCompany v-if="showEnterComp === true" :number="this.actualNumber" @close="closeWithCheck" @choseComp="addComp"></enterCompany>

    <allCards />

    <!-- Додати нотатку в ATS -->
    <addNote
        v-if="this.showAddNote === true"
        @close="this.showAddNote = false"
    ></addNote>
    <!-- Додати нотатку в ATS -->

    <!-- Передивитися нотатки в ATS -->
    <showNotes
        v-if="this.showViewNotes === true"
        :notes="this.atsNotes"
        @close="this.showViewNotes = false"
    ></showNotes>
    <!-- Передивитися нотатки в ATS -->

    <!-- Поставити на моніторинг -->
    <monitoringCreate
        v-if = "showModalCreateMonitoring === true"
        @close = "showModalCreateMonitoring = false"
        :obj = "this.objCard"
        :perm = "false"
    ></monitoringCreate>
    <!-- Поставити на моніторинг -->

    <!-- Переглянути моніторинг -->
    <viewMonitoring
        v-if = "showModalMonitoring === true"
        @close = "showModalMonitoring = false"
        :obj = "this.objCard"
        :blockEdit="true"
    ></viewMonitoring>
    <!-- Переглянути моніторинг -->

    <!-- Обрізати зображення -->
    <cropImage 
        v-if="this.showCropImageWindow === true"
        :img="this.imgOnCrop"
        @close="this.showCropImageWindow = false"
    />
</template>

<script>
import cropImage from "@/components/cropImage"
import viewMonitoring from "@/views/monitoring/view/index.vue"
import monitoringCreate from "@/views/monitoring/create.vue"
import triggerRequest from '@/views/calls/trigger/view/index.vue'
import coldRequest from '@/views/calls/cold/view/viewapplic.vue'
import enterCompany from '@/views/newtickets/view/actions/enterCompany.vue';
import dialogModal from '@/components/modal/dialog'
import post from '@/components/faq/view/index.vue'
import lesson from '@/components/knowledge-base/view/index.vue'
import createTimeModal from '@/views/calendar/createCustomTime'
import viewTicket from '@/views/newtickets/view/index'
import viewUnregBox from '@/views/accounts/unregistered/view/index'
import viewRegist from '@/views/accounts/allAccounts/viewnew/index'
import preloader from "@/components/preloader";
import preloaderTable from '@/components/globaltable/preloader.vue'
import { storeS } from '@/store'
import Leftmenu from '@/components/dashboard/leftmenu/index'
import router from './router'
import footerblock from '@/components/footer'
// import connectSIP from './components/dashboard/sip/ConnectionState'
import sound from '@/assets/music/file.mp3'
import new_message_voice from '@/assets/music/new_message_voice.mp3'
import new_messages_girl_voice from '@/assets/music/new_messages_girl_voice.mp3'
import new_message from '@/assets/music/new_message.mp3'
import viewRequest from '@/views/application/view/viewapplic'
import viewPresentation from '@/views/presentations/view/index'
import viewTask from '@/views/tasks/view/viewtask'
import viewDump from '@/views/dumps/view/index.vue'
import alertUpdate from '@/components/dashboard/alerts/update'
import { Applications, Presentations, Tasks, Dumps, OtherCalls, SuperAdmin, Tickets, KW, FAQ, ColdCalls } from '@/API.js';
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import dialogChooseVue from './components/globaltable/actions/dialogChoose.vue'
import { storeSip } from '@/telephony/telephony.js'
import allCards from '@/components/dashboard/allCards'
import addNote from '@/components/voip/addNote.vue'
import showNotes from '@/components/voip/showNotes.vue'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Applications();
let apiPresentations = new Presentations();
let apiTasks = new Tasks();
let apiDumps = new Dumps();
// let apiOtherCalls = new OtherCalls();
let apiAdmin = new SuperAdmin()
let apiTickets = new Tickets();
let apiKW = new KW()
let apiFAQ = new FAQ();
let apiCold = new ColdCalls()

export default {
    data(){
        return{
            showCropImageWindow: false,
            imgOnCrop: {},
            showModalMonitoring: false,
            changeSizeContent: false,
            atsNotes: [],
            showViewNotes: false,
            showAddNote: false,
            showModalColdRequest: [],
            showEnterComp: false,
            showPost: false,
            showLesson: false,
            item: '',
            createTime: false,
            haveNotFinish: false,
            notFinish: [],
            showModalTicket: false,
            objcard: {},
            showModalUnUser: [],
            objClient: '',
            testshowModalclient: [],
            statusMenu: "",
            show_in_preloader: false,
            showModalRequest: [],
            showModalPresent: false,
            showModalTask: false,
            showModalDump: [],
            closeOnePerm: '',
            client: '',
            clientParrent: '',
            statusMute: '1', // 0 - вимкнути звук
            reason: '',
            specialId: [],
            showCompClient: false,
            searchedComp: '',
            searchComp: {companyTitle: '', number: ''},
            actualNumber: '',
            showModalTrigger: [],
            activeTab: 'main',
            showModalCreateMonitoring: false,
        }
    },
    components: {
        cropImage,
        viewMonitoring,
        monitoringCreate,
        showNotes,
        addNote,
        triggerRequest,
        dialogModal,
        dialogChooseVue,
        viewTicket,
        viewUnregBox,
        viewRegist, 
        Leftmenu, 
        preloader, 
        preloaderTable, 
        footerblock,
        viewRequest,
        viewPresentation,
        viewTask,
        viewDump,
        alertUpdate,
        createTimeModal,
        post,
        lesson,
        enterCompany,
        coldRequest,
        allCards
        // connectSIP 
    },
    created(){

        console.log('fullmenu', this.fullMenu, this.changeSizeContent)

        if(this.user.countMessages > 0){
            this.$toast.warning(this.$t('YouhaveUnreadMessages'))
        }

        // Слухаємо зміни URL-адреси
        window.addEventListener('popstate', this.handleUrlChange)

        // Опрацьовуємо URL при першому завантаженні
        this.handleUrlChange()

        // Подія, що відкриває модалку обрізання зображення
        this.eventBus.off('cropImage')
        this.eventBus.on('cropImage', (file) => {
            this.imgOnCrop = file.img
            this.showCropImageWindow = true
        })

        this.eventB.off('commentInRequest')
        this.eventB.on('commentInRequest', (status) => {
            if(status.status == true) {
                if(
                    status.type == 'call' || 
                    status.type == "request" || 
                    status.call == 'incoming' || 
                    // Перевірка нижче обходить відкриття заявки без дозволу після створення але дозволяє її відкрити після дзвінку
                    (
                        status.action == "addNewRequest" && 
                        !status.type && 
                        status.call
                    )
                ) {
                    apiServe.getRequest(status.requestId).then(result => {
                        if(result.status == 'done') {
                            this.eventB.emit('getCom', {status: true})
                            this.showModalRequest.push(result.data)
                        }
                    })
                } else if(status.type == 'dump') {
                    apiDumps.getDump(status.requestId).then(result => {
                        if(result.status == 'done') {
                            this.eventB.emit('getCom', {status: true})
                            this.showModalDump.push(result.data)
                        }
                    })
                } else if(status.type == 'coldCall') {
                    apiCold.getCall(status.requestId).then(result => {
                        if(result.status == 'done') {
                            this.eventB.emit('getCom', {status: true})
                            this.showModalColdRequest.push(result.data)
                        }
                    })
                }
            }
        })

        // Якщо є запит, що зафакапився, то ми його кидаємо ще раз
        if(localStorage.getItem('toDoFirst')) {
            let obj = JSON.parse(localStorage.getItem('toDoFirst'))

            const Http = new XMLHttpRequest();
            const url= obj.api;
            Http.open(obj.method, url);
            Http.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
            Http.send(obj.data);

            Http.onreadystatechange = (e) => {
                localStorage.removeItem('toDoFirst')
            }
        }
        // Якщо є запит, що зафакапився, то ми його кидаємо ще раз

        this.clearStore();
        // localStorage.setItem('nightTheme', 'light')
        if(this.checks['022'] == true && this.checks['023'] == true){   
            let time = new Date().getHours();
            if(time >= 7 && time <= 18){
                localStorage.setItem('nightTheme', 'light')
            } else {
                localStorage.setItem('nightTheme', 'dark')
            }
        }

        // Активація всіх можливостей
        if(localStorage.getItem('fullAccess')){
            if(localStorage.getItem('fullAccess') == 'true'){
                this.perms[666] = true;
            } else {
                this.perms[666] = false;
            }
        } else {
            localStorage.setItem('fullAccess', false)
            this.perms[666] = false
        }

        this.eventB.off('changeStatusCall')
        this.eventB.on('changeStatusCall', status => {
            if(location.pathname == '/calls/all/') {
                let obj = {
                    requestIds: status.requestId
                }
                apiServe.packCallNow(obj).then(result=> {
                    if(result.status == 'done') {
                        this.checkPage('requests');
                    }
                })
            }
        })

        this.eventBus.on('changeStatusForAlerts', status => {
            if(status.busy = true) {
                this.statusCall = 'busy'
            } else {
                this.statusCall = 'free'
            }
        })

        this.eventBus.off('createNewMonitoring')
        this.eventBus.on('createNewMonitoring', status => {
            this.objCard = status.data
            this.showModalCreateMonitoring = true
        })

        document.documentElement.setAttribute("data-layout-mode", storeS.nightTheme );
        document.documentElement.setAttribute("data-layout-style", "default");
        document.documentElement.setAttribute("data-layout-position", "fixed");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
        document.documentElement.setAttribute("data-topbar", "light");
        document.documentElement.setAttribute("data-layout-width", "fluid");
        document.documentElement.setAttribute("data-layout", "vertical");
        document.documentElement.setAttribute("data-sidebar", "dark");


        this.eventB.off('newRequest');
        this.eventB.on('newRequest', (data) => {
            switch (data.call) {
                case 'new': // 
                    if(this.checks['110'] == true && this.user.workerJob == 'managercallcenter' && this.statusCall !== 'busy'){
                        this.onMusic('all');
                    }
                    this.$toast.success(this.$t('NewApplicationReceived'));
                    break;
                case 'missing':
                    if(this.checks['110'] == true && this.user.workerJob == 'managercallcenter' && this.statusCall !== 'busy'){
                        this.onMusic('all');
                        this.eventBus.emit('updateAlert', {
                            status: true,
                            type: 'request',
                            color: 'danger',
                            icon: 'bx bxs-phone-off',
                            text: this.$t('missingCall'),
                            dataAlert: data,
                            date: this.currentDate()
                        })
                    }
                    this.$toast.error(this.$t('missingCall'));
                break;
                case 'outgoing':
                    if(this.user.userid == data.toWorkerId){
                        apiServe.getRequest(data.requestId).then(result => {
                            if(result.status === 'done') {
                                this.objCard = result.data;
                                this.showModalRequest.push(this.objCard);
                            }
                        })
                    }
                break;
                case 'incoming':
                    if(this.user.userid == data.toWorkerId){
                        apiServe.getRequest(data.requestId).then(result => {
                            if(result.status === 'done') {
                                this.objCard = result.data;
                                this.showModalRequest.push(this.objCard);
                            }
                        })
                    }
                break;
            
                default:
                    break;
            }
            this.checkPage('requests');
        });

        this.eventB.off('createCustomCalendarTime')
        this.eventB.on('createCustomCalendarTime', (data) => {
            if(data.status == true) {
                this.createTime = true
            }
        })

        this.eventB.off('cancelRequest');
        this.eventB.on('cancelRequest', (data) => {         
            if(storeS.checkModal.type == 'request' && storeS.checkModal.id == data.requestId){
                this.eventBus.emit('updateRequest', true)
            } else {
                this.checkPage('requests');
            }
        });

        this.eventB.off('delayRequest');
        this.eventB.on('delayRequest', (data) => {
            // if(storeS.checkModal.type == 'request' && storeS.checkModal.id == data.requestId){
                this.eventBus.emit('updateRequestByCall', true)
                this.eventBus.emit('updateRequest', {status: true})
            // } else {
                // this.checkPage('requests');
            // }
        });

        this.eventB.off('addReportOnCancelRequest');
        this.eventB.on('addReportOnCancelRequest', (data) => {
            // this.checkPage('requests');
            if(storeS.checkModal.type == 'request' && storeS.checkModal.id == data.requestId){
                this.eventBus.emit('updateRequest', true)
            } else {
                this.checkPage('requests');
            }
        });

        this.eventB.off('fixationTransferRequest');
        this.eventB.on('fixationTransferRequest', (data) => {
            // this.checkPage('requests');
            if(storeS.checkModal.type == 'request' && storeS.checkModal.id == data.requestId){
                this.eventBus.emit('updateRequest', true)
            } else {
                this.checkPage('requests');
            }
        });

        this.eventB.off('transferRequest');
        this.eventB.on('transferRequest', (data) => {
            if(this.user.userid == data.toWorkerId){
                if(this.checks['110'] == true  && this.statusCall !== 'busy'){
                    this.onMusic('all');
                }
                // оповіщення про передачу заявки
                // this.eventBus.emit('updateAlert', {
                //     status: true,
                //     color: 'primary',
                //     type: 'request',
                //     icon: 'ri-phone-fill',
                //     text: this.nameWorker(data.fromWorkerId) + " " + this.$t('sentYouRequest'),
                //     dataAlert: data,
                //     date: this.currentDate()
                // })
                this.$toast.success(this.nameWorker(data.fromWorkerId) + " " + this.$t('sentYouRequest'));
            } else {
                this.checkPage('requests');
            }
            
            if(this.user.userid == data.toWorkerId){
                apiServe.getRequest(data.requestId).then(result => {
                    if(result.status === 'done') {
                        this.objCard = result.data;
                        this.showModalRequest.push(this.objCard);
                        storeS.checkModal.type = 'request';
                        storeS.checkModal.id = data.requestId;
                    }
                })
            }
        });

        this.eventB.off('authServerOff')
        this.eventB.on('authServerOff', (data) => {
            if(data.status === true){
                this.reason = 'authServerOff'
                this.alertU = true
            }
        })

        this.eventB.off('skyServerOff')
        this.eventB.on('skyServerOff', (data) => {
            if(data.status === true){
                this.reason = 'skyServerOff'
                this.alertU = true
            }
        })

        this.eventB.off('transferRequestByCall');
        this.eventB.on('transferRequestByCall', (data) => {
            if(this.user.userid == data.toWorkerId){
                if(this.checks['110']  && this.statusCall !== 'busy'){
                    this.onMusic('all');
                }
                this.$toast.success(this.nameWorker(data.fromWorkerId) + " " + this.$t('sentYouRequest'));
            } else {
                this.checkPage('requestbycall');
            }
            
            // if(this.user.userid == data.toWorkerId){
            //     apiOtherCalls.getRequest(data.requestId).then(result => {
            //         if(result.status === 'done') {
            //             this.objCard = result.data;
            //             this.showModalRequest = true;
            //             storeS.checkModal.type = 'requestbycall';
            //             storeS.checkModal.id = data.requestId;
            //         }
            //     })
            // }
        });

        this.eventB.off('addTaskWaitConfirm');
        this.eventB.on('addTaskWaitConfirm', (data) => {
            if(this.checks['140']  && this.statusCall !== 'busy'){
                this.onMusic('all');
            }
            // оповіщення про створення завдання
            // this.eventBus.emit('updateAlert', {
            //     status: true,
            //     type: 'task',
            //     color: 'info',
            //     icon: 'ri-honour-line',
            //     text: 'AddedNewTask',
            //     dataAlert: data,
            //     date: this.currentDate()
            // })
            this.$toast.success(this.$t('AddedNewTask'));
            this.checkPage('tasks');
        })
        
        this.eventB.off('addTaskInJob');
        this.eventB.on('addTaskInJob', (data) => {
            if(storeS.checkModal.type == 'task' && storeS.checkModal.id == data.taskId){
                this.eventBus.emit('updateTask', true)
            } else {
                this.checkPage('tasks');
            }
        })

        this.eventB.off('transferTask');
        this.eventB.on('transferTask', (data) => {
            if(this.user.userid == data.toWorkerId){
                if(this.checks['140']  && this.statusCall !== 'busy'){
                    this.onMusic('all');
                }
                // оповіщення про передачу завдання
                // this.eventBus.emit('updateAlert', {
                //     status: true,
                //     type: 'task',
                //     color: 'info',
                //     icon: 'ri-honour-line',
                //     text: this.nameWorker(data.fromWorkerId) + " " + this.$t('sentYouTask'),
                //     dataAlert: data,
                //     date: this.currentDate()
                // })
                this.$toast.success(this.nameWorker(data.fromWorkerId) + " " + this.$t('sentYouTask'));

                apiTasks.getTask(data.taskId).then(result => {
                    if(result.status === 'done') {
                        this.objCard = result.data;
                        this.showModalTask = true;
                        storeS.checkModal.type = 'task';
                        storeS.checkModal.id = data.taskId;
                    } else {
                        this.$toast.err(this.$t('error'))
                    }
                })
            }
        });

        this.eventB.off('confirmTask');
        this.eventB.on('confirmTask', (data) => {
            if(this.checks['140']  && this.statusCall !== 'busy'){
                this.onMusic('all');
                this.eventBus.emit('updateAlert', {
                    status: true,
                    type: 'task',
                    color: 'info',
                    icon: 'ri-honour-line',
                    text: 'magangerConfirmTaskAlert',
                    dataAlert: data,
                    date: this.currentDate()
                })
            }
            this.$toast.success(this.$t('magangerConfirmTaskAlert'));
            // this.checkPage('tasks');
            if(storeS.checkModal.type == 'task' && storeS.checkModal.id == data.taskId){
                this.eventBus.emit('updateTask', true)
            } else {
                this.checkPage('tasks');
            }
        })

        this.eventB.off('cancelTask');
        this.eventB.on('cancelTask', (data) => {
            if(this.checks['029']) {
                this.eventBus.emit('updateAlert', {
                    status: true,
                    type: 'task',
                    color: 'info',
                    icon: 'ri-honour-line',
                    text: 'TaskCanceled',
                    dataAlert: data,
                    date: this.currentDate()
                })
            }
            // this.$toast.success(this.$t('NewApplicationReceived'));
            // this.checkPage('tasks');
            if(storeS.checkModal.type == 'task' && storeS.checkModal.id == data.taskId){
                this.eventBus.emit('updateTask', true)
            } 
            // else {
            //     this.checkPage('tasks');
            // }
        });

        this.eventB.off('haveForms')
        this.eventB.on('haveForms', status => {
            if(status.status == true) {
                this.notFinish = status.forms
                this.haveNotFinish = true
            }
        })

        this.eventBus.off('modalInModal')
        this.eventBus.on('modalInModal', status => {
            if(status.status == true) {
                if(status.action) {
                    if(status.action == 'openReferal') {
                        this.eventBus.emit('getClient', {
                            status: true,
                            parent: status.client,
                            number: status.data.phone
                        })
                    }
                    return
                } else if(status.type){
                    if(status.type) {
                        switch (status.type) {
                            case 'trigger':
                                this.objCard = status.data
                                this.showModalTrigger.push(this.objCard)
                                this.client = status.formForReturnInClientCard
                            break;
                            case 'dump':
                                this.objCard = status.data
                                this.showModalDump.push(this.objCard)
                                this.client = status.formForReturnInClientCard
                            break;
                            case "request":
                                this.objCard = status.data
                                this.showModalRequest.push(this.objCard);
                                this.client = status.formForReturnInClientCard
                            break;
                            case "requestByCall":
                                this.objCard = status.data
                                this.showModalRequest.push(this.objCard);
                                this.client = status.formForReturnInClientCard
                            break;
                            case "coldRequest":
                                this.objCard = status.data
                                this.showModalColdRequest.push(this.objCard);
                                this.client = status.formForReturnInClientCard
                            break;
                            case "call":
                                this.objCard = status.data
                                this.showModalRequest.push(this.objCard);
                                this.client = status.formForReturnInClientCard
                            break;
                            case "task":
                                this.objCard = status.data
                                this.showModalTask = true
                                this.client = status?.formForReturnInClientCard
                            break;
                            case "presentation":
                                this.objCard = status.data
                                this.showModalPresent = true
                                this.client = status.formForReturnInClientCard
                            break;
                            case "ticket":
                                this.objCard = status.data
                                this.showModalTicket = true
                                this.client = status.formForReturnInClientCard
                            break;
                            case "monitoring":
                                this.objCard = status.data
                                this.showModalMonitoring = true
                                this.client = status.formForReturnInClientCard
                            break;
                        }
                    }
                } else if(status.openFromUser) {
                    switch(status.openFromUser) {
                        case "ticket":
                            this.objCard = status.data
                            this.showModalTicket = true
                        break;
                    }
                }
            }
        })

        // переглянути додані нотатки
        this.eventBus.off('showNotesWindow')
        this.eventBus.on('showNotesWindow', status => {
            this.atsNotes = status.data
            this.showViewNotes = true
        })
        // переглянути додані нотатки

        // додати нотатку по номеру в indexedDB
        this.eventBus.off('addNote')
        this.eventBus.on('addNote', status => {
            this.showAddNote = true
        })
        // додати нотатку по номеру в indexedDB

        window.addEventListener('click', () => {
            this.eventBus.emit('click', {
                status: true
            })
        })

        this.eventBus.off('enterCompanies')
        this.eventBus.on('enterCompanies', (status) => {
            this.actualNumber = status.number
            this.showEnterComp = true
        })

        this.eventBus.off('getClient');
        this.eventBus.on('getClient', (status) => {
            var numcode = ["039", "067", "068", "096", "097", "098", "050", "066", "095", "099", "063", "073", "093", "091", "092", "089", "094"] // прописємо коди операторів по Україні
            if(status.status == true){
                if(status.requestId) {
                    this.specialId.push(status.requestId) // якщо є айді заявки, то відкриваєио заявку
                } else {
                    this.specialId.push('') // просто пушимо пустий айді, щоб не збивати порядок відкритих заявок
                }
                if(status.parent) {
                    this.clientParrent = status.parent // для реалізації модалки в модалці
                }
                let res = status.number;
                if(Number(status.number) != status.number) { // якщо на місці номера айді компанії, то дістаємо картку по компанії               
                    if(status.number.indexOf('-') == -1) { // яккщо компанії були вказані без дефісів, то вказуємо їх
                        res = ''
                        let str = status.number.split('')
                        for(let i = 0; i < str.length; i++) {
                            res += str[i]
                            if(i === 7) {
                                res += '-'
                            }
                            if(i === 11 || i === 15 || i === 19) {
                                res += '-'
                            }
                        }
                    }

                    apiAdmin.getListCompanyBycompanyId(res).then(result => {
                        if(result.status == "done"){
                            this.searchComp.number = result.data.items[0].ownerPhone
                            this.searchComp.companyTitle = result.data.items[0].companyName
                            this.showCompClient = true
                        } 
                    })
                } else {
                    numcode.forEach(el => {
                        if(status.number.substr(0, 3) == el ) {
                            status.number = "38" + status.number // якщо номер почався з коду оператора
                        }
                    })
                    apiAdmin.getCard(status.number).then(result => {
                        if(result.status == "done"){
                            if(result.data.accountId == false || result.data.accountId == undefined) {
                                // this.objcard = result.data
                                this.showModalUnUser.push(result.data)
                            } else {    
                                // this.objcard = result.data
                                this.testshowModalclient.push(result.data)
                            }
                            this.$toast.success(this.$t('Account') + ` - ` + result.data.userPhone);
                        } 
                    })
                }
            }
        });

        this.eventB.off('createdTicket');
        this.eventB.on('createdTicket', (data) => {
            if(this.checks['150']  && this.statusCall !== 'busy'){
                this.onMusic('all');
            }
            // оповіщення про створення тікету
            // this.eventBus.emit('updateAlert', {
            //     status: true,
            //     type: 'ticket',
            //     color: 'info',
            //     text: 'NewTicketAdded',
            //     icon: 'ri-task-line',
            //     dataAlert: data,
            //     date: this.currentDate()
            // })
            this.$toast.success(this.$t('NewTicketAdded'));
            this.checkPage('tickets');
        })

        this.eventB.off('reopenedTicket');
        this.eventB.on('reopenedTicket', (data) => {
            // оповідення про перевідкриття тікету
            // this.eventBus.emit('updateAlert', {
            //     status: true,
            //     type: 'ticket',
            //     color: 'info',
            //     text: 'reopenTicket',
            //     icon: 'ri-task-line',
            //     dataAlert: data,
            //     date: this.currentDate()
            // })
            // this.onMusic('all');
            this.$toast.warning(this.$t('reopenTicket'));
            // this.checkPage('tickets');
            if(storeS.checkModal.type == 'ticket' && storeS.checkModal.id == data.ticketId){
                this.eventBus.emit('updateTicket', true)
            } else {
                this.checkPage('tickets');
            }
        })

        this.eventB.off('inJobTicketManually');
        this.eventB.on('inJobTicketManually', (data) => {
            for(var item in data.workerIds){
                if(this.user.userid == data.workerIds[item]  && this.statusCall !== 'busy'){
                    // оповіщення закріплення тікету за вами
                    // this.eventBus.emit('updateAlert', {
                    //     status: true,
                    //     type: 'ticket',
                    //     color: 'info',
                    //     text: 'ticketBeenAssignedYou',
                    //     icon: 'ri-task-line',
                    //     dataAlert: data,
                    //     date: this.currentDate()
                    // })
                    this.onMusic('all');
                    this.$toast.warning(this.$t('ticketBeenAssignedYou'));
                }
            }
            // this.checkPage('tickets');
            if(storeS.checkModal.type == 'ticket' && storeS.checkModal.id == data.ticketId){
                this.eventBus.emit('updateTicket', true)
            } else {
                this.checkPage('tickets');
            }
        })

        this.eventB.off('inJobTicket');
        this.eventB.on('inJobTicket', (data) => {
            // оповіщення ваш тікет взяли в роботу
            // this.eventBus.emit('updateAlert', {
            //     status: true,
            //     type: 'ticket',
            //     color: 'info',
            //     text: 'ticketInJobAlert',
            //     icon: 'ri-task-line',
            //     dataAlert: data,
            //     date: this.currentDate()
            // })
            // this.onMusic('all');
            this.$toast.warning(this.$t('ticketInJobAlert'));
            // this.checkPage('tickets');
            if(this.user.userid == data.workerId){
                this.eventBus.emit('updateTicket', true)
            } else {
                this.checkPage('tickets');
            }
        })

        this.eventB.off('decidedTicket');
        this.eventB.on('decidedTicket', (data) => {
            // оповіщення про рішення до тікету
            // this.eventBus.emit('updateAlert', {
            //     status: true,
            //     type: 'ticket',
            //     color: 'info',
            //     text: 'SolutionAddedYourTicket',
            //     icon: 'ri-task-line',
            //     dataAlert: data,
            //     date: this.currentDate()
            // })
            // this.onMusic('all');
            this.$toast.warning(this.$t('SolutionAddedYourTicket'));
            // this.checkPage('tickets');
            if(storeS.checkModal.type == 'ticket' && storeS.checkModal.id == data.ticketId){
                this.eventBus.emit('updateTicket', true)
            } else {
                this.checkPage('tickets');
            }
        })

        this.eventB.off('closedTicket');
        this.eventB.on('closedTicket', (data) => {
            // оповіщення про закриття тікету
            // this.eventBus.emit('updateAlert', {
            //     status: true,
            //     type: 'ticket',
            //     color: 'info',
            //     text: 'yourTicketClose',
            //     icon: 'ri-task-line',
            //     dataAlert: data,
            //     date: this.currentDate()
            // })
            // this.onMusic('all');
            this.$toast.warning(this.$t('yourTicketClose'));
            // this.checkPage('tickets');
            if(storeS.checkModal.type == 'ticket' && storeS.checkModal.id == data.ticketId){
                this.eventBus.emit('updateTicket', true)
            } else {
                this.checkPage('tickets');
            }
        })


        // оповіщення про повідомлення в чаті
        this.eventB.off('sendMessageInTicketChat');
        this.eventB.on('sendMessageInTicketChat', (data) => {
            if(this.user.userid != data.fromWorkerId){
                if(this.checks['201']){
                    this.onMusic('message');
                    this.$toast.success(this.$t('sendMessageInTicketChat'));
                }
                this.eventBus.emit('updateMessage', {
                    status: true,
                    type: 'messageTicket',
                    color: 'info',
                    icon: 'bx bx-message-square-add',
                    text: 'sendMessageInTicketChat',
                    dataMsg: data,
                    date: this.currentDate()
                })
                if(storeS.checkModal.type == 'ticket' && storeS.checkModal.id == data.ticketId){
                    this.eventBus.emit('newMsgticket', true)
                }
                
                // передача повідомлень в тестовий компонент
                if(this.user.userid == 32 || this.user.userid == 34 || this.user.userid == 33 || this.user.userid == 52 || this.user.userid == 42){
                    this.eventBus.emit('newNotifyMsg', {
                        status: true,
                        type: 'messageTicket',
                        color: 'info',
                        icon: 'bx bx-message-square-add',
                        text: 'sendMessageInTicketChat',
                        dataMsg: data,
                        date: this.currentDate()
                    })
                }
            }
        })

        this.eventB.off('replyOnMessageInTicketChat');
        this.eventB.on('replyOnMessageInTicketChat', (data) => {
            if(this.user.userid != data.fromWorkerId){
                if(this.checks['201']){
                    this.onMusic('message');
                    this.$toast.success(this.$t('sendMessageInTicketChat'));
                }
                // повімомлення по всім тікетам або тільки по тим, де закріплений юзер
                // this.eventBus.emit('updateMessage', {
                //     status: true,
                //     type: 'replyMessageTicket',
                //     color: 'info',
                //     icon: 'bx bx-reply-all',
                //     text: 'sendMessageInTicketChat',
                //     dataMsg: data,
                //     date: this.currentDate()
                // })
                if(storeS.checkModal.type == 'ticket' && storeS.checkModal.id == data.ticketId){
                    this.eventBus.emit('newMsgticket', true)
                }
                
            }
        })

        this.eventB.off('sendMessageInProjectChat');
        this.eventB.on('sendMessageInProjectChat', (data) => {
            if(this.user.userid != data.fromWorkerId){
                if(this.checks['201']){
                    this.onMusic('message');
                }
                this.eventBus.emit('updateMessage', {
                    status: true,
                    type: 'messageProject',
                    color: 'info',
                    icon: 'bx bx-message-square-add',
                    text: 'sendMessageInProjectChat',
                    dataMsg: data,
                    date: this.currentDate()
                })
                if(storeS.checkModal.type == 'project' && storeS.checkModal.id == data.projectId){
                    this.eventBus.emit('newMsgproject', true)
                }
                this.$toast.success(this.$t('sendMessageInProjectChat'));
            }
        })

        this.eventB.off('replyOnMessageInProjectChat');
        this.eventB.on('replyOnMessageInProjectChat', (data) => {
            if(this.user.userid != data.fromWorkerId){
                if(this.checks['201']){
                    this.onMusic('message');
                }
                this.eventBus.emit('updateMessage', {
                    status: true,
                    type: 'replyMessageProject',
                    color: 'info',
                    icon: 'bx bx-reply-all',
                    text: 'sendMessageInProjectChat',
                    msg: data,
                    date: this.currentDate()
                })
                if(storeS.checkModal.type == 'project' && storeS.checkModal.id == data.projectId){
                    this.eventBus.emit('newMsgproject', true)
                }
                this.$toast.success(this.$t('sendMessageInProjectChat'));
            }
        })

        this.eventB.off('createBreak');
        this.eventB.on('createBreak', (data) => {
            // оповіщення про відкриття перерви
            // this.eventBus.emit('updateAlert', {
            //     status: true,
            //     type: 'break',
            //     text: data.workerName + " " + this.$t('GoneforBreak'),
            //     color: 'secondary',
            //     icon: 'ri-timer-line',
            //     dataAlert: data,
            //     date: this.currentDate()
            // })
            // this.onMusic('all');
            this.workers.forEach((el, index) => {
                if(el.workerName === data.workerName){
                    storeS.workers[index].statusShift = "goBreak"
                }
            });

            // if(this.user.userid === data.workerId) {
            //     window.location = 'https://cabinet.skyservice.online/'
            // }

            this.$toast.warning(data.workerName + " " + this.$t('GoneforBreak'));
            this.checkPage('breaks');
        })

        this.eventB.off('closeBreak');
        this.eventB.on('closeBreak', (data) => {
            // оповіщення про закриття перерви
            // this.eventBus.emit('updateAlert', {
            //     status: true,
            //     type: 'break',
            //     text: data.workerName + " " + this.$t('closedBreak') + ". " + this.$t('Duration') + " " + data.duration + " " + this.$t('min'),
            //     color: 'secondary',
            //     icon: 'ri-timer-line',
            //     dataAlert: data,
            //     date: this.currentDate()
            // })
            // this.onMusic('all');
            this.workers.forEach((el, index) => {
                if(el.workerName === data.workerName){
                    storeS.workers[index].statusShift = "open"
                }
            });
            this.$toast.warning(data.workerName + " " + this.$t('closedBreak') + ". " + this.$t('Duration') + " " + data.duration + " " + this.$t('min'));
            this.checkPage('breaks');
        })

        this.eventB.off('tryOpenShiftFromAnotherIp');
        this.eventB.on('tryOpenShiftFromAnotherIp', (data) => {
            // this.eventBus.emit('updateAlert', {
            //     status: true,
            //     type: 'shift',
            //     text: this.$t('User') + " " + this.nameWorker(data.workerId) + " " + this.$t('userTriesOpenShift') + ". IP - " + data.ip,
            //     color: 'danger',
            //     icon: 'ri-timer-line',
            //     dataAlert: data,
            //     date: this.currentDate()
            // })
            this.$toast.warning(this.$t('User') + " " + this.nameWorker(data.workerId) + " " + this.$t('userTriesOpenShift') + ". IP - " + data.ip);
            // this.checkPage('breaks');
        })

        this.eventB.off('tryCloseShiftFromAnotherIp');
        this.eventB.on('tryCloseShiftFromAnotherIp', (data) => {
            if(this.check['027'] == true) {
                this.eventBus.emit('updateAlert', {
                    status: true,
                    type: 'shift',
                    text: this.$t('User') + " " + this.nameWorker(data.workerId) + " " + this.$t('userTriesCloseShift') + ". IP - " + data.ip,
                    color: 'danger',
                    icon: 'ri-timer-line',
                    dataAlert: data,
                    date: this.currentDate()
                })
            }
            this.$toast.warning(this.$t('User') + " " + this.nameWorker(data.workerId) + " " + this.$t('userTriesCloseShift') + ". IP - " + data.ip);
            // this.checkPage('breaks');
        })
        
        this.eventB.off('addDump');
        this.eventB.on('addDump', (data) => {
            if(this.checks['028']  && this.statusCall !== 'busy'){
                this.onMusic('all');
                this.eventBus.emit('updateAlert', {
                    status: true,
                    type: 'dump',
                    color: 'info',
                    icon: 'ri-phone-fill',
                    text: 'NewApplicationDumpReceived',
                    dataAlert: data,
                    date: this.currentDate()
                })
            }
            this.$toast.success(this.$t('NewApplicationDumpReceived'));
            this.checkPage('dumps');
        })
        
        this.eventB.off('transferDump');
        this.eventB.on('transferDump', (data) => {
            if(this.user.userid == data.toWorkerId){
                if(this.checks['029']  && this.statusCall !== 'busy'){
                    this.onMusic('all');
                }
                // this.eventBus.emit('updateAlert', {
                //     status: true,
                //     type: 'dump',
                //     color: 'info',
                //     icon: 'ri-honour-line',
                //     text: this.nameWorker(data.fromWorkerId) + " " + this.$t('sentYouDump'),
                //     dataAlert: data,
                //     date: this.currentDate()
                // })
                this.$toast.success(this.nameWorker(data.fromWorkerId) + " " + this.$t('sentYouDump'));

                apiDumps.getDump(data.requestId).then(result => {
                    if(result.status === 'done') {
                        this.objCard = result.data;
                        this.showModalDump.push(this.objCard);
                        storeS.checkModal.type = 'dump';
                        storeS.checkModal.id = data.requestId;
                    } else {
                        this.$toast.err(this.$t('error'))
                    }
                })
            }
        });

        this.eventB.off('updateDumps');
        this.eventB.on('updateDumps', (data) => {
            // this.checkPage('tasks');
            if(storeS.checkModal.type == 'dump' && storeS.checkModal.id == data.requestId){
                this.eventBus.emit('saveDumps', true)
            } else {
                this.checkPage('dumps');
            }
        })

        this.eventB.off('addFine');
        this.eventB.on('addFine', (data) => {
            if(this.user.userid == data.workerId){
                // створення штрафу, виводиться співробітнику за ким штраф
                // this.eventBus.emit('updateAlert', {
                //     status: true,
                //     type: 'fine',
                //     color: 'secondary',
                //     icon: 'bx bxs-detail',
                //     text: 'createdFineuserAlert',
                //     dataAlert: data,
                //     date: this.currentDate()
                // })
                this.$toast.success(this.$t('createdFineuserAlert'));
            }
            this.checkPage('fines');
        })

        this.eventB.off('addHoliday');
        this.eventB.on('addHoliday', (data) => {
            // оповіщення про запит на відпустку 
            // this.eventBus.emit('updateAlert', {
            //     status: true,
            //     type: 'holiday',
            //     color: 'secondary',
            //     icon: 'ri-briefcase-line',
            //     text: 'createdHolidayuserAlert',
            //     dataAlert: data,
            //     date: this.currentDate()
            // })
            this.$toast.success(this.$t('createdHolidayuserAlert'));
            this.checkPage('holydays');
        })

        this.eventB.off('addNewPresentation');
        this.eventB.on('addNewPresentation', (data) => {
            if(this.checks['031']  && this.statusCall !== 'busy'){ // Якщо оператор не заайнятий, то включаємо музику у вухах
                this.onMusic('all');
            }
            this.$toast.success(this.$t('NewPresentationReceived'));
        });

        this.eventB.off('cancelPresentation');
        this.eventB.on('cancelPresentation', (data) => {
            if(storeS.checkModal.type == 'presentation' && storeS.checkModal.id == data.presentationId){
                this.eventBus.emit('updatePresentation', true)
            } else {
                this.checkPage('presentations');
            }
        });

        this.eventB.off('closePresentation');
        this.eventB.on('closePresentation', (data) => {
            // this.checkPage('presentations');
            if(storeS.checkModal.type == 'presentation' && storeS.checkModal.id == data.presentationId){
                this.eventBus.emit('updatePresentation', true)
            } else {
                this.checkPage('presentations');
            }
        });

        this.eventB.off('transferPresentation');
        this.eventB.on('transferPresentation', (data) => {
            if(this.user.userid == data.toWorkerId){
                if(this.checks['029']  && this.statusCall !== 'busy'){
                    this.onMusic('all');
                }
                // оповіщення про передачу презентації, має отримувати юзер, кому передали
                // this.eventBus.emit('updateAlert', {
                //     status: true,
                //     type: 'presentation',
                //     color: 'secondary',
                //     icon: 'ri-skype-fill',
                //     text: this.nameWorker(data.fromWorkerId) + " " + this.$t('sentYouRequest'),
                //     dataAlert: data,
                //     date: this.currentDate()
                // })
                this.$toast.success(this.nameWorker(data.fromWorkerId) + " " + this.$t('sentYouRequest'));
                
                if(this.user.userid == data.toWorkerId){
                    if(storeS.checkModal.type == 'presentation' && storeS.checkModal.id == data.presentationId){
                        this.eventBus.emit('updatePresentation', true)
                    } else {
                        apiPresentations.getCard(data.presentationId).then(result => {
                            if(result.status === 'done') {
                                this.objCard = result.data;
                                this.showModalPresent = true;
                                storeS.checkModal.type = 'presentation';
                                storeS.checkModal.id = data.presentationId;
                            }
                        })
                    }
                } else {
                    this.checkPage('presentations');
                }
            }
        });

        this.eventB.off('fixationTransferPresentation');
        this.eventB.on('fixationTransferPresentation', (data) => {
            if(storeS.checkModal.type == 'presentation' && storeS.checkModal.id == data.presentationId){
                this.eventBus.emit('updatePresentation', true)
            } else {
                this.checkPage('presentations');
            }
        });

        this.eventB.off('editWorker');
        this.eventB.on('editWorker', (data) => {
            if(this.user.userid == data.toWorkerId && this.user.userid !== data.workerId){
                storeS.update = true
            }
        });

        this.eventB.off('addEvent');
        this.eventB.on('addEvent', (data) => {
            if(storeS.checkModal.type == 'event' && storeS.checkModal.id != ''){
                // this.eventBus.emit('updatePresentation', true)
            } else {
                this.checkPage('events');
            }
        });

        this.eventB.off('shareInFileManager');
        this.eventB.on('shareInFileManager', (data) => {
            if(storeS.checkModal.type == 'actionFM' && storeS.checkModal.id != ''){
                // this.eventBus.emit('updatePresentation', true)
            } else {
                this.checkPage('filemanager');
            }

            data.shareWorkerPerms.forEach(element => {
                if(element.workerId == this.user.userid){
                    if(this.checks['029']  && this.statusCall !== 'busy'){
                        this.onMusic('all');
                    }
                    this.$toast.info('З вами поділились папкою/файлом: ' + data.name)
                }
            });
        });

        this.eventBus.off('openFAQ')
        this.eventBus.on('openFAQ', (data) => {
            this.item = data.form.postId
            apiFAQ.getPost(this.item).then(result => {
                if(result.status == 'done') {
                    this.item = result.data
                    this.showPost = true
                }
            })
        })
        
        this.eventBus.off('openKW')
        this.eventBus.on('openKW', (data) => {
            this.item = data.form.lessonId
            apiKW.getPost(this.item).then(result => {
                if(result.status == 'done') {
                    this.item = result.data
                    this.showLesson = true
                }
            })
        })

        this.eventB.on('errorWithReq', status => {
            if(status.status == true) {
                this.$toast.error(this.$t('errWithReq'))
            }
        })
    },
    methods: {
        closeWithCheck(a){
            if(a == false) {
                this.showEnterComp = false
            } else {
                this.showEnterComp = false
                this.eventBus.emit('popNumber', {number: this.actualNumber})
            }
        },
        addComp(e) {
            // this.form.userPhones.push(e)
            this.eventBus.emit('addNumber', {status: true, obj: e})
            this.showEnterComp = false
        },
        clearLocalStorageOnMonday() {
            const mondayKey = 'mondayCleared';

            // Отримуємо поточну дату та день тижня
            const today = new Date();
            const dayOfWeek = today.getDay(); // Понеділок має значення 1 в JavaScript

            // Перевіряємо, чи сьогодні понеділок та чи ще не було очищення
            if (dayOfWeek === 1 && !localStorage.getItem(mondayKey)) {
                // localStorage.clear(); // Очищаємо localStorage
                localStorage.removeItem('completedTasks')
                localStorage.setItem(mondayKey, 'cleared'); // Встановлюємо ключ, щоб вказати, що вже очищено
                console.log('LocalStorage очищено в понеділок!');
            } else {
                console.log('Сьогодні не понеділок або вже очищено. localStorage залишається незмінним.');
            }
        },
        getClient(number) {
            this.showCompClient = false
            
            apiAdmin.getCard(number).then(result => {
                if(result.status == "done"){
                    this.objcard = result.data
                    result.data.сompanies.forEach(el => {
                        if(el.companyName == this.searchComp.companyTitle) {
                            this.searchedComp = el.companyId
                        } 
                    })
                    this.testshowModalclient.push(this.objcard)
                    this.$toast.success(this.$t('Account') + ` - ` + result.data.userPhone);
                } 
            })
        }, // для окремих випадків
        openOld(e) {
            this.eventBus.emit('openOld', {status: true, form: e})
        }, 
        createNew() {
            this.eventBus.emit('createNew', {status: true})
        },
        openParrent(e) {
            this.objClient = e
            this.clientParrent = undefined
            this.testshowModalclient.push(this.objcard)
        },
        closeOne(e){
            this.objcard = e
            this.testshowModalclient.push(this.objcard)
        },
        call(e) {
            localStorage.setItem('actualCall', e)
            this.eventBus.emit('actualCall', {
                status: true
            })
        },
        checkPage(e){
            storeS.checkModal.type = '';
            storeS.checkModal.id = '';
            var url = new URL(window.location.href);
            var searchPath = url.pathname;
            switch (searchPath) {
                case '/calls/all/':
                    if(e == 'requests'){
                        this.eventBus.emit('saveApplic', true)
                    }
                    break;
                case '/tasks/all':
                    if(e == 'tasks'){
                        this.eventBus.emit('saveTask', true)
                    }
                    break;
                case '/newtickets/all':
                    if(e == 'tickets'){
                        this.eventBus.emit('saveTickets', true)
                    }
                    break;
                // case '/newtickets/all':
                //     if(e == 'breaks'){
                //         this.eventBus.emit('saveTickets', true)
                //     }
                //     break;
                case '/calls/dumps':
                    if(e == 'dumps'){
                        this.eventBus.emit('saveDumps', true)
                    }
                    break;
                case '/statistics/fines':
                    if(e == 'fines'){
                        this.eventBus.emit('saveFines', true)
                    }
                    break;
                case '/statistics/holidays':
                    if(e == 'holydays'){
                        this.eventBus.emit('saveHolidays', true)
                    }
                    break;
                case '/presentations':
                    if(e == 'presentations'){
                        this.eventBus.emit('savePres', true)
                    }
                break;
                case '/calendar':
                    if(e == 'events'){
                        this.eventBus.emit('updateEvents', true)
                    }
                break;
                case '/filemanager':
                    if(e == 'filemanager'){
                        this.eventBus.emit('updateFilemanager', true)
                    }
                    break;
            
                default:
                    break;
            }
        },
        onMusic(e){
            if(this.statusMute == '1'){
                var audio = "";
                switch (e) {
                    case 'all':
                        audio = new Audio(sound);
                        // audio.play();
                        break;
                    case "message":
                        if(this.checks['024'] == true){
                            audio = new Audio(new_messages_girl_voice);
                        } else {
                            audio = new Audio(new_message);
                        }
                        break;
                    default:
                        break;
                }
                audio.volume = 0.5
                audio.play();
            }
        },
        currentDate() {
            var date = new Date();
            var dateStr =
            date.getFullYear() + "-" +
            ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
            ("00" + (date.getDate())).slice(-2) + " " +
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2);
            return dateStr
        },
        closeModal(e){
            switch (e) {
                case 'request':
                    this.showModalRequest.pop();
                    this.checkPage('requests');
                break;
                case 'ticket':
                    this.showModalTicket = false;
                    this.checkPage('tickets');
                    window.history.replaceState({}, document.title, window.location.pathname);
                break;
                case 'task':
                    this.showModalTask = false;
                    this.checkPage('tasks');
                break;
                case 'present':
                    this.showModalPresent = false;
                    this.checkPage('presentations');
                break;
                case 'dump':
                    this.showModalDump.pop();
                    this.checkPage('dumps');
                break;
            
                default:
                break;
            }
        },
        swipeHandler(event){
            if(event == 'right'){
                if (window.screen.width <= 767) {
                    this.toggleMenu();
                }
            } else if(document.querySelector('.vertical-sidebar-enable') !== null && event == 'left'){
                if (window.screen.width <= 767) {
                    this.toggleMenu();
                }
            }
        },
        toggleMenu() {
            document.body.classList.toggle("vertical-sidebar-enable");

            if (window.screen.width >= 992) {
                // eslint-disable-next-line no-unused-vars
                router.afterEach((routeTo, routeFrom) => {
                    document.body.classList.remove("vertical-sidebar-enable");
                    document.body.classList.remove("vertical-collpsed");
                });
                document.body.classList.toggle("vertical-collpsed");
            } else {
                // eslint-disable-next-line no-unused-vars
                router.afterEach((routeTo, routeFrom) => {
                document.body.classList.remove("vertical-sidebar-enable");
                });
                document.body.classList.remove("vertical-collpsed");
            }
            this.isMenuCondensed = !this.isMenuCondensed;
        },
        nameWorker(item){
            return nameWorker(item)
        },
        clearStore(){
            var date = new Date();
            var n = date.getDay();
            if(n == 1){
                localStorage.removeItem('urlFiles')
            }
        }, 
        handleUrlChange() {
            const urlParams = new URLSearchParams(window.location.search)
            const ticketId = urlParams.get('id')
            const type = urlParams.get('type')
            this.activeTab = urlParams.get('section')

            if (ticketId && type == "ticket") {
                // Відкрити тікет з ідентифікатором ticketId
                this.openTicket(ticketId)
            }
        },
        openTicket(e) {
            apiTickets.getTicket(e).then(result => {
                if(result.status == 'done'){
                    if(!Array.isArray(result.data.userPhones)) {
                        this.$toast.error(this.$t('error'));

                        var errorText = 'Помилка відкриття тікету. Номери string'; 
                        var statusRequest = "error";
                        var api = 'getTicket';
                        var fileRequest = 'src/views/newtickets/all.vue';
                        var params = id;
                        var response = result.data.userPhones

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                    
                    this.objCard = result.data;
                    this.showModalTicket = true;
                } else {
                    this.$toast.error(this.$t('NotFoundTicket') + " #" + e)
                    window.history.replaceState({}, document.title, window.location.pathname);
                }
            })
        },
        changeSize(bool){
            console.log('textDash', bool)
            if(bool === true) {
                this.changeSizeContent = true
            } else {
                this.changeSizeContent = false
            }
        }

    },
    destroyed() {
        // Прибираємо обробник подій при знищенні компонента
        window.removeEventListener('popstate', this.handleUrlChange)
    },
    mounted(){
        if (localStorage.getItem('statusMute')) {
            this.statusMute = localStorage.getItem('statusMute')
        } else {
            this.statusMute = localStorage.setItem('statusMute', '1')
        }

        this.eventBus.off('changeStatusMute')
        this.eventBus.on('changeStatusMute', status => {
            if(status.status == true) {
                this.statusMute = localStorage.getItem('statusMute')
            }
        })
    },
    computed:{
        statusCall() {
            return storeSip.info.status // статус оператора при дзвінку
        },
        ifMenu(){
            return storeS.menu
        },
        keyForReload() {
            return storeS.routeKey;
        },
        checks(){
            return storeS.checks;
        },
        preloader(){
            return storeS.preloader
        },
        preloaderTable(){
            return storeS.preloaderTable
        },
        user(){
            return storeS.userbase
        },
        workers(){
            return storeS.workers //база работников
        },
        alertU(){
            return storeS.update
        },
        perms(){
            return storeS.perms
        },
        fullMenu() {
            return storeS.fullMenu;
        }
    }
}
</script>

<style scoped>
#app{
    display: block;
    height: 100vh;
    overflow: auto;
}
.main-content{ 
    height: 100%;
    overflow: auto;
}

.main-content::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.main-content::-webkit-scrollbar
{
	width: 5px;
    height: 5px;
	background-color: #F5F5F5;
}

.main-content::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}
#app::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#app::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

#app::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}

#app{
    padding-top: env(safe-area-inset-top) !important;
    /* height: calc(env(safe-area-inset-top) + 60px) !important; */
}

@media screen and (max-width: 820px) and (-webkit-device-pixel-ratio: 3), 
       screen and (max-width: 820px) and (-webkit-device-pixel-ratio: 2) {
        #app {
            padding-top: calc(env(safe-area-inset-top) + 40px)!important; /* додатковий відступ в 10px */
        }
        .modal {
            padding-top: calc(env(safe-area-inset-top) + 40px); /* додатковий відступ в 10px */
            background-color: #212529!important; /* Колір підкладки */
        }
        header#page-topbar {
            padding-top: calc(env(safe-area-inset-top) + 40px)!important; /* додатковий відступ в 10px */
        }
}

@media all and (max-width: 767px) {
    .adaptive-burger{
        margin-left: 0px !important
    }
}
</style>
