<template>

    <modal>

        <!-- Заголовок -->
        <template v-slot:title>{{ $t('Гайд') }}</template>  

        <!-- Кнопки навбару вікна -->
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <template v-slot:body>

            <div class="" >
                <div class="card-body p-5" >
                    <div class="text-center" >
                        <h3>Гайд/пояснення на створення завдання тестування</h3>
                        <p class="mb-0 text-muted">Останнє оновлення: 13.09.2024</p>
                    </div>
                </div>
            </div>

            <div >
                <p class="text-muted">Щоб забезпечити якісне тестування, завдання для QA повинно бути чітким, детальним і структурованим. Нижче наведені рекомендації, які допоможуть створити ефективну задачу для тестувальників.</p>
            </div>

            <div class="preconditions mt-1 pb-3" >
                <div class="header-line-container" >
                    <h6 class="fw-semibold text-uppercase" >
                        Завдання на тестування складається з
                    </h6>
                </div>
                <ul class="ps-4 vstack gap-2 mt-3 text-muted fs-14" >
                    <li>
                        <p>
                            <strong>Назва завдання</strong>:<br />
                            Назва має бути короткою, але інформативною, щоб тестувальники одразу зрозуміли, що саме потрібно перевіряти.
                        </p>

                        <p class="mt-2"><strong>Приклад</strong>: "Тестування проведення продажу товару в POS-терміналі"</p>
                    </li>

                    <li>
                        <p>
                            <strong>Проект</strong>:<br />
                            Вкажіть проект, в якому проводиться тестування (наприклад, "POS-термінал").
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Пріоритет</strong>:<br />
                            Виберіть пріоритет виконання завдання:
                        </p>

                        <ul>
                            <li><b>Високий пріоритет (high)</b> - критично важливе і має бути виконане першочергово.</li>
                            <li><b>Середній пріоритет (medium)</b> - потрібно виконати найближчим часом.</li>
                            <li><b>Низький пріоритет (low)</b> - не критично важливе, можна виконати пізніше.</li>
                        </ul>
                    </li>

                    <li>
                        <p>
                            <strong>Тип тестування (за потреби):</strong>:<br />
                            Якщо є необхідність вказати тип тестування, зазначте його. Приклади:
                        </p>

                        <ul>
                            <li><b>Функціональне тестування</b> - перевірка конкретної функції.</li>
                            <li><b>Навантажувальне тестування</b> - перевірка системи під великим навантаженням.</li>
                            <li><b>Регресійне тестування</b> - перевірка, чи нові зміни не зламали інші частини системи.</li>
                        </ul>

                    </li>

                    <li class="mt-2">
                        <p>
                            <strong>Опис</strong>:<br />
                            Вкажіть детальний опис того, що потрібно протестувати, включаючи інформацію про нові чи змінені функціональності.
                        </p>
                    
                    </li>

                    <li class="mt-2">
                        <p>
                            <strong>Передумови тестування</strong>:<br />
                            Вкажіть всі умови, які мають бути виконані перед початком тестування, наприклад:
                        </p>

                        <ul>
                            <li>Користувач повинен бути авторизований під роллю співробітника.</li>
                            <li>Функція додавання товарів має бути активована в системі.</li>
                            <li>Версія програми повинна бути не нижче 1.22.</li>
                        </ul>
                    </li>

                    <li>
                        <p>
                            <strong>Чек-лист</strong>:<br />
                            Короткий список елементів, які повинні бути перевірені під час тестування. 
                        </p>

                        <p><strong>Приклад:</strong></p>
                        <ul>
                            <li>Перевірка додавання товару з коректними даними.</li>
                            <li>Перевірка обробки некоректних даних.</li>
                            <li>Перевірка повідомлення про успіх або помилку.</li>
                        </ul>

                    </li>

                    <li>
                        <p>
                            <strong>Тест-кейси</strong>:<br />
                            Додайте окремі тест-кейси для різних сценаріїв тестування. Кожен тест-кейс має бути описаний чітко і містити:
                            <ul>
                                <li>Кроки для виконання</li>
                                <li>Очікуваний результат</li>
                            </ul>
                        </p>

                        <p><strong>Приклад тест-кейсу:</strong></p>
                        <ul>
                            <li>Відкрити розділ "Товари".</li>
                            <li>Натиснути "Додати".</li>
                            <li>Заповнити всі поля коректними даними.</li>
                            <li>Натиснути "Додати".
                                <p>
                                    <strong>Очікуваний результат</strong>
                                    Товар з'являється у списку товарів з коректними даними, система видає повідомлення про успішне збереження.
                                </p>
                            </li>
                        </ul>

                    </li>


                    <li>
                        <p>
                            <strong>Середовище тестування</strong>:<br />
                            Вкажіть середовище, на якому прововодилось тестування
                        </p>

                        <ul>
                            <li>
                                <p>
                                    <strong>Операційна система</strong>:<br />
                                    Оберіть ОС, де виникла проблема, наприклад, <strong>Windows</strong>.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Версія ОС</strong>:<br />
                                    Вкажіть точну версію ОС, наприклад, Windows 10.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Застосунок для тестування</strong>:<br />
                                    Вкажіть назву програми або модуль, в якому тестувалася система.
                                </p>
                            </li>
                        </ul>

                    </li>

                    <li>
                        <p>
                            <strong>Додаткові файли</strong>:<br />
                            Додайте всі необхідні файли для тестування, якщо це потрібно:
                        </p>

                        <ul>
                            <li>Скріншоти</li>
                            <li>Тестові дані</li>
                            <li>Відео</li>
                            <li>Гайд</li>
                        </ul>
                    </li>

                </ul>
            </div>

        </template>

    </modal>

</template>

<script>
import modal from '@/components/modal-small'

export default {
    components: {
        modal
    },
    data() {
        return {
            form: ''
        }
    },
}
</script>
