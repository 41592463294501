<template>
    <b-card>
        <div class="card-header">
            <h5 class="card-title mb-0"><b>{{ $t('aboutApplication') }}</b></h5>
        </div>
        <div class="card-body">
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('phone_number') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content d-flex">
                                <h6 :class="`fs-14 mb-0 fw-normal copy `" @click="getClient(this.form)">{{ form.userPhone }}</h6>
                                <i class="ri-file-copy-2-fill fs-16 copybtn text-secondary" style="margin-left: 10px;" @click="copy(this.form.userPhone)"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('priority') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-normal copy ${this.mutatePriority(form.priority).bg}`" >{{ this.mutatePriority(form.priority).name }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3" v-if="form.createWorkerName">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('created') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-normal copy `" @click="copy(this.form.createWorkerName)">{{ form.createWorkerName }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3" v-if="form.createDatetime">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('createDate') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-normal copy `" @click="copy(this.form.createDatetime)">{{ form.createDatetime }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3" v-if="form.createComment || this.form.ticketName">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('Comment') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`mb-0 fs-16 `" v-if="this.form.createComment" @click="this.form.createComment == 'new_registration' ? copy(this.$t('newRegister')) :  copy(this.form.createComment)">{{ form.createComment == 'new_registration' ? this.$t('newRegister') : (form.createComment == 'create_company' ? this.$t('newComp') :  this.form.createComment) }}</h6>
                                <h6 :class="`mb-0 fs-16 `" style="cursor: pointer" @click="this.openTicket(this.form.ticketId)" v-if="this.form.ticketName">{{this.$t('createdFromTicket')}}: {{ this.form.ticketName }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3" v-if="this.form.fromSite == 1">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('Comment') }}:</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`mb-0 fs-16 `" @click="copy(this.$t('fromSite'))">{{ this.$t('fromSite') }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
import {copy} from '@/usabilityScripts/copy.js'
import { mutatePriority } from '@/usabilityScripts/globalMutate.js'
import { Archive } from '../../../../API'

let archiveTicketimport = new Archive()

export default{
    props: ['obj'],
    data(){
        return {
            form: ''
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
        openTicket(id) {
            archiveTicketimport.getArchiveTicket(id).then(result => {
                if(result.status == 'done') {
                    this.eventBus.emit('modalInModal', {
                        status: true,
                        data: result.data,
                        type: 'ticket',
                    })
                }
            })
        },
        getClient(e) {
            this.eventBus.emit('getClient', {status: true, number: e.userPhone, requestId: this.form.requestId})
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        mutatePriority(e){
            return mutatePriority(e)
        },
    }
}
</script>
