<template>
    <!-- 
        Тестовий компонент на повідомлення, у правому кутку екрана
        - зникають коли їх закриє користувач
        - відображаються поверх вікон
    -->

    <div 
        :key="componentKey"
        class="toast-container1" 
        style="width: 350px;"
    >
        <template v-for="(item, index) in newMsg" :key="index">
            <div 
                class="toast fade show" 
                :id="`notify${index}`"
                role="alert" 
                aria-live="assertive"
                aria-atomic="true" 
                data-bs-toggle="toast"
                :style="`position: absolute; top: ${index * 99 + 16}px; right: 16px;z-index: 6;height:90px;cursor:pointer;`"
            >
                <div class="toast-header">
                    <!-- ID тікету -->
                    <span style="margin-right: 5px;">#{{ item.dataMsg.ticketId }}</span>
                    <span class="fw-semibold me-auto">{{ $t("Message") }}</span>

                    <!-- Поле дати повідомлення -->
                    <small>{{ item.date }}</small>
                    <button 
                        @click="closeNotify(index)"
                        type="button" 
                        class="btn-close"
                        data-bs-dismiss="toast" 
                        aria-label="Close"
                    ></button>
                </div>

                <div 
                    class="toast-body" 
                    style="overflow: auto;height: 50px;"
                    @click="open(item);closeNotify(index)"
                >
                    <!-- Текст повідомлення -->
                    <span v-html="item.dataMsg.message.slice(0, 100)"></span>
                </div>
            </div>
        </template>

        <!-- Кнопка очистити -->
        <button 
            v-if="newMsg.length > 0"
            @click="clear()"
            type="button" 
            class="btn btn-danger waves-effect waves-light"
            :style="`
                    position: absolute; 
                    width: 350px; 
                    top: ${newMsg.length * 102 + 16}px; 
                    right: 16px;
                    z-index: 6;`
            "
        >
            {{ $t('Clear') }}
        </button>
    </div>
</template>

<script>

export default {
    props: [],
    data(){
        return{
            componentKey: 0, // ключ для ререндеру
            newMsg: [] // масив для повідомлень
        }
    },
    created(){
        console.log(this.newMsg.length);
    },
    methods: {
        closeNotify(x){
            // закриття картки повідомлення

            this.newMsg.splice(x, 1);
            this.componentKey += 1;
        },
        clear(){
            // очищення повідомлень
            this.newMsg = []
        },
        open(e){
            // відкриття чату тікета з повідомленнями
            this.eventBus.emit('openChatTicket', {
                status: true,
                type: 'messageTicket',
                noticeData: {
                    type: 'messageTicket'
                },
                ticketId: e.dataMsg.ticketId,
                data: e,
            });
        }
    },
    mounted(){
        // Відписка та підписка на подію для оновлення повідомлень
        this.eventBus.off('newNotifyMsg')
        this.eventBus.on('newNotifyMsg', data => {
            if (data.status == true) {
                if(this.newMsg.length > 5) {
                    this.newMsg = [];
                    this.newMsg.push(data)
                } else {
                    this.newMsg.push(data)
                }
            }
        })
    }
}
</script>