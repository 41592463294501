<template>
    <!-- Компонент з правою частиною верхнього меню -->

    <!-- Випадаюче меню "Sky Apps" -->
    <div class="dropdown topbar-head-dropdown ms-1 header-item d-none d-sm-flex">
        <button 
            type="button" 
            class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            data-bs-toggle="dropdown" 
            aria-haspopup="true" 
            aria-expanded="false"
            @click="getCompanies"
        >
            <i class="bx bx-category-alt fs-22"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
            <div class="
                p-3
                border-top-0 border-start-0 border-end-0 border-dashed border
            ">
                <div class="row align-items-center">
                    <!-- Заголовок блоку -->
                    <div class="col">
                        <h6 class="m-0 fw-semibold fs-15">Sky Apps</h6>
                    </div>
                    <div class="col-auto">
                    </div>
                </div>
            </div>

            <div class="p-2">
                <div class="row g-0">
                    <!-- Вивід іконок для кожного елементу "Sky Apps" -->
                    <template v-for="item in skyApps" :key="item">
                        <div  class="col-md-4" v-if="item.title != 'Git'">
                            <a class="dropdown-icon-item" target="_blank" :href="item.href">
                                <img :src="require(`@/assets/images/webapps/${item.img}.png`)" :alt="item.title" />
                                <span>{{ item.title }}</span>
                            </a>
                        </div>
                        <div  class="col-md-4" v-else-if="item.title == 'Git' && (this.user.workerJob == 'developer')">
                            <a class="dropdown-icon-item" target="_blank" :href="item.href">
                                <img :src="require(`@/assets/images/webapps/${item.img}.png`)" :alt="item.title" />
                                <span>{{ item.title }}</span>
                            </a>
                        </div>
                    </template>
                </div>
            </div>

            <!-- Блок власних компаній, відкритий тільки для Максів -->
            <template
                v-if="user.userid == 34 || user.userid == 32"
            >
                <div class="card-footer">
                    <!-- <a href="javascript:void(0);" class="link-success float-end">Показати більше <i class="ri-arrow-right-s-line align-middle ms-1 lh-1"></i></a> -->
                    <p class="text-muted mb-0 fw-semibold">Мої компанії</p>
                </div>

                <div class="p-2">
                    <div class="row g-0">
                        <!-- Вивід іконок для кожної компанії -->
                        <template v-for="item in companies" :key="item">
                            <div  class="col-md-4" v-if="item.company_server != 's-3'">
                                <a class="dropdown-icon-item" target="_blank" :href="item.url">
                                    <img :src="require(`@/assets/images/webapps/skydash.png`)" :alt="item.title" />
                                    <span>{{ item.company_name }}</span>
                                </a>
                            </div>
                        </template>
                    </div>
                </div>
            </template>

        </div>
    </div>

    <!-- Увімкнення/вимкнення темного режиму -->
    <div class="ms-1 header-item d-none d-sm-flex" v-if="this.checks['022']">
        <button
            type="button"
            class="
                btn btn-icon btn-topbar btn-ghost-secondary
                rounded-circle
                light-dark-mode
            "
            @click="toggleDarkMode"
        >
            <i class="bx bx-moon fs-22"></i>
        </button>
    </div>

    <!-- Кнопка для відображення сповіщень -->
    <notificationsBtnNew />

    <!-- Кнопка для відображення списку працівників -->
    <workersBtn />

    <!-- Кнопка для відкриття меню користувача та особиста інформація -->
    <div class="dropdown ms-sm-3 header-item topbar-user">
        <button
            type="button"
            class="btn"
            id="page-header-user-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
        >
            <span class="d-flex align-items-center">
                <div class="avatar-xs" style="margin: 0 auto;">
                    <!-- Ініціали співробітника -->
                    <div 
                        v-if="this.workers.find(el => el.workerId === this.user.userid).avatar === ''"
                        :class="`avatar-title rounded bg-success text-white`"
                    >
                        {{this.getInitials(user.name)}} 
                    </div>

                    <!-- Аватар співробітника -->
                    <div 
                        v-else
                        :class="`avatar-title rounded bg-success text-white`"
                    >
                        <img :src="this.workers.find(el => el.workerId === this.user.userid).avatar" alt="worker_avatar" style="width: 32px; border-radius: 7px">
                    </div>
                </div>

                <!-- Ім'я співробітника -->
                <span class="text-start ms-xl-2">
                    <span
                        class="
                            d-none d-xl-inline-block
                            ms-1
                            fw-medium
                            user-name-text
                        "
                    >
                        {{user.name}}
                    </span>
                </span>
            </span>
        </button>

         <!-- Випадаюче меню користувача -->
        <div class="dropdown-menu dropdown-menu-end">
            <!-- Елементи випадаючого меню -->
            <h6 class="dropdown-header">{{ $t('hi') }} {{user.name}}!</h6>

            <!-- Посилання на головну сторінку -->
            <router-link class="dropdown-item" to="/">
                <i
                    class="
                        mdi mdi-account-circle
                        text-muted
                        fs-16
                        align-middle
                        me-1
                    "
                ></i>
                <span class="align-middle">{{ $t('startPage') }}</span>
            </router-link>

            <!-- Відкриття зміни -->
            <button 
                v-if="this.user.statusshift == 'close'" 
                class="dropdown-item" 
                @click="this.$emit('openShift')"
            >
                <i class="ri-timer-line text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{$t('OpenShift')}}</span>
            </button>

            <!-- Закриття зміни -->
            <button 
                v-if="this.user.statusshift == 'open'" 
                class="dropdown-item" 
                @click="this.$emit('closeShift')"
            >
                <i class="ri-timer-fill text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{$t('CloseShift')}}</span>
            </button>

            <!-- Закриття зміну співробітнику -->
            <button 
                v-if="this.user.userid == 33 || this.user.userid == 34" 
                class="dropdown-item" 
                @click="this.$emit('closeShiftWorker')"
            >
                <i class="ri-timer-2-line text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{$t('closeShiftForWorker')}}</span>
            </button>
            
            <!-- Перерва -->
            <button 
                v-if="(this.break.statusBreak == false && this.user.workerJob !== `managercallcenter`)" 
                class="dropdown-item" 
                @click="openbreakbox()"
            >
                <i class="ri-timer-line text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{$t('break')}}</span>
            </button>

            <button 
                v-if="(this.break.statusBreak == false && this.user.workerJob == `managercallcenter`)" 
                class="dropdown-item" 
                @click="openbreakbox()" 
            >
                <i class="ri-timer-line text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{$t('break')}}</span>
            </button> 

            <button 
                v-if="this.break.statusBreak == true" 
                class="dropdown-item" 
                @click="closeBreak()"
            >
                <i class="ri-timer-line text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{$t('closeBreak')}}</span>
            </button>

            <div class="dropdown-divider"></div>
            
            <!-- Профіль користувача -->
            <button 
                v-if="this.perms[700] == true" 
                class="dropdown-item" 
                @click="myprofile()"
            >
                <i class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{$t('Profile')}}</span>
            </button>

            <!-- Перехід до календаря -->
            <router-link class="dropdown-item" to="/calendar">
                <i class="ri-calendar-todo-fill text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{$t('Calendar')}}</span>
            </router-link>

            <!-- логи -->
            <router-link class="dropdown-item" to="/logs" v-if="perms[2000]">
                <i class="bx bx-info-circle text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{$t('Logs')}}</span>
            </router-link>

            <!-- Посилання на особистий кабінет -->
            <a 
                class="dropdown-item" 
                href="https://cabinet.skyservice.online/"
            >
                <i class="ri-home-smile-fill text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{ $t('myCabinet') }}</span>
            </a>
            
            <div class="dropdown-divider d-lg-none d-xl-none" v-if="this.checks['022']"></div>

            <!-- Кнопка для зміни теми (моб) -->
            <button 
                class="d-flex d-sm-flex d-md-flex dropdown-item" 
                v-if="this.checks['022']"  
                @click="toggleDarkMode"
            >
                <i class="bx bx-moon text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{$t('darkTheme')}}</span>
            </button>

            <div class="dropdown-divider"></div>
            
            <!-- Кнопка для відкриття модального вікна для анонімного повідомлення -->
            <button 
                class="dropdown-item" 
                @click="openAnonimMessageModal()" 
                style="
                    display: grid;
                    grid-template-columns: 1fr 9fr;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                "
            >
                <span style="font-size: 16px;">
                    <i class="ri-chat-private-line text-muted fs-16 align-middle me-1"></i>
                </span>
                <span class="align-middle">{{$t('anonMessage')}}</span>
            </button>

            <template v-if="perms[2000]">

                <div class="dropdown-divider"></div>

                <!-- Кнопка для Активації/деактивації всіх можливостей -->
                <button 
                    class="dropdown-item"
                    @click="changeFullAccess()"
                    style="
                        display: grid;
                        grid-template-columns: 1fr 9fr;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                        height: 30px;
                    "
                >
                    <span style="font-size: 16px;">
                        <i :class="`${!statusAllFeatures ? 'bx bx-lock-open-alt' : 'bx bx-lock-alt'} text-muted fs-16 align-middle me-1`"></i>
                    </span>
                    <span class="align-middle">{{ !statusAllFeatures ? $t('Активувати повний доступ') : $t('Деактивувати повний доступ')}}</span>
                </button>

            </template>

        </div>
    </div>

</template>

<script>
import workersBtn from './workersNew' // Кнопка для відображення списку працівників
import notificationsBtnNew from './noticeNew/index.vue' // Оновлені сповіщення
import { storeS } from '@/store'; // глобальне сховище даних
import { AuthCabinet } from '@/API' // клас для взаємодії з API 

let apiServe = new AuthCabinet();

export default {
    data(){
        return {
            fullAccess: false,
            skyApps: [ // Список додатків "Sky Apps" з параметрами
                { title: 'Cabinet', href: 'https://auth.skyservice.online/', img: 'skycabinet', perm: 'all' },
                { title: 'Dashboard', href: 'https://online.skyservice.pro/dashboard-new/', img: 'skydash', perm: 'all' },
                { title: 'Pos', href: 'https://online.skyservice.pro/?section=pos', img: 'posterminal', perm: 'all' },
                { title: 'Support', href: 'https://support.skyservice.pro/', img: 'skysupport', perm: 'all' },
                { title: 'Adminka', href: 'https://admin.skyservice.online/', img: 'skyadminka', perm: 'all' },
                { title: 'Git', href: 'https://git.skyservice.pro/dashboard/projects', img: 'git', perm: 'developer' },
                { title: 'Toolsy', href: 'https://toolsyit.com/', img: 'toolsy', perm: 'developer' },
            ],
            statusAllFeatures: false,
            companies: []
        }
    },
    components: { 
        workersBtn,
        notificationsBtnNew
    },
    mounted() {

        console.log("dsds", localStorage.getItem('fullAccess'));
        if(localStorage.getItem('fullAccess')){
            if (localStorage.getItem('fullAccess') == 'true') {
                this.statusAllFeatures = true
                this.perms[666] = this.statusAllFeatures;
            } else {
                this.statusAllFeatures = false
                this.perms[666] = this.statusAllFeatures;
            }
        }
        console.log(this.perms);
        
        

      // this.eventBus.on('updateOnline', (status) => {
      //   if(status == true) {
      //     alert('dsd');
      //   }
      // })
    },
    created() {
        console.log(this.user)
    },
    methods: {
        changeFullAccess(){
            if(this.statusAllFeatures){
                this.statusAllFeatures = !this.statusAllFeatures;
                localStorage.setItem('fullAccess', false);
                storeS.perms[666] = false;
                this.$toast.success('Повний доступ деактивовано');
            } else {
                this.statusAllFeatures = !this.statusAllFeatures;
                localStorage.setItem('fullAccess', true);
                storeS.perms[666] = true;
                this.$toast.error('Повний доступ активовано');
            }
        },
        getInitials(string) {
            // Отримання ініціалів з імені користувача

            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
        openbreakbox(){
            // Відкриття перерви
            this.$emit('openbreakbox')
        },
        openAnonimMessageModal(){
            // Відкриття модального вікна для анонімного повідомлення
            this.$emit('openAnonimMessageModal')
        },
        closeBreak(){
            // Закриття робочої перерви
            this.$emit('closeBreak')
        },
        myprofile(){
            // Відкриття профілю користувача
            this.$emit('myprofile', this.user)
        },
        toggleDarkMode() {
            // Зміна теми (світла/темної)

            var typeTheme = localStorage.getItem('nightTheme');
            if (typeTheme == 'light') {
                localStorage.setItem('nightTheme', 'dark')
                document.documentElement.setAttribute("data-layout-mode", "dark");
                storeS.nightTheme = 'dark'
            } else {
                localStorage.setItem('nightTheme', 'light')
                document.documentElement.setAttribute("data-layout-mode", "light");
                storeS.nightTheme = 'light'
            }
        },
        getCompanies(){
            // Отримання списку власних компаній

            apiServe.getCompanies().then(result => {
                if(result.status == "done"){
                    var companies = result.data.companies;
                    let updatedCompanies = companies.map(company => {
                        let url = '';

                        switch (company.company_server) {
                            case 's-0':
                                url = 'https://admin.skyservice.online/?&companyId=' + company.company_id + '&lang=uk';
                                break;
                            case 's-1':
                                url = 'https://online.skyservice.pro/dashboard-new/?token=' + storeS.token + '&companyId=' + company.company_id + '8&lang=uk';
                                break;
                            // case 's-2':
                            //     url = 'https://example.com/server2';
                            //     break;
                            // case 's-3':
                            //     // inside
                            //     url = 'https://example.com/server3';
                            //     break;
                            case 's-4':
                                url = 'https://xn-l3h.dashboard.skyservice.online/?token=' + storeS.token + '&companyId=' + company.company_id + '';
                                break;
                            case 'staging':
                                url = 'https://staging.pos.skyservice.online/?token=' + storeS.token + '&companyId=' + company.company_id + '&lang=uk';
                                break;
                            default:
                                url = 'https://skyservice.pro';
                                break;
                        }

                        return {
                            ...company,
                            url: url
                        };
                    });

                    this.companies = updatedCompanies;
                }
            })
        }
    },
    computed: {
        perms() {
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        checks(){
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
        break() {
            /*
                Повертає дані перерви юзера з глобального стору.
            */
            return storeS.break
        },
        workers() {
            // Повертає список співробітників
            return storeS.workers
        },
    },
}
</script>