<template>
    <!-- модальное окно создания/отправки смс -->
    <modal>
        <template v-slot:title>{{ $t('sendMessage') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
                <div class="mb-3">
                    <h4 class="fs-15">{{ $t('enterPhoneNumber') }}</h4>
                    <input type="number" class="form-control" id="contactNumber" v-model="this.form.userPhone" placeholder="+380">
                </div>
                <autotag :link="this.form.url" @inputCustom="inputText"></autotag>
                
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button>
            <button type="button" class="btn btn-primary" @click="sendSms">{{ $t('Add') }}</button>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal-small' 
import autotag from '@/components/autotag'
import { Clients } from '@/API'
import { v4 as uuidv4 } from 'uuid';

let apiServe = new Clients();

export default ({
    data(){
        return {
            form:{
                userPhone: "",
                text: "",
                company: '',
                formId: ''
            }
        }
    },
    props:['link', 'phone', 'obj'],
    components: { modal, autotag},
    created(){
        // this.form.url = this.link
        if(this.phone){
            this.form.userPhone = this.phone
        }
    },
    methods: {
        inputText(text){
            console.log(text)
            if(text) {
                this.form.text = text.message;
            } else {
                this.form.text = '';
            }
    
            console.log(this.form.text)
        },
        sendSms(){
            if (!this.form.userPhone) {
                //перевіряємо чи вказаний номер
                this.$toast.error(this.$t('EnterNumber'));
            } else
            if (!this.form.text) {
                //перевіряємо чи вказаний текст
                this.$toast.error(this.$t('EnterSms'));
            } else {    
                this.form["formId"] = uuidv4();
                this.form.company = this.obj?.companyId    
                this.$emit('sendSMS', this.form)
                this.$emit('close')
            }
        }
    }
})
</script>
