<template>
    <!-- 
        Компонент Контекстного меню
    -->
    <teleport to="body">
      <transition name="fade">
        <div v-if="isVisible" id="contextMenu" class="context-menu" :style="{ left: posX + 'px', top: posY + 'px' }" @click.stop>
            <ul class="menu"> 
                <template v-for="item in btn" :key="item" >
                    <li v-if="isShowItem(item) && item.status == true" :class="item.color" @click="handleOptionClick(item.value)">
                        <a href="#">
                            <i :class="item.icon" aria-hidden="true"></i> 
                            {{ item.title }}
                        </a>
                    </li> 
                </template>
            </ul> 
        </div>
      </transition>
    </teleport>
</template>
  
<script>
import { storeS } from '@/store';

export default {
    props: ['btn'],
    data() {
      return {
        isVisible: false,
        posX: 0,
        posY: 0,
        tempItem: ''
      };
    },
    methods: {
      showContextMenu(event, item) {
        console.log('event', event);
        console.log('item', item);
        // Виклик меню
        this.tempItem = item
        this.isVisible = true;
        this.posX = event.clientX;
        this.posY = event.clientY;
        document.addEventListener('click', this.hideContextMenu);
      },
      hideContextMenu() {
        // Ховаємо меню
        this.isVisible = false;
        document.removeEventListener('click', this.hideContextMenu);
      },
      handleOptionClick(option) {
        // Опрацьовуємо вибір
        this.$emit('select', option, this.tempItem);
        this.hideContextMenu();
      },
      isShowItem(option) {
        /**
          * Метод для перевірки, чи слід показувати пункт меню відповідно до прав доступу користувача.
          * @param {Object} option - Об'єкт з параметрами елемента.
          * @returns {boolean} - Результат перевірки на відображення елемента.
        */

        return this.perms[2000] == true || this.perms[option.perms] == true || option.perms == null;
      }
    },
    computed: {
      perms(){
        /*
            Повертає дані прав доступу користувача з глобального стору.
        */
        return storeS.perms
      }
    }
  };
</script>
  
<style scoped>
.context-menu { 
    position: absolute; 
    z-index: 5;
} 

.menu {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
    /* box-shadow: -1px 1px 35px -11px rgba(0,0,0,0.7);
    -webkit-box-shadow: -1px 1px 35px -11px rgba(0,0,0,0.7);
    -moz-box-shadow: -1px 1px 35px -11px rgba(0,0,0,0.7); */
    padding: 10px 0;
    list-style: none;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.menu > li > a {
    font: inherit;
    font-size: 15px;
    border: 0;
    padding: 10px 30px 10px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: unset;
    color: #000;
    font-weight: 500;
    transition: 0.3s linear;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
}

.menu > li > a:hover {
    background: #f1f7f2;
    color: #409b56;
}

.menu > li > a > i {
    padding-right: 10px;
}

.menu > li.danger > a:hover {
    color: red;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.1s ease-in-out;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
  