<template>
    
    <dialogBox>
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('DeleteItem') }}</template>

        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="this.$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>
            
            <div class="mt-4 text-center">
                <h3><b>{{ $t('confirmDeletion') }}?</b></h3>
                <p class="text-muted fs-15 mb-4">{{ $t('deletingPosition') }}</p>
            </div>

        </template>

        <!-- Слот футера модального вікна з кнопками -->
        <template v-slot:footer-bottom >
            <div class="mt-3">

                <!-- 
                    Кнопка "Видалити" 
                    - надає змогу відредагувати подію
                -->
                <button 
                    type="button" 
                    class="btn btn-danger" 
                    @click="$emit('deleteItem')"
                >
                    {{ $t('Delete') }}
                </button>
            </div>
        </template>

    </dialogBox>

</template>

<script>
import dialogBox from '@/components/modal/dialog'

export default {
    props: [],
    components: {
        dialogBox
    },
    data() {
        return {
            form: ''
        }
    },
}

</script>