<template>

    <!-- 
        Шапка картки завдання
    -->
    
    <div class="row" >
        <div class="col-lg-12" >
            <div class="card mt-n4 mx-n3" >
                <div class="bg-soft-info" >
                    <div class="card-body pb-0 px-4" >
                        <div class="row mb-3" >
                            <div class="col-md" >
                                <div class="row align-items-center g-3" >
                                    <!-- <div class="col-md-auto" >
                                        <div class="avatar-md" >
                                            <div class="avatar-title bg-white rounded-circle" >
                                                <img src="assets/images/brands/slack.png" alt="" class="avatar-xs">
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-md" >
                                        <div >
                                            <h4 class="fw-bold">{{ form.title }}</h4>
                                            <div class="hstack gap-3 flex-wrap" >
                                                <div >Проект: {{ form.template }}</div>
                                                <div class="vr" ></div>
                                                <div >Дата створення : <span class="fw-medium">10 вересня, 2024</span></div>
                                                <div class="vr" ></div>
                                                <div >Термін виконання : <span class="fw-medium">10 жовтня, 2024</span></div>
                                                <div class="vr" ></div>
                                                <div class="badge rounded-pill bg-info fs-12" >Новий</div>
                                                <div class="badge rounded-pill bg-danger fs-12" >Високий</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-auto" >
                                <div class="hstack gap-1 flex-wrap" >
                                    <button type="button" class="btn py-0 fs-16 favourite-btn active">
                                        <i class="ri-star-fill"></i>
                                    </button>
                                    <button type="button" class="btn py-0 fs-16 text-body">
                                        <i class="ri-share-line"></i>
                                    </button>
                                    <button type="button" class="btn py-0 fs-16 text-body">
                                        <i class="ri-flag-line"></i>
                                    </button>
                                </div>
                            </div> -->
                        </div>

                        <ul class="nav nav-tabs-custom border-bottom-0" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link fw-semibold active" data-bs-toggle="tab" href="#testing-main" role="tab" aria-selected="true">
                                    Основне
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link fw-semibold" data-bs-toggle="tab" href="#testing-devices" role="tab" aria-selected="false" tabindex="-1">
                                    Пристрої
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link fw-semibold" data-bs-toggle="tab" href="#testing-chat" role="tab" aria-selected="false" tabindex="-1">
                                    Чат
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link fw-semibold" data-bs-toggle="tab" href="#testing-checklist" role="tab" aria-selected="false" tabindex="-1">
                                    Чек-лист
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link fw-semibold" data-bs-toggle="tab" href="#testing-testcases" role="tab" aria-selected="false" tabindex="-1">
                                    Тест-кейси
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link fw-semibold" data-bs-toggle="tab" href="#testing-reports" role="tab" aria-selected="false" tabindex="-1">
                                    Баг-репорти
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link fw-semibold" data-bs-toggle="tab" href="#testing-files" role="tab" aria-selected="false" tabindex="-1">
                                    Файли
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    props: ['objCard'],
    components: {

    },
    data() {
        return {
            form: ''
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.objCard
        }
    }
}
</script>
