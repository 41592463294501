<template>
    <b-card>
        <div class="card-header">
            <h5 class="card-title mb-0"><b>{{ $t(`${this.title}`) }}</b></h5>
        </div>
        <div class="card-body">
            <template v-for="items, idx in blocks" :key="idx">
                <div class="mb-3" v-if="items.show === true">
                    <div class="d-flex mt-4">
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div :class="`avatar-title bg-${items.class} rounded-circle fs-16 text-white`" >
                                <i :class="`${items.icon}`"></i>
                            </div>
                        </div>
                        <div :class="`flex-grow-1 overflow-hidden`" >
                            <p class="mb-1 fs-16">{{ $t(`${items.text}`) }} :</p>
                            <div class="ctext-wrap" style="display:flex">
                                <div class="ctext-wrap-content">
                                    <h6
                                        @click="this.$emit(item.event.name, item.event.value)"
                                        :class="
                                            `mb-0 fs-14` + 
                                            (items.textColor ? ' ' + items.textColor : '')
                                        "
                                    > {{ items.value ? items.value : '-' }}</h6>
                                </div>
                                <i
                                    v-if="items.copy.status === true" 
                                    class="ri-file-copy-2-fill fs-16 text-success btnCopy"  
                                    @click="
                                        this.$emit('copy', items.copy.value)
                                    "
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </b-card>
</template>

<script>

export default {
    props: ['blocks', 'title'],
    data() {
        return {

        }
    }
}
</script>

<!-- Example -->
<!-- 
    <div class="mb-3">
        <div class="d-flex mt-4">
            <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                    <i :class="`ri-hashtag`"></i>
                </div>
            </div>
            <div :class="`flex-grow-1 overflow-hidden`" >
                <p class="mb-1 fs-16">{{ $t('phone_number') }} :</p>
                <div class="ctext-wrap" style="display:flex">
                    <div class="ctext-wrap-content d-flex">
                        <h6 :class="`fs-14 mb-0 fw-normal copy `" @click="getClient(this.form)">{{ form.userPhone }}</h6>
                        <i class="ri-file-copy-2-fill fs-16 copybtn text-secondary" style="margin-left: 10px;" @click="copy(this.form.userPhone)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
-->