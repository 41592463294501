<template>
    <!-- Компонент для відображення повідомлень -->

    <!-- 
        Блок повідомлень, відображається якщо повідомлень більше 0
    -->
    <div 
        class="notification-actions mt-2 pb-1" 
        id="notification-actions" 
        style="display: block;" 
        v-if="this.messages.length > 0" 
        :key="componentKey"
    >
        <!-- Блок з кнопкою позначення вибраних повідомлень як прочитаних -->
        <div class="d-flex text-muted justify-content-center" v-if="readCheckbox.length > 0">
            <button type="button" class="btn btn-link link-danger p-0 ms-3" @click="readSelectNotice" >
              {{  $t('readNotice') }}
            </button>
        </div>

        <div style="margin-bottom: 100px;">

            <div v-for="(messages, key) in groupedMessages" :key="key">
                <div 
                    class="text-reset notification-item d-block dropdown-item copy border"
                >
                    <div class="d-flex" >

                        <div class="avatar-xs me-3" @click="open(messages[0], messages)">
                            <span class="avatar-title bg-soft-info text-info rounded-circle fs-16">
                                <i :class="`bx bx-message-square-add`"></i>
                            </span>
                        </div>

                        <div class="flex-1" @click="open(messages[0], messages)">

                            
                            <p class="mb-1 fw-semibold">
                                {{ messages.length > 1 ? $t('groupMsgsTicket') + " #" + key : nameWorker(messages[0].workerId) }}
                            </p>

                            
                            <p class="mb-1 noticeMsg" >
                                {{ messages.length > 1 ? $t('LastMessage') : $t('Message') }} : <span v-html="messages[0].messageTitle.slice(0, 100)"></span>
                            </p>

                            
                            <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                <span><i class="mdi mdi-clock-outline"></i> {{ messages[0].datetime }}</span>
                            </p>

                        </div>

                        <div class="px-2 fs-15 d-flex">
                            
                            <div class="form-check notification-check">
                                <input class="form-check-input" :checked="messages.every(el => this.readCheckbox.includes(el))" @change="updateSelectedMessages(messages)" type="checkbox" :value="messages" id="all-notification-check01">
                                <label class="form-check-label" for="all-notification-check01"></label>
                            </div>

                            <button 
                                v-if="readCheckbox.length < 1" 
                                @click="readMsg(messages)" 
                                :title="this.$t('readNotice')" 
                                type="button"
                                class="btn btn-soft-info btn-icon btn-sm fs-16 close-btn-email"
                            >
                                <i class="bx bxs-message-alt-check align-bottom"></i>
                            </button>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Блок для відображення, якщо немає повідомлень -->
    <div v-else>
        <div class="tab-pane fade p-4 active show" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">
            <div class="empty-notification-elem">
                <div class="text-center pb-5 mt-2">
                    <h6 class="fs-18 fw-semibold lh-base">{{ $t('YouhaveNoMessages') }}</h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Messages } from '@/API'; // клас для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних
import { nameWorker } from '@/usabilityScripts/getNameWorker'

let apiMessages = new Messages()

export default{
    props: ['typeClear'],
    data(){
        return{
            messages: [],
            groupedMessages: {},
            componentKey: 0,
            readCheckbox: [],
            selectedMessages: []
        }
    },
    created() {

        // Відписка та підписка на подію для отримання повідомлень
        this.eventBus.off('getMessages')
        this.eventBus.on('getMessages', status => {
            if(status.status == true) {
                this.getMsgs(status.scrollPos)
            }
        })

        // Відписка та підписка на подію для оновлення повідомлень
        this.eventBus.off('updateMessage')
        this.eventBus.on('updateMessage', data => {
            if (data.status == true) {
                this.getMsgs()
                this.alerts = this.alerts.reverse()
                this.eventBus.emit('updateMessageCount', {
                    status:true,
                    count: this.alerts.length
                })  
            }
        })
        
        this.getMsgs()
    },
    methods: {
        updateSelectedMessages(items) {
            console.log(items, items.every(el => this.selectedMessages.includes(el.id)))
            if(items.every(el => this.selectedMessages.includes(el.id))) {
                items.forEach(el => {
                    this.readCheckbox.splice(this.readCheckbox.findIndex(k => el.id == k), 1)
                    this.selectedMessages.splice(this.selectedMessages.findIndex(k => el.id == k), 1)
                })
            } else {
                items.forEach(el => {
                    this.readCheckbox.push(el.id)
                    this.selectedMessages.push(el.id)
                })
            }

            console.log(this.selectedMessages);
        },
        readAlert(item) {
            // Метод для позначення альерта як прочитаного

            let obj = {
                ids: [item.id]
            }

            apiAlerts.readNotices(obj).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('statusViewed'))
                    this.getAlerts()
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        getMsgs(scrollPos) {
            // Метод для отримання повідомлень

            this.messages = []
            apiMessages.getMessages().then(result => {
                if(result.status == 'done'){
                    this.messages = result.data.items.reverse();

                    // Створити об'єкт для групування повідомлень за тікетами або проектами
                    const groupedMessages = {};

                    this.messages.forEach(message => {
                    const key = message.ticketId || message.projectId; // Вибір ключа в залежності від наявності тікета або проекту
                    if (!groupedMessages[key]) {
                        groupedMessages[key] = [];
                    }
                    groupedMessages[key].push(message);
                    });

                    this.groupedMessages = groupedMessages;

                    storeS.readMsg = this.messages.filter(item => item.read == 0 && item.workerId != this.user.userid);
                    this.$emit('updateTitle');
                    this.$emit('updateMessage', result.data.items.length, scrollPos)
                    this.$emit('itemsAlerts', this.typeClear, this.messages)
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        readMsg(e) {
            // Метод для позначення повідомлення як прочитаного\
            let messagesIds = {ids: []}
            e.forEach(el => {
                messagesIds.ids.push(el.id)
            })
            // Відправлення запиту на позначення повідомлень як прочитаних
            apiMessages.readMessages(messagesIds).then(result => {
                if(result.status == 'done'){
                    this.showRead = false
                    this.$toast.success(this.$t('AllmessagesRead'));
                    this.messageCustom = 0
                    storeS.messages = 0
                    this.getMsgs()
                    this.$emit('updateTitle')
                    this.$emit('updateMessage')
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        open(item, messages) {
            // Метод для відкриття повідомлення
            this.$emit('open', item, messages)
        },
        clearAlert() {
            // Метод для очищення повідомлень 
            this.$emit('openAccept', this.typeClear, this.messages)
        },
        readSelectNotice(){
            // Метод для позначення вибраних повідомлень як прочитаних

            this.$emit('openAccept', this.typeClear, this.selectedMessages)
            this.readCheckbox = []
            this.selectedMessages = []
        },
        nameWorker(e) {
            // Метод для отримання імені працівник
            return nameWorker(e)
        },
    },
    computed: {
        user() {
            /*
                Повертає дані користувача з глобального стору.
            */
            return storeS.userbase
        }
    }
}
</script>

<style>
.canvNotice .onlineBl .tab-pane{
    max-height: 100%!important;
    overflow: auto;
}
.canvNotice .tab-content {
    height: 100vh;
}
p.mb-1.noticeMsg img {
    width: 100%;
    border-radius: 8px;
    opacity: 0.6;
}
p.mb-1.noticeMsg img:hover {
    transition: .3s;
    opacity: 1;
}
p.mb-1.noticeMsg p {
    max-width: 200px;
}
</style>