<template>
    <b-card>
        <div class="card-header">
            <h5 class="card-title mb-0"><b>Інформація</b></h5>
        </div>
        <div class="card-body">
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">Cпівробітник:</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content d-flex">
                                <h6 :class="`fs-14 mb-0 fw-normal copy `">{{ this.data.worker.workerName }}</h6>
                                <i class="ri-file-copy-2-fill fs-16 copybtn text-secondary" @click="copy(this.data.workerName)" style="margin-left: 10px;"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">Компанія:</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content d-flex">
                                <h6 :class="`fs-14 mb-0 fw-normal copy `">{{ this.data.companyId }}</h6>
                                <i class="ri-file-copy-2-fill fs-16 copybtn text-secondary" @click="copy(this.data.companyId)" style="margin-left: 10px;"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">Дата та час:</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content d-flex">
                                <h6 :class="`fs-14 mb-0 fw-normal copy `">{{ this.data.datetime }}</h6>
                                <i class="ri-file-copy-2-fill fs-16 copybtn text-secondary" @click="copy(this.data.datetime)" style="margin-left: 10px;"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
import {copy} from '@/usabilityScripts/copy.js'

export default{
    props: ['data'],
    data(){
        return {
            
        }
    },
    created(){
        
    },
    methods: {
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
        },
    }
}
</script>
