<template>
    <b-card>
        <div class="card-header">
            <h5 class="card-title mb-0"><b>Несинхронізовані документи</b></h5>
        </div>
        <div class="card-body">
            <div class="mb-3">
                <!-- Табличка айдішників -->
                <table class="table" v-if="this.data.unsyncDocuments.items.filter(items => items.syncAfterReport === false).length > 0">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <!-- <th>{{this.$t('Status')}}</th> -->
                            <th>{{this.$t('Sum')}}</th>
                            <th>{{this.$t('date_time')}}</th>
                            <th>{{this.$t('action')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item, i in this.data.unsyncDocuments.items.filter(items => items.syncAfterReport === false)" :key="i">
                            <th>{{ item.orderId }}</th>
                            <!-- <td>{{ this.mutateStatus(item.syncAfterReport) }}</td> -->
                            <td>{{ item.sum }}</td>
                            <td>{{ item.datetime }}</td>
                            <td>
                                <button 
                                    @click="syncCheck(item.orderId)"
                                    v-if="item.syncAfterReport === false" 
                                    class="btn btn-danger"
                                >
                                    {{ this.$t('sync') }}
                                </button>
                                <p v-else>
                                    {{ this.$t('Synchronized') }}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- Результатів нема -->
                <div v-else class="noresult" style="display: block;">

                    <div class="text-center" v-if="checks['024'] == false">
                        <h5 class="mt-2">{{ $t('SorryNoResultFound') }}</h5>
                        <p class="text-muted mb-0">{{ $t('WeveSearchedMoreThan2') }}</p>
                    </div>
                    <div class="text-center" v-else>
                        <img class="gallery-img img-fluid mx-auto" style="max-width: 30%;" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Naruto_Shiki_Fujin.svg/1200px-Naruto_Shiki_Fujin.svg.png" alt="">
                        <h5 class="mt-2">{{ $t('pashNotFound') }}</h5>
                        <p class="text-muted mb-0">{{ $t('pashNotFoundDesc') }}</p>
                    </div>

                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
import tablecustom from "@/components/globaltable/index"
import { Documents } from "@/API_Dash.js"
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
import { storeS } from '@/store'

let apiServe = new Documents()

export default{
    props: ['data'],
    data(){
        return {

        }
    },
    components: {
        tablecustom
    },
    created(){
        
    },
    methods: {
        // Мутейт статусів
        mutateStatus(status) {
            if(status === true) {
                return this.$t('Synchronized')
            } else {
                return this.$t('OutofSync')
            }
        },
        // Функція синхронізації чеків
        syncCheck(orderId) {
            apiServe.syncCheck(this.data.reportId, this.data.companyId, orderId).then(result => {
                if(result.data.status == 'done') {
                    console.log(result)
                    this.$toast.success(this.$t('Synchronized'))
                    this.$emit('getReport', this.data.reportId)
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка синхронізації чеків'; 
                    var statusRequest = "error";
                    var api = 'syncCheck';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/activity/checks/blocks/unSyncDocs.vue';
                    var params = JSON.stringify(
                        {
                            "orderId": orderId,
                            "reportId": this.data.reportId,
                            "companyId": this.data.companyId,
                        }
                    );
                    var response = result.status

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        }
    },
    computed: {
        checks(){
            return storeS.checks
        }
    }
}
</script>
