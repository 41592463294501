<template>
    <b-card>
        <div class="d-flex align-items-center border-bottom mb-3 pb-1 mt-1">

            <div class="flex-grow-1">
                <div class="mt-2 mb-3">
                    <!-- Заголовок для секції з загальними параметрами -->
                    <h5 class="card-title mb-0"><b>{{ $t('Чек-ліст') }}</b></h5>
                </div>
            </div>

            <div class="flex-shrink-0">
                <div class="text-muted">
                    <!-- Кнопка для видалення блоку -->
                    <button 
                        type="button" 
                        class="btn btn-danger btn-icon waves-effect waves-light" 
                        @click="removeItem"
                    >
                        <i class="ri-delete-bin-5-line"></i>
                    </button>
                </div>
            </div>

        </div>

        <b-row>
            <b-col lg="6">
                <div class="card bg-light">

                    <!-- Відображення кнопки для видалення блока, якщо блок активний -->
                    <div class="disabledBlock" v-if="preconditions.show" >
                        <button 
                            @click="preconditions.show = false"
                            type="button" 
                            class="btn btn-danger mt-3 fs-14 fw-semibold"
                        >Прибрати</button>
                    </div>

                    <!-- Загальна інформація про блок -->
                    <div class="card-body copy m-2" @click="preconditions.show = true">
                        <div class="d-flex align-items-center" >
                            <div class="flex-shrink-0" >
                                <i class="bx bx-question-mark display-6 text-muted"></i>
                            </div>
                            <div class="flex-grow-1 ms-3" >
                                <h3 class="mb-0 fw-semibold">Додати передумови</h3>
                                <span class="mt-1 fs-11 text-muted" style="display: block;">Передумови виконання. Наприклад, увійти в pos-термінал від співробітника</span>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="card bg-light">

                    <!-- Відображення кнопки для видалення блока, якщо блок активний -->
                    <div class="disabledBlock" v-if="inspection_conditions.show" >
                        <button 
                            @click="inspection_conditions.show = false"
                            type="button" 
                            class="btn btn-danger mt-3 fs-14 fw-semibold"
                        >Прибрати</button>
                    </div>

                    <!-- Загальна інформація про блок -->
                    <div class="card-body copy m-2" @click="inspection_conditions.show = true">
                        <div class="d-flex align-items-center" >
                            <div class="flex-shrink-0" >
                                <i class="bx bx-square-rounded display-6 text-muted"></i>
                            </div>
                            <div class="flex-grow-1 ms-3" >
                                <h3 class="mb-0 fw-semibold">Додати пункти перевірки</h3>
                                <span class="mt-1 fs-11 text-muted" style="display: block;">Пункти перевірки. Наприклад, проведення продажу з ціною в 0.01 грн.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>

        <div class="mt-2" v-if="preconditions.show">
            <h5>Вкажіть передумови</h5>

            <b-row>
                <b-col xl="6">
                    <div class="flex-grow-1 mb-2">
                        <div class="input-group">
                            <input 
                                type="text"
                                v-model="preconditions.text" 
                                @keydown.enter="addElement"
                                class="form-control w-50"  
                            >
                            <button @click="addElement" class="btn btn-success" type="button" ><i class="ri-add-line"></i></button>
                        </div>
                    </div>
                </b-col>

                <div class="border mt-3 border-dashed" ></div>

                <h5 class="fs-14 mb-3 mt-3 text-muted fw-semibold">Передумови</h5>

                <b-col lg="12">
                    
                    <div >
                        <p class="text-muted">QA-менеджер має виконати передумови нижче, перед початком виконання пунктів перевірки.</p>
                        
                        <div class="live-preview" >
                            <ul class="list-group">
                                <template
                                    v-for="item in form.preconditions" :key="item"
                                >
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        {{ item.item }}
                                        <!-- <span class="badge bg-success">High</span> -->
                                        <div class="d-flex me-2">
                                            <button class="copy btn btn-soft-info btn-icon" style="margin-right: 5px;"><i class="ri-edit-2-line"></i></button>
                                            <button class="copy btn btn-soft-danger btn-icon"><i class="ri-delete-bin-line"></i></button>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </b-col>
            </b-row>

        </div>

        <div class="mt-2" v-if="inspection_conditions.show">

            <b-row>
                <div class="border mt-3 border-dashed" ></div>

                <h5 class="fs-14 mb-3 mt-3 text-muted fw-semibold">Пункти перевірки</h5>

                <b-col lg="12">
                    
                    <div >
                        <p class="text-muted">QA-менеджер має виконати передумови нижче, перед початком виконання пунктів перевірки.</p>
                        
                        <h5>Вкажіть пункти перевірки</h5>

                        <b-col xl="6">
                            <div class="flex-grow-1 mb-2">
                                <div class="input-group">
                                    <input 
                                        type="text"
                                        v-model="inspection_conditions.text" 
                                        @keydown.enter="addElement"
                                        class="form-control w-50"  
                                    >
                                    <button @click="addElement" class="btn btn-success" type="button" ><i class="ri-add-line"></i></button>
                                </div>
                            </div>
                        </b-col>

                        <div class="live-preview" >
                            <ul class="list-group">
                                <template
                                    v-for="item in form.inspection_conditions" :key="item"
                                >
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        {{ item.item }}
                                        <!-- <span class="badge bg-success">High</span> -->
                                        <div class="d-flex me-2">
                                            <button class="copy btn btn-soft-info btn-icon" style="margin-right: 5px;"><i class="ri-edit-2-line"></i></button>
                                            <button class="copy btn btn-soft-danger btn-icon"><i class="ri-delete-bin-line"></i></button>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </b-col>
            </b-row>

        </div>

    </b-card>
    
</template>

<script>
export default{
    props: ['type', 'activeBlocks'],
    data(){
        return{
            form: {
                tasks: [],
                preconditions: [],
                inspection_conditions: []
            },
            item: {
                taskName: '',
                checked: 0
            },
            inspection_conditions: {
                show: false,
                text: ''
            },
            preconditions: {
                show: false,
                text: ''
            }
        }
    },
    methods: {
        add(e, index) {;
            console.log("e", e);
            if (this.form.tasks.length > 0) {
                this.form.tasks.push({
                    taskName: e.taskName,
                    checked: 0
                });
            } else {
                this.form.tasks.splice(index + 1, 0, {
                    taskName: e.taskName,
                    checked: 0
                });
            }
            this.item.taskName = "";
        },
        removeItem(){
            // Метод для видалення поточного блоку
            this.activeBlocks.forEach(element => {
                if(element.type == this.type ){
                    element.status = false;
                    this.$emit('saveBlock', this.activeBlocks)
                }
            });
        },
        addElement(){
            // Додати елемент

            console.log("preconditions");

            this.form.preconditions.push({
                item: this.preconditions.text
            });

            // if (this.form.preconditions.length > 0) {
            //     this.form.preconditions.push({
            //         item: preconditions.text
            //     });
            // } else {
            //     this.form.preconditions.splice(index + 1, 0, {
            //         taskName: preconditions.text,
            //     });
            // }
            this.preconditions.text = "";

        }
    }
}
</script>