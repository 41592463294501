<template>
    <!-- Компонент меню повідомлень та сповіщень -->

    <!-- Кнопка для відкриття меню повідомлень -->
    <button 
        class="btn btn-ghost-secondary btn-icon btn-sm fs-18" 
        type="button" 
        id="dropdownMenuClickableInside" 
        data-bs-toggle="offcanvas"
        :data-bs-target="'#offcanvasNotice'"
        aria-controls="offcanvasNotice"
        @click="trick"
    >
        <i class="bx bx-bell fs-22"></i>
        <!-- Кількість непрочитаних повідомлень -->
        <span :class="`position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-warning`">
            {{ this.alertCustom + this.messagesCustom }}
        </span>
    </button>

    <!-- Меню повідомлень -->
    <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        :id="'offcanvasNotice'"
        aria-labelledby="offcanvasNoticeLabel"
    >
        <div class="offcanvas-body canvNotice profile-offcanvas p-0">
            <!-- Верхній блок з кнопками та іншими елементами -->
            <div :class="`p-1 pb-4 pt-0 ${checkDark == 'dark' ? '' : 'bg-warning'}`">
                <div class="team-settings">
                    <div class="row g-0 notifButtons">
                        <!-- Кнопка закриття меню -->
                        <div class="col">
                            <div class="btn nav-btn">
                                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                        </div>
                        <!-- Додаткові кнопки (dropdown) -->
                        <div class="col-auto">
                            <div class="user-chat-nav d-flex">
                                <div class="dropdown">
                                    <a class="btn nav-btn" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="ri-more-2-fill"></i>
                                    </a>
                                    <!-- Список елементів -->
                                    <ul class="dropdown-menu dropdown-menu-end">

                                        <!-- Позначити всі повідомлень прочитаними -->
                                        <li v-if="(currentTab == 0 && alerts.length > 0) || (currentTab == 1 && messages.length > 0)">
                                            <a class="dropdown-item copy" @click="showRead = true">
                                                <i class="ri-inbox-archive-line align-bottom text-muted me-2"></i>{{ $t('ReadAllMsgs') }}
                                            </a>
                                        </li>

                                        <!-- Вимкнення або увімкнення звукових сповіщень -->
                                        <li>
                                            <a class="dropdown-item copy" @click="mute">
                                                <i :class="`${statusMute == '1' ? 'ri-notification-off-line' : 'ri-notification-3-line' } align-bottom text-muted me-2`"></i>
                                                {{ statusMute == '1' ? $t('Mute') : $t('Unmute') }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Вкладки з повідомленнями, сповіщеннями, чатом і т.д. -->
            <b-tabs 
                pills 
                content-class="mt-0 onlineBl"
                :nav-class="`nav nav-tabs dropdown-tabs nav-tabs-custom ${checkDark == 'dark' ? '' : 'bg-warning'} onlineBl px-2`"
                nav-item-class="bg-white"
                active-nav-item-class="bg-white"
            >
                <!-- Вкладка зі сповіщеннями -->
                <b-tab :class="'notificationTab'" @click="isOpenNotific = true; currentTab = 0">

                    <!-- Вміст вкладки (сповіщення) -->
                    <template v-if="isOpenNotific == true">
                        <notifications 
                            @openAccept="openAccept"
                            @open=open 
                            @updateTitle="this.updateTitle()" 
                            :typeClear="'alerts'"
                            @updateNotifications="updateNotifications"
                            @itemsAlerts="itemsAlerts"
                        ></notifications>
                    </template>

                    <!-- Заголовок вкладки та кількість непрочитаних сповіщень -->
                    <template #title>
                        <span @click="this.getAlerts()">{{ $t('Alerts') }} <span v-if="alertCustom > 0" class="badge badge-soft-secondary fs-12">{{ alertCustom }}</span></span> 
                    </template>
                </b-tab>

                <!-- Вкладка з повідомленнями -->
                <b-tab :class="'messagesTab'" @click="isOpenMsg = true; currentTab = 1">

                    <!-- Вміст вкладки (повідомлення) -->
                    <template v-if="isOpenMsg == true">
                        <messages 
                            @openAccept="openAccept" 
                            @open=open 
                            @updateTitle="this.updateTitle()" 
                            :typeClear="'messages'" 
                            @updateMessage="updateMessage"
                            @itemsAlerts="itemsAlerts"
                        ></messages>
                    </template>

                    <!-- Заголовок вкладки та кількість непрочитаних повідомлень -->
                    <template #title>
                        <span @click="this.getMessages()">{{ $t('Messages') }} <span v-if="messagesCustom > 0" class="badge badge-soft-secondary fs-12">{{ messagesCustom }}</span></span> 
                    </template>

                </b-tab>

                <!-- Вкладка з чатом Crisp (якщо є відповідні дозволи або посада) -->
                <b-tab @click="isOpenCrisp = true; currentTab = 2" v-if="this.perms[2000] || this.user.workerJob == 'supportmanager'">

                    <!-- Вміст вкладки (чат Crisp) -->
                    <template v-if="isOpenCrisp == true">
                        <crispchat 
                            :obj="crispChat"
                        />
                    </template>

                    <template #title>
                        <span @click="this.getChatCrisp()">{{ $t('Crisp') }}</span> 
                    </template>
                </b-tab>
            </b-tabs>
        </div>
    </div>


    <!-- Картка тікету -->
    <viewTicket 
        v-for="item in showModalTicket" 
        @close="closeModal('ticket')" 
        @getDataMessages="this.getMessages()"
        :obj="item.data" 
        :activeTab="item.activeTab"
        :messages="this.messagesFromGroup"
    />

    <!-- Картка проекту -->
    <viewProject 
        v-if="showModalProject" 
        @close="closeModal('project')" 
        :obj="objCard" 
        :activeTab="activeTab"
    />

    <!-- Картка запиту на відгул -->
    <viewHoliday 
        v-if="showModalHoliday" 
        @close="closeModal('holiday')" 
        :obj="objCard" 
    />

    <!-- Картка штрафу -->
    <viewFine
        v-if="showModalFine == true"
        @close="closeModal('fine')" 
        :obj="objCard" 
    />

    <!-- Картка заявки -->
    <viewRequest
        v-if="showModalRequest == true"
        @close="closeModal('request')" 
        :shortapplic="objCard" 
    />

    <!-- Картка перерви -->
    <viewBreak
        v-if="showModalBreak == true"
        @close="closeModal('break')" 
        :objCard="objCard" 
    />

    <!-- Картка презентації -->
    <viewPresent
        v-if="showModalPresent == true"
        @close="closeModal('present')" 
        :shortapplic="objCard" 
    />
    
    <!-- Картка завдання -->
    <viewTask
        v-if="showModalTask == true"
        @close="closeModal('task')" 
        :shortapplic="objCard" 
    />
  
    <!-- Компонент для підтвердження прочитання повідомлень -->
    <acceptRead 
        v-if="showRead" 
        @close="showRead = false" 
        @clearAlerts="clearAlerts" 
        :type="typeClear" 
        :messages="this.messages" 
        :alerts="this.alerts">
    </acceptRead>

    <!-- Холодний дзвінок -->
    <viewColdCall
        v-if="showModalColdRequest == true"
        @close="closeModal('coldRequest')" 
        :shortapplic="objCard"
    >
    </viewColdCall>

    <!-- Моніторинг -->
    <viewMonitoring
        v-if="showModalMonitoring == true"
        @close="closeModal('monitoring')" 
        :obj="objCard"
        :blockEdit="true"
    ></viewMonitoring>

</template>

<script>
import acceptRead from './acceptRead.vue'
import messages from './messages'
import notifications from './notifications.vue'
import crispchat from './crispchat.vue'
import viewTicket from '@/views/newtickets/view/index.vue'
import viewProject from '@/views/projects/view/index.vue'
import viewHoliday from '@/views/statistics/holidays/view'
import viewFine from '@/views/statistics/fines/view'
import viewRequest from '@/views/application/view/viewapplic'
import viewBreak from '@/views/users/view/formright/breaks/view'
import viewPresent from '@/views/presentations/view/index'
import viewMonitoring from '../../../views/monitoring/view/index.vue'
import viewTask from '@/views/tasks/view/viewtask'
import { Tickets, 
        Applications, 
        Projects, 
        Holidays, 
        Fines, 
        Breaks, 
        Presentations, 
        Tasks, 
        Messages, 
        Notices, 
        Dumps,
        OtherCalls,
        Crisp,
        ColdCalls,
        Monitoring
    } from '@/API'
import { storeS } from '@/store';
import { webviewCheck } from '@/usabilityScripts/webViewCheck.js'
import viewColdCall from '@/views/calls/cold/view/viewapplic.vue'

let apiServe = new Tickets();
let apiApplications = new Applications();
let apiProjects = new Projects();
let apiHolidays = new Holidays();
let apiFines = new Fines();
let apiBreaks = new Breaks();
let apiPresentations = new Presentations();
let apiTasks = new Tasks();
let apiMessages = new Messages();
let apiOther = new OtherCalls()
let apiAlerts = new Notices()
let apiDumps = new Dumps()
let apiCrisp = new Crisp();
let apiCold = new ColdCalls()
let apiMonitoring = new Monitoring()

export default{
    components: {
        messages,
        notifications,
        acceptRead,
        viewTicket,
        viewProject,
        viewHoliday,
        viewFine,
        viewRequest,
        viewBreak,
        viewPresent,
        viewTask,
        crispchat,
        viewColdCall,
        viewMonitoring
    },
    data(){
        return{
            showModalMonitoring: false, // показати картку моніторингу
            messagesCustom: 0, // Кількість непрочитаних повідомлень
            alertCustom: 0, // Кількість непрочитаних сповіщень
            obj: '',
            objCard: '', // Об'єкт для збереження даних про події
            alerts: [], // Масиви для повідомлень
            messages: [], // Масиви для сповіщень
            showRead: false,  // Прапорець для відображення модального вікна підтвердження прочитання
            allNotification: [], // Масив всіх повідомлень та сповіщень
            // selectedAlerts: [],
            // selectedMessages: [],
            // Прапорці для відображення модальних вікон різних подій
            showModalTicket: [],
            showModalProject: false,
            showModalHoliday: false,
            showModalFine: false,
            showModalRequest: false,
            showModalBreak: false,
            showModalPresent: false,
            showModalTask: false,
            activeTab: 'main', // Активна вкладка для модальних вікон
            count: 0, 
            interval: '',
            typeClear: '', // Тип події для підтвердження прочитання
            // Прапорці для відображення вкладок з повідомленнями та сповіщеннями
            isOpenNotific: false,
            isOpenMsg: false,
            isOpenCrisp: false,
            currentTab: 0, // Поточна вкладка (0 - сповіщення, 1 - повідомлення, 2 - чат Crisp)
            statusMute: '1', // 0 - вимкнути звук
            checkDark: '', // Тема (світла або темна)
            crispChat: '', // Об'єкт для чату Crisp
            showModalColdRequest: false,
            messagesFromGroup: []
        }
    },
    created() {
        // Ініціалізація кількостей непрочитаних повідомлень та сповіщень при створенні компоненту
        this.alertCustom = this.alertsCount
        this.messagesCustom = this.messagesCount

        // Оновлення заголовку документа
        this.updateTitle()
        
        // Отримання налаштувань теми з локального сховища
        this.checkDark = localStorage.getItem('nightTheme');
    },
    methods: {
        androidFixMono () {
            // Метод для виправлення відображення інтерфейсу
            if(webviewCheck() == 'android_webview' || webviewCheck() == 'ios_webview') {
                document.querySelector('.notifButtons').classList.add('fixWindow')
            } else {
                document.querySelector('.notifButtons').classList.remove('fixWindow')
            }
        },
        getMessages() {
            // Метод для отримання повідомлень

            this.eventBus.emit('getMessages', {
                status: true,
                scrollPos: document.querySelector(".messagesTab").scrollTop
            })
        },
        getChatCrisp(){
            // Метод для отримання чату Crisp

            apiCrisp.getActiveConversations().then(result => {
                if(result.status == 'done'){
                    this.crispChat = result.data
                }
            })
        },
        getAlerts() {
            // Метод для отримання сповіщень

            this.eventBus.emit('getAlerts', {
                status: true
            })
        },
        updateNotifications(e) {
            // Метод для оновлення масиву сповіщень

            storeS.alerts = e
            this.alertCustom = e
        },
        updateMessage(e, scrollPos) {
            // Метод для оновлення масиву повідомлень

            setTimeout(() => {
                document.querySelector('.messagesTab').scrollTop = scrollPos
                console.log('updatePos')
            }, 200)
            storeS.messages = e
            this.messagesCustom = e
        },
        openAccept(e, items) {
            // Метод для відкриття вікна підтвердження прочитання

            // Встановлення типу події для підтвердження прочитання
            this.typeClear = e; 

            // Визначення масиву повідомлень або сповіщень для підтвердження
            if(e == 'alerts') {
                this.alerts = items;
            } else {
                this.messages = items
            };
            
            this.showRead = true;
        },
        itemsAlerts(e, items){
            // Метод для отримання повідомлень або сповіщень для відображення
            
            this.typeClear = e; 
            if(e == 'alerts') {
                this.alerts = items;
            } else {
                this.messages = items
            }
        },
        trick() {
            // Метод для відображення вкладок та отримання відповідних подій при зміні вкладки

            if(this.currentTab == 0){
                this.isOpenNotific = true
                this.getAlerts();
            } else {
                this.getMessages();
            }
        },
        open(e, messages) {
            // Метод для обробки подій та відкриття вікна залежно від типу події

            // Визначення типу події
            var type = e.noticeData ? e.noticeData.type : e.projectId ? 'messageProject' : e.ticketId ? 'messageTicket' : '-'
            console.log(type, messages)

            // Виклик відповідного методу для обробки події
            this.visibilityCanv();

            if(type != undefined) {
                switch (type) {
                    case "monitoring":
                        apiMonitoring.getMonitoring(e.noticeData.monitoringId).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalMonitoring = true;
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case "coldCall":
                        apiCold.getCall(e.noticeData.requestId).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalRequest = true;
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case 'requestbycall':
                        apiOther.getRequest(e.noticeData.requestId).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalRequest = true;
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case 'ticket':
                        apiServe.getTicket(e.noticeData.ticketId).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.activeTab = 'main';
                                this.showModalTicket.push({
                                    data: this.objCard,
                                    activeTab: this.activeTab
                                });
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case 'messageTicket':
                        apiServe.getTicket(e.ticketId).then(result => {
                            if (result.status == 'done') {
                                this.messagesFromGroup = messages
                                this.objCard = result.data;
                                this.activeTab = 'chat';
                                this.showModalTicket.push({
                                    data: this.objCard,
                                    activeTab: this.activeTab
                                });
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case 'messageProject':
                        apiProjects.getCardProjects(e.projectId).then(result => {
                            if (result.status === 'done') {
                                this.objCard = result.data
                                this.activeTab = 'chat';
                                this.showModalProject = true
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case 'holiday':
                        apiHolidays.getRequest(e.noticeData.holidayId).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalHoliday = true;
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case 'fine':
                        apiFines.getFine(e.noticeData.fineId).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalFine = true;
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case 'request':
                        apiApplications.getRequest(e.noticeData.requestId).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalRequest = true;
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case 'break':
                        apiBreaks.getBreak(e.noticeData.breakId).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalBreak = true;
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case 'presentation':
                        apiPresentations.getCard(e.noticeData.presentationId).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalPresent = true;
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case 'task':
                        apiTasks.getTask(e.noticeData.taskId).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalTask = true;
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    default:
                    break;
                }
            } else {
                switch(e.noticeTitle) {
                    case 'getRequest':
                        apiApplications.getRequest(e.noticeData.requestId).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalRequest = true;
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case 'addNewRequest':
                        apiApplications.getRequest(Number(e.noticeData.requestId)).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalRequest = true;
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case 'createdTicket':
                        apiServe.getTicket(Number(e.noticeData.ticketId)).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalTicket.push({
                                    data: this.objCard,
                                    activeTab: 'main'
                                });
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case 'exitAssignedTicket':
                        apiServe.getTicket(Number(e.noticeData.ticketId)).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalTicket.push({
                                    data: this.objCard,
                                    activeTab: 'main'
                                });
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case 'decidedTicket':
                        apiServe.getTicket(Number(e.noticeData.ticketId)).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalTicket.push({
                                    data: this.objCard,
                                    activeTab: 'main'
                                });
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case "sendMessageInTicketChat":
                        apiServe.getTicket(Number(e.noticeData.ticketId)).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalTicket.push({
                                    data: this.objCard,
                                    activeTab: 'main'
                                });
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case "inJobTicketManually":
                        apiServe.getTicket(Number(e.noticeData.ticketId)).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalTicket.push({
                                    data: this.objCard,
                                    activeTab: 'main'
                                });
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case "exitTicket":
                        apiServe.getTicket(Number(e.noticeData.ticketId)).then(result => {
                            if (result.status == 'done') {
                                this.objCard = result.data;
                                this.showModalTicket.push({
                                    data: this.objCard,
                                    activeTab: 'main'
                                });
                            } else {
                                this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case "resumeTicket":
                        apiServe.getTicket(Number(e.noticeData.ticketId)).then(result => {
                            if (result.status == 'done') {
                            this.objCard = result.data;
                                this.showModalTicket.push({
                                    data: this.objCard,
                                    activeTab: 'main'
                                });
                            } else {
                            this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case "stopTicket":
                        apiServe.getTicket(Number(e.noticeData.ticketId)).then(result => {
                            if (result.status == 'done') {
                            this.objCard = result.data;
                                this.showModalTicket.push({
                                    data: this.objCard,
                                    activeTab: 'main'
                                });
                            } else {
                            this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case "waitReleaseTicket": 
                        apiServe.getTicket(Number(e.noticeData.ticketId)).then(result => {
                            if (result.status == 'done') {
                            this.objCard = result.data;
                                this.showModalTicket.push({
                                    data: this.objCard,
                                    activeTab: 'main'
                                });
                            } else {
                            this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case 'exitTicket':
                        apiServe.getTicket(Number(e.noticeData.ticketId)).then(result => {
                            if (result.status == 'done') {
                            this.objCard = result.data;
                                this.showModalTicket.push({
                                    data: this.objCard,
                                    activeTab: 'main'
                                });
                            } else {
                            this.$toast.error(this.$t('err'));
                            }
                        })
                    break;
                    case 'addDump':
                    window.location.href = '/calls/dumps'
                    break;
                }
            }
        },
        closeModal(e){
            // Метод для закриття модальних вікон

            var url = new URL(window.location.href);
            var searchPath = url.pathname;
            this.visibilityCanv();
            switch (e) {
                case 'coldRequest':
                    this.showModalColdRequest = false
                break;
                case 'monitoring':
                    this.showModalMonitoring = false
                break;
                case 'ticket':
                    this.showModalTicket.pop();
                    if(searchPath == '/newtickets/all'){
                        this.eventBus.emit('saveTickets', true)
                    }
                break;
                case 'project':
                    this.showModalProject = false;
                    if(searchPath == '/projects/all'){
                        this.eventBus.emit('saveProject', true)
                    }
                break;
                case 'holiday':
                    this.showModalHoliday = false;
                    if(searchPath == '/statistics/holidays'){
                        this.eventBus.emit('saveHolidays', true)
                    }
                break;
                case 'fine':
                    this.showModalFine = false;
                    if(searchPath == '/statistics/fines'){
                        this.eventBus.emit('saveFines', true)
                    }
                break;
                case 'request':
                    this.showModalRequest = false;
                    if(searchPath == '/calls/all/'){
                        this.eventBus.emit('saveApplic', true)
                    }
                break;
                case 'break':
                    this.showModalBreak = false;
                break;
                case 'present':
                    this.showModalPresent = false;
                    if(searchPath == '/presentations'){
                        this.eventBus.emit('updatePresentation', true)
                    }
                break;
                case 'task':
                    this.showModalTask = false;
                    if(searchPath == '/tasks/all'){
                        this.eventBus.emit('saveTask', true)
                    }
                break;
            
                default:
                break;
            }
        },
        visibilityCanv(){
            // Метод для зміни видимості елементів на сторінці

            //костиль для відкриття модалки та змоги користуватись інпатом
            var doc = document.getElementById('offcanvasNotice');
            if(doc.style.visibility == 'visible'){
                doc.style.visibility = 'hidden'
            } else if(doc.style.visibility == 'hidden'){
                doc.style.visibility = 'visible'
            }
            
        },
        updateTitle(){
            // Метод для оновлення заголовку в вкладці браузера

            this.checkDark = localStorage.getItem('nightTheme');
            var c = 0
            var alerts = storeS.alerts
            var messages = storeS.messages
            if(alerts != 0 || messages != 0){
                const fav = document.querySelector('link[rel="icon"]')
                fav.href = "/red_not.ico";
                let sum = (messages + alerts)
                let potentialString = this.$t('YouHaveNotRead') + ' ' + sum + ' ' + this.$t('notifications')
                if(document.readyState == 'interactive' || document.readyState == 'complete' && potentialString.length<20){
                setTimeout(() => 
                    document.title = this.$t('YouHaveNotRead') + ' ' + sum+ ' ' + this.$t('notifications'), 
                2000);
                } else {
                document.title = this.$t('YouHaveNotRead') + ' ' + sum + ' ' + this.$t('notifications')
                this.interval = setInterval(function() {
                    c++
                    if(c+20 <= potentialString.length) {
                    document.title = potentialString.substring(c, c+19);
                    } else if (c+20 >= potentialString.length && c+20 < potentialString.length*2) {
                    document.title = potentialString.substring(c, c+39) + ' ' + potentialString.substring(c-potentialString.length, (c-potentialString.length)+20);
                    } else {
                    c=0
                    }
                }, 200)
                }
            } else {
                clearInterval(this.interval)
                document.title = ''
                const fav = document.querySelector('link[rel="icon"]')
                fav.href = "/favicon.ico";
                if(document.readyState == 'interactive' || document.readyState == 'complete'){
                setTimeout(() => 
                    document.title = this.$route.meta.title + ' | ' + 'SkyInside', 
                1000);
                }
            }
        },
        saveAlert(e) {
            // Метод для збереження альертів або повідомлень в локальне сховище

            let parsed = "";
            switch (e) {
                case 'alerts':
                // збереження альортів в локалстор
                    parsed = JSON.stringify(this.alerts);
                    localStorage.setItem('alerts', parsed);
                break;
                case 'messages':
                // збереження альортів в локалстор
                    parsed = JSON.stringify(this.messages);
                    localStorage.setItem('messages', parsed);
                break;

                default:
                break;
            }
        },
        clearAlerts(e, items) {
            // Метод для підтвердження прочитання альертів чи повідомлень

            this.typeClear = e
            console.log(e, items)
            // Видалення всіх альертів чи повідомлень з локального сховища
            switch (this.typeClear) {
                case 'messages':
                    this.messages = items
                    let messagesIds = {ids: []}
                    items.forEach(el => {
                        if(el.id) {
                            messagesIds.ids.push(el.id)
                        } else {
                            messagesIds.ids.push(el)
                        }
                    })
                    // Відправлення запиту на позначення повідомлень як прочитаних
                    apiMessages.readMessages(messagesIds).then(result => {
                        if(result.status == 'done'){
                            this.showRead = false
                            this.$toast.success(this.$t('AllmessagesRead'));
                            this.messageCustom = 0
                            storeS.messages = 0
                            this.updateTitle();
                            this.getMessages()
                        } else {
                            this.$toast.error(this.$t('err'))
                        }
                    })
                break;
                case 'alerts':
                    this.alerts = items
                    var arr = [];
                    for(var item in this.alerts){
                        arr.push(this.alerts[item].id)
                    }
                    this.showRead = false
                    let obj = {ids: arr}

                    // Відправлення запиту на позначення сповіщень як прочитаних
                    apiAlerts.readNotices(obj).then(result => {
                        if(result.status == 'done'){
                            this.showRead = false
                            this.$toast.success(this.$t('AllmessagesRead'));
                            this.alertCustom = 0
                            storeS.alerts = 0
                            this.updateTitle();
                            this.getAlerts()
                        } else {
                            this.$toast.error(this.$t('err'))
                        }
                    })
                break;

                default:
                break;
            }
            
            // Збереження змін у локальному сховищі
            this.saveAlert(this.typeClear);
        },
        mute(){
            // Метод для вмикання/вимикання звукових сповіщень
            this.statusMute = this.statusMute == '1' ? '0' : '1'

            localStorage.setItem('statusMute', this.statusMute)
            this.eventBus.emit('changeStatusMute', {
                status: true
            })
        }
    },
    mounted(){
        // Виклик методу для виправлення відображення кнопок на Android-пристроях
        this.androidFixMono()

        // Зчитування статусу вимкнення/увімкнення звукових сповіщень з локального сховища
        if (localStorage.getItem('statusMute')) {
            this.statusMute = localStorage.getItem('statusMute')
        }

        // Відписка та підписка на подію для оновлення кількості повідомлень
        this.eventBus.off('updateMessageCount')
        this.eventBus.on('updateMessageCount', data => {
            if (data.status == true) { 
                this.messagesCustom = data.counts
                this.saveAlert('messages');
            }
        })

        // Відписка та підписка на подію на відкриття чату тікета
        this.eventBus.off('openChatTicket')
        this.eventBus.on('openChatTicket', data => {
            if (data.status == true) { 
                // this.messagesCustom = data.counts
                // this.saveAlert('messages');
                this.open(data)
            }
        })

         // Відписка та підписка на подію для оновлення кількості сповіщень
        this.eventBus.off('updateAlertsCount')
        this.eventBus.on('updateAlertsCount', data => {
            if (data.status == true) {
                this.alertsCustom = data.count
                this.saveAlert('alerts');
            }
        })

        // Зчитування з локального сховища та ініціалізація масивів повідомлень та сповіщень
        if (localStorage.getItem('alerts')) {
            try {
                this.alerts = JSON.parse(localStorage.getItem('alerts'));
                for (var item in this.alerts) {
                this.allNotification.push(this.alerts[item])
                }
                this.alerts = this.alerts.reverse();
            } catch (e) {
                // localStorage.removeItem('alerts');
            }
        }
        if (localStorage.getItem('messages')) {
            try {
                this.messages = JSON.parse(localStorage.getItem('messages'));
                for (var item in this.messages) {
                this.allNotification.push(this.messages[item])
                }
                this.messages = this.messages.reverse();
            } catch (e) {
                // localStorage.removeItem('messages');
            }
        }  
    },
    computed: {
        checks() {
            return storeS.checks
        },
        perms() {
            return storeS.perms
        },
        workers() {
            return storeS.workers
        },
        user() {
            return storeS.userbase
        },
        messagesCount() {
            return storeS.messages
        },
        alertsCount() {
            return storeS.alerts
        }
    }
}
</script>

<style scoped>
.offcanvas-end{
    border-left: none!important;;
}
.canvNotice {
    overflow: hidden!important;;
}
.ql-syntax {
    display: none!important;;
}
@media (orientation: portrait) {
  .fixWindow {
    padding-top: 50px !important;
  }
}

.nav {
    overflow: hidden !important;
}
</style>