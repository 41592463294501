<template>
    
    <!-- 
        Таб Пристрої
    -->

    <globalTable 
        :columns="columns" 
        :rows="filteredItems" 
        @search="searchB" 
        :createdform="false"
        @open="open"
    />

    <adddevice 
        v-if="showdevice == true" 
        @close="this.showdevice = false" 
        @removeDeivce="removeDeivce"
        :typeAdd="'appendType'"
        :itemdevice="itemdevice"
        :perm="this.permToEdit"
        :editform="false"
    />

</template>



<script>
import globalTable from '@/components/globaltable/index'; // компонент таблиці
import adddevice from '@/components/ticketdeviceblock/index.vue' // компонент девайсу
import { mutateStatusChecklist } from '@/usabilityScripts/globalMutate'

export default {
    props: ['objCard'],
    components: {
        globalTable,
        adddevice
    },
    data() {
        return {
            form: '',
            showNoteCL: false, // прапорець відображення нотатки
            listStatusChecklist: [ // статуси до пунктів чек листа
                { value: 'passed', text: 'Успішно' },
                { value: 'failed', text: 'Не вдалося' },
                { value: 'blocked', text: 'Блокуючий' },
                { value: 'in_progress', text: 'В роботі' },
                { value: 'not_run', text: 'Не перевірено' },
                { value: 'skipped', text: 'Пропущено' },
            ],
            showdevice: false,
            modaldevice: false,
            search: '',
            rows: [],
            itemdevice: '',
            columns: [
                {
                    name: "ID",
                    text: "id",
                    align: "left",
                    status: false
                },
                {
                    name: this.$t("Point_name"),
                    text: "device",
                    align: "left",
                    status: true,
                    mutate: (item) => item.name ? item.name : '-'
                },
                {
                    name: this.$t("type"),
                    text: "device",
                    align: "left",
                    status: true,
                    mutate: (item) => item.type ? item.type : '-'
                },
                {
                    name: this.$t("manufacturer"),
                    text: "device",
                    align: "left",
                    status: false,
                    mutate: (item) => item.manufacturer ? item.manufacturer : '-'
                },
                {
                    name: this.$t("bank_terminal_model"),
                    text: "device",
                    align: "left",
                    status: true,
                    mutate: (item) => item.model ? item.model : '-'
                },
                {
                    name: this.$t("OS"),
                    text: "device",
                    align: "left",
                    status: true,
                    mutate: (item) => item.os ? item.os : '-'
                },
                {
                    name: this.$t("Protocol"),
                    text: "device",
                    align: "left",
                    status: true,
                    mutate: (item) => item.protocol ? item.protocol : '-'
                }
            ]
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.objCard
            this.rows = this.objCard.devices
        },
        open(e){
            this.itemdevice = e
            this.permToEdit = true
            this.showdevice = true
        },
        mutateStatusChecklist(e){
            // мутейт статусу пункту чек листа
            return mutateStatusChecklist(e)
        },
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.rows)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.id.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ;
                })
        },
    }
}
</script>