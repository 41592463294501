<template>

    <!-- Блок тестування в тікетах -->

    <div class="card" >
        <div class="card-header align-items-center d-flex border-bottom-dashed mb-2" >
            <h6 class="card-title mb-0 flex-grow-1">Тестування</h6>
            <div class="flex-shrink-0" >
                <button 
                    @click="showCreatedBox = true"
                    type="button" 
                    class="btn btn-soft-info btn-sm"
                >
                    <i class="ri-add-line me-1 align-bottom"></i>Додати
                </button>
            </div>
        </div>
        <div class="card-body" >

            <template v-if="formTest.testing.length > 0" >
                <ul class="list-unstyled vstack gap-3 mb-0" >
                    <li class="click" v-for="item in formTest.testing" :key="item" @click="open(item)">
                        <div class="d-flex align-items-center" >
                            <div class="flex-shrink-0 me-3" ><span class="fs-14 badge bg-info">#{{ item.id }}</span></div>
                            <div class="flex-grow-1" ><h5 class="fs-14 mb-0">{{ item.title }}</h5></div>
                        </div>
                    </li>
                </ul>
            </template>

            <template v-else>
                <h4 class="card-title fw-semibold">Додати тестування</h4>
                <p class="card-text text-muted">Цей тікет ще не проходив етап тестування</p>
                <button @click="showCreatedBox = true" class="btn btn-success">Додати</button>
            </template>
            
        </div>
    </div>

    <!-- 
        Вікно створення картки завдання
        - для відкриття застосовується перевірка значення showCreatedBox
        - @close - подія, яка спрацьовує при закритті картки
    -->
    <createBox 
        v-if="showCreatedBox"
        @close="showCreatedBox = false"
    />

    <!-- Вікно перегляду картки завдання -->
    <viewBox 
        v-if="showViewBox.show == true"
        @close="showViewBox.show = false"
        :objCard="this.showViewBox.obj"
    />
    
</template>

<script>
import createBox from '@/views/software-testing/created_testing/index' // картка створення завдання qa
import viewBox from '@/views/software-testing/view_testing/index' // картка створення завдання qa

export default {
    props: [],
    components: {
        createBox,
        viewBox
    },
    data() {
        return {
            showCreatedBox: false, // прапорець відображення вікна створення
            showViewBox: {
                show: false, // прапорець відображення вікна картки
                obj: '' // дані
            },
            formTest: {
                testing: [
                    // {
                    //     id: "23",
                    //     title: "Тестування пРРО в пос-терміналі"
                    // },
                    // {
                    //     id: "233",
                    //     title: "Тестування РРО в пос-терміналі"
                    // }
                ]
            }
        }
    },
    methods: {
        open(e){
            // Відкриття картки

            this.showViewBox.obj = e
            this.showViewBox.show = true


        }
    }
}
</script>
