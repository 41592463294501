<template>
    <!-- 
        Модальне вікно для відображення картки товару 
    -->

    <modal 
        :title="form.nomenclatureName" 
        :stylebody="'var(--vz-body-bg)'"
    >
        <!-- Верхня частина модального вікна з кнопкою закриття -->
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <!-- Основна частина модального вікна з інформацією про товар -->
        <template v-slot:body>
            <b-card>
                <b-row>
                    <!-- Ліва частина зображення товару -->
                    <b-col lg="3">
                        <div class="product-img-slider sticky-side-div">
                            <!-- тут має бути слайдер -->
                        </div>
                    </b-col>

                    <!-- Права частина інформації про товар -->
                    <b-col lg="9">
                        <div class="mt-xl-0 mt-5">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <!-- Назва товару -->
                                    <h4>{{form.nomenclatureName}}</h4>
                                    <!-- Інформація про виробника, постачальника та категорію товару -->
                                    <div class="hstack gap-3 flex-wrap">
                                        <div>
                                            <a class="text-primary d-block">
                                                {{form.manufacturerName}}
                                            </a>
                                        </div>

                                        <div class="vr"></div>

                                        <div class="text-muted">
                                            {{$t('Provider')}} :
                                            <!-- Посилання на постачальника з можливістю відкриття деталей -->
                                            <span 
                                                @click="opencounterparty"
                                                class="text-body fw-medium"
                                                style="cursor: pointer;"
                                                >{{ nameProvider(form.counterpartyId) }}</span
                                            >
                                        </div>
                                        <div class="vr"></div>

                                            <!-- Відображення категорії товару -->
                                            <div class="text-muted">
                                                {{$t('Categories')}} :
                                                <span class="text-body fw-medium">{{ nameCategories(form.productsCategoryId) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Інформація про ціни та прибуток -->
                            <div class="row mt-4">
                                <!-- Ціна постачальника -->
                                <div class="col-lg-4 col-sm-6" v-if="perms[1304]">
                                    <div class="p-2 border border-dashed rounded">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar-sm me-2">
                                                <div 
                                                    class="avatar-title rounded bg-transparent text-success fs-24"
                                                >
                                                </div>
                                            </div>
                                            <div class="flex-grow-1">
                                                <p class="text-muted mb-1">{{ $t('priceProvider') }}:</p>
                                                <!-- Відображення ціни постачальника з урахуванням валюти -->
                                                <h5 class="mb-0 text-success fw-semibold">
                                                    {{(form.providerPrice * currency.course).toFixed(2)}} {{ currency.iso }}
                                                    <span class="text-muted fw-normal fs-14">( {{ form.providerPrice }} {{ currency.isoenter }} )</span>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Ціна продажу -->
                                <div class="col-lg-4 col-sm-6" >
                                    <div class="p-2 border border-dashed rounded">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar-sm me-2">
                                                <div
                                                    class="avatar-title rounded bg-transparent text-success fs-24"
                                                >
                                                    <i :class="`bx bxs-dollar-circle`"></i>
                                                </div>
                                            </div>
                                            <div class="flex-grow-1">
                                                <p class="text-muted mb-1">{{ $t('price') }}:</p>
                                                <!-- Відображення ціни продажу з урахуванням валюти -->
                                                <h5 class="mb-0 text-success fw-semibold">
                                                    {{(form.price * currency.course).toFixed(0)}} {{ currency.iso }}
                                                    <span class="text-muted fw-normal fs-14">( {{ form.price }} {{ currency.isoenter }} )</span>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Прибуток -->
                                <div class="col-lg-4 col-sm-6" v-if="perms[1304]">
                                    <div class="p-2 border border-dashed rounded">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar-sm me-2">
                                                <div
                                                class="avatar-title rounded bg-transparent text-success fs-24"
                                                >
                                                <i :class="`bx bxs-dollar-circle`"></i>
                                                </div>
                                            </div>
                                            <div class="flex-grow-1">
                                                <p class="text-muted mb-1">{{ $t('Profit') }}:</p>
                                                <!-- Відображення прибутку з урахуванням валюти -->
                                                <h5 class="mb-0 text-success fw-semibold">
                                                    {{((form.price - form.providerPrice) * currency.course).toFixed(2) }} {{ currency.iso }} 
                                                    <span class="text-muted fw-normal fs-14">( {{ (form.price - form.providerPrice).toFixed(2) }} {{ currency.isoenter }} )</span>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Опис товару -->
                            <div class="mt-4 text-muted">
                                <h5 class="fs-14">{{ $t('desc') }} :</h5>
                                <!-- Відображення HTML-опису товару -->
                                <p class="text-muted" v-html="form.description"></p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </template>
        
        <!-- Нижня частина модального вікна з кнопками опцій -->
        <template v-slot:footer-bottom>
            
            <!-- Кнопка видалення товару (за умови наявності відповідних прав) -->
            <button 
                type="button" 
                v-if="this.perms[1303] == true && form.nomenclatureId != 211"
                @click="dialogShow = true"
                class="btn btn-danger r waves-effect waves-light">
                {{ $t('Delete') }}
            </button>

            <!-- Кнопка редагування товару (за умови наявності відповідних прав) -->
            <button 
                v-if="this.perms[1302] == true"
                type="button" 
                @click="edit(this.form)"
                class="btn btn-warning r waves-effect waves-light">
                {{ $t('Edit') }}
            </button>

            <!-- Кнопка редагування товару (за умови наявності відповідних прав) -->
            <button 
                v-if="this.perms[1302] == true"
                type="button" 
                @click="copyProduct(this.form)"
                class="btn btn-info r waves-effect waves-light">
                {{ $t('Copy') }}
            </button>

       </template>
    </modal>

    <!-- Додатковий компонент для відображення картки постачальника -->
    <viewbox 
        v-if="showModal == true" 
        @close="showModal = false" 
        :objcard="objprovider" 
        @remove="remove"
    ></viewbox>

    <!-- Модальне вікно підтвердження видалення товару -->
    <modalremove 
        v-if="dialogShow" 
        @close="dialogShow = false" 
        @remove="remove"
    />
   
</template>

<script>
import modal from '@/components/modal'
import { ref } from 'vue';
import { Thumbs } from 'swiper';
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import viewbox from '@/views/ecommerce/providers/view'
import modalremove from '@/components/modal/modalremove.vue'
import { ProvidersProducts, CategoriesProducts, Products } from '@/API.js';
import { storeS } from '@/store.js'
SwiperCore.use([Pagination, Navigation]);

let apiServe = new ProvidersProducts();
let apiCategory = new CategoriesProducts();
let apiProducts = new Products();

export default {
    data() {
        return {
            form:{ // Об'єкт, що містить дані про товар
                provider: "",
                category: ""
            },
            dialogShow: false, // Прапорець відображення вікна підтвердження видалення
            objprovider: {}, // Об'єкт, що містить дані про постачальника для додаткового вікна
            showModal: false, // Прапорець відображення вікна картки постачальника
            currency: { // Об'єкт, що містить інформацію про валюту
                isoenter: '',
                iso: '',
                course: ''
            },
        };
    },
    props: ["objcard", 'ifPrevopen', 'ifNextopen'],
    components: {
        modal,
        Swiper,
        SwiperSlide,
        viewbox,
        modalremove
    },
    created() {

        // Завантаження даних про товар та інші ініціалізації
        this.getData();

        // Визначення валютних параметрів
        if(this.currencyShop.enteringTheCostOfGoods == 'USD' && this.currencyShop.conclusionOfGoods == 'UAH'){
            this.currency.isoenter = '$';
            this.currency.iso = '₴';
            this.currency.course = this.currencyShop.course.USD
        } else if(this.currencyShop.enteringTheCostOfGoods == 'USD' && this.currencyShop.conclusionOfGoods == 'USD'){
            this.currency.isoenter = '$';
            this.currency.iso = '$';
            this.currency.course = 1;
        }
    },
    setup() {
      const thumbsSwiper = ref(null);
      const setThumbsSwiper = (swiper) => {
        thumbsSwiper.value = swiper;
      };
      return {
        Thumbs,
        thumbsSwiper,
        setThumbsSwiper,
      };
    },
    methods: {
        copyProduct(form) {
            this.$emit('copyProduct', form)
        },
        getData(){
            // Отримання даних про товар
            this.form = this.objcard
        },
        opencounterparty(){
            apiServe.getProvider(this.form.counterpartyId).then(result => {
                if(result.status == 'done'){
                    this.objprovider = result.data;
                    this.showModal = true;
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        nameProvider(item){
            // Отримання імені постачальника за його ідентифікатором

            var name = "";
            this.providers.forEach((el) => {
                if(el.providerId === item) {
                    name = el.name
                }
            })
            return name
        },
        nameCategories(item){
            // Отримання імені категорії за її ідентифікатором

            var name = "";
            this.productCategories.forEach((el) => {
                if(el.categoryId === item) {
                    name = el.categoryName
                }
            })
            return name
        },
        remove(){
            // Видалення товару

            apiProducts.deleteProduct(this.form.nomenclatureId).then(result => {
                if(result.status == 'done'){
                    this.$emit('close');
                    this.$toast.success(this.$t('Removed'));
                    this.eventBus.emit('saveProduct', true)
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        edit(e) {
            // Відкриття вікна редагування товару
            this.$emit('edit', e)
            this.$emit('close')
        },
        prevOpen(){
            // Відкриття попереднього товару
            this.$emit('close');
            this.$emit('prevOpen', this.form.id)
        },
        nextOpen(){
            // Відкриття наступного товару
            this.$emit('close');
            this.$emit('nextOpen', this.form.id)
        },
    },
    mounted() {
    },
    computed:{
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        user(){
            /*
                Повертає дані користувача з глобального стору.
            */
            return storeS.userbase
        },
        productCategories(){
            /*
                Повертає дані категорій з глобального стору.
            */
            return storeS.productCategories
        },
        providers(){
            /*
                Повертає дані постачальників з глобального стору.
            */
            return storeS.providers
        },
        currencyShop(){
            /*
                Повертає дані налаштувань валюти та курсу з глобального стору.
            */
            return storeS.currencyShop
        }
    }
}
</script>