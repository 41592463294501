<template>
    <!-- 
        Компонент, який відповідає за відображення та завантаження файлів 
    -->

    <b-card>
        <!-- Заголовок блоку -->
        <div class="d-flex mb-3 border-bottom pb-3" v-if="!this.title">
            <h5 class="card-title fs-18 fw-semibold mb-0 flex-grow-1">{{ $t('Files') }}</h5>
        </div>

        <div class="d-flex mb-3 border-bottom pb-3" v-else>
            <h5 class="card-title fs-18 fw-semibold mb-0 flex-grow-1">{{ this.title }}</h5>
        </div>

        <!-- Тіло блоку -->
        <div class="card-body">

            <!-- Кнопка для збереження вибраних файлів -->
            <button 
                v-if="selectedFiles.length > 0"
                @click="saveFile" 
                type="button" 
                class="btn btn-success btn-md mt-1 mb-2" 
            >
                <i class="ri-plus-line me-1 align-bottom" ></i>{{ $t('save') }}
            </button>

            <!-- Компонент для вибору та завантаження файлів -->
            <file-pond
                :key="this.componentKey"
                name="test"
                ref="pond"
                class="mb-3"
                style="cursor: pointer;"
                label-idle="Drop files here..."
                v-bind:allow-multiple="true"
                accepted-file-types="image/jpeg, image/png"
                v-on:init="handleFilePondInit"
                @processfile="onProcessFile" 
                @addfile="onAddFile"
                @removefile="removefile"
                v-on:removefile="onRemoveFileClick"
                v-bind:files="myFiles"
            />

            <!-- Відображення прикріплених файлів, якщо вони є -->
            <div v-if="fromUpload == 'filemanager' && filesData.length > 0">
                <span class="mt-4 pt-4 fw-semibold fs-16">{{ $t('attachedFiles') }}</span>

                <!-- Перебір файлів та їх відображення -->
                <template v-for="(item, index) in this.filesData" :key="index">
                    <div class="d-flex align-items-center border border-dashed p-2 rounded mb-2 mt-3" >
                        <!-- Відображення іконки файлу -->
                        <div class="avatar-sm copy" >
                            <div :class="`avatar-title bg-light text-${this.mutateImg(item.type).color} rounded fs-24`">
                                <i :class="this.mutateImg(item.type).img"></i>
                            </div>
                        </div>

                        <!-- Інформація про файл -->
                        <div class="flex-grow-1 ms-3 copy" >
                            <h6 class="mb-1"><a>{{ item.filename }}</a></h6>
                            <span class="text-muted">{{ $t('desc') }}: </span><span class="text-muted description" v-html=" item.description != '' ? item.description : '-' "></span>
                        </div>

                        <div class="hstack gap-3 fs-16" >
                            <!-- 
                                Кнопка для зміни назви та додавання опису файлу 
                                - відкриває вікно для зміни назви
                            -->
                            <button 
                                type="button" 
                                @click="showRenameBox = true; selectedItem.ind = index; selectedItem.item = item" 
                                class="btn btn-soft-info btn-icon btn-sm fs-16 close-btn-email" 
                                title="змінити назву та додати опис" 
                            >  
                                <i class="bx bx-rename align-bottom" ></i>
                            </button>

                        </div>
                    </div>
                </template>
            </div>

            <!-- Відображення завантажених файлів -->
            <div class="d-flex align-items-center border border-dashed p-2 rounded mb-2 mt-3" v-for="item in objFiles" :key="item" >
                <!-- Відображення іконки файлу -->
                <div class="avatar-sm copy" @click="viewFile(item)">
                    <div :class="`avatar-title bg-light text-${this.mutateImg(item.type).color} rounded fs-24`">
                        <i :class="this.mutateImg(item.type).img"></i>
                    </div>
                </div>

                <!-- Інформація про файл -->
                <div class="flex-grow-1 ms-3 copy" @click="viewFile(item)">
                    <h6 class="mb-1"><a>{{ item.name }}</a></h6>
                    <small class="text-muted">{{ item.datetime }}</small>
                </div>
                
                <!-- Кнопки для завантаження та видалення файлу -->
                <div class="hstack gap-3 fs-16">
                    <a class="text-muted copy" @click="getFile(item)"><i class="ri-download-2-line"></i></a>
                    <a class="text-muted copy" @click="showRemDialog(item)"><i class="ri-delete-bin-line"></i></a>
                </div>
            </div>
        </div>
    </b-card>

    <!-- Модальне вікно для підтвердження видалення файлу -->
    <modalremove 
        v-if="dialogShow"
        @close="dialogShow = false" 
        @remove="this.deleteFile(gFile)" 
    />

    <!-- Компонент для зміни назви файлу -->
    <renameBox 
        v-if="showRenameBox"
        @close="showRenameBox = false"
        @renameFile="renameFile"
        :objItem="selectedItem"
    />

</template>

<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// Ініціалізація компоненту FilePond
const FilePond = vueFilePond(
  FilePondPluginImagePreview
);

import modalremove from '@/components/modal/modalremove' // компонент діалогового вікна видалення
import renameBox from './rename' // компонент вікна редагування назви та опису
import { mutateImgFiles } from '@/usabilityScripts/globalMutate.js'
import { storeS } from '@/store'

export default{
    props: ['topic','title', 'obj', 'objFiles', 'fileFromCtrlV','fromUpload', 'numberIdx'],
    components: {
        modalremove,
        FilePond,
        renameBox
    },
    data(){
        return{
            componentKey: 0, // ключ компонента
            myFiles: [], // Масив для зберігання вибраних файлів
            file: '', // Файл
            files: [], // Масив для зберігання всіх файлів
            selectedFiles: [], // Масив для зберігання вибраних файлів
            gFile: { // Дані про обраний файл
                fileId: '',
                src: '',
                type: ''
            },
            // Прапореці для навігації між зображеннями
            ifNextimg: false,
            ifPrevimg: false,
            dialogShow: false, // Прапорець для відображення діалогового вікна видалення файлу
            showRenameBox: false, // Прапорець для відображення діалогового вікна перейменування файлу
            selectedItem: { // Дані обраного елемента для перейменування
                ind: '',
                item: ''
            },
            filesData: [] // Дані про файли
        }
    },
    created(){
        // Завантаження початкових даних
        this.getdata();

        // Додавання файлу за допомогою комбінації "вставити" (cmd+v)
        console.log(this.fileFromCtrlV)
        if(this.fileFromCtrlV) {
            const reader = new FileReader()
            this.myFiles.push({source: this.fileFromCtrlV, options: {}})
        }
        console.log("files", this);
        
    },
    methods: {
        getdata(){
            // Отримання даних
            this.files = this.objFiles
        },
        getFile(e){
            // Отримання файлу
            this.$emit('downloadFile', e)
        },
        deleteFile(e){
            // Видалення файлу

            this.dialogShow = false
            this.$emit('deleteFile', e)
        },
        viewFile(e){
            // Перегляд файлу

            if(e.type == 'image/jpeg' || e.type == 'image/png' || e.type == 'image/heic' || e.type == 'video/quicktime' || e.type == 'video/mp4'){
                this.$emit('getFile', e)
            }   else {
                this.$toast.error(this.$t('fileNotSupported'));
            }
        },
        showRemDialog(e){
            // Відображення діалогового вікна видалення файлу

            this.gFile.fileId = e.fileId
            this.dialogShow = true;
        },
        mutateImg(e) {
            // Функція для отримання кольору та іконки файлу

            return mutateImgFiles(e)
        },
        onProcessFile(error, file) {
            // Обробник події обробки файлу
            // console.log('file processed', { error, file })
        },
        onAddFile(error, file) {
            /* 
                Якщо робити вставку картинки в чат, то летіло в файли. Скрипт від цього.
                Перевіряємо чи ми на вкладці з файлами, якщо ні, то видаляємо зображення 
            */

            console.log('tabindex', this.tabIndex, this.tabIndex != 6)
            if(this.tabIndex != 6) {
                // Блок на завантаження файлів
                this.myFiles = []
                this.componentKey++
            } else {
                if(this.topic === "checks") {
                    console.log(error, file, )
                    if(file.fileExtension !== 'json') {
                        this.$toast.error(this.$t('FileIsntJSON'))
                        file.abortLoad()
                    } else if(file.fileExtension === 'json') {
                        if(file.fileSize > 5000000) {
                            this.$toast.error(this.$t('FileBiggerThan5MB'))
                            file.abortLoad()
                        } else if(this.selectedFiles.length > 0) {
                            this.$toast.error(this.$t('FileMustBeOne'))
                            file.abortLoad()
                        } else {

                            // Якщо ні одна з умов не стопнула виконання функції
                            this.selectedFiles.push(file);
                            var metaFile = {
                                filename: file.filename,
                                description: '',
                                type: file.fileType,
                                id: file.id
                            }
                            this.filesData.push(metaFile)
                        }
                    }
                } else {
                    // Якщо не чеки, то робимо дефолтний сценарій
                    this.selectedFiles.push(file);
                    var metaFile = {
                        filename: file.filename,
                        description: '',
                        type: file.fileType,
                        id: file.id
                    }
                    this.filesData.push(metaFile)
                }
            }
        },
        onRemoveFileClick(error, file){
            // Обробник події видалення файлу

            // Видалення файлу зі списку обраних
            for(var item in this.selectedFiles){
                if(this.selectedFiles[item].file.name == file.file.name){
                    this.selectedFiles.splice(item, 1)
                }
            }
        },
        saveFile(){
            // Збереження обраних файлів
            console.log('saveFile');

            let formData = new FormData();
            var filesDataArray = [];

            for (let i = 0; i < this.selectedFiles.length; i++) {
                let file = this.selectedFiles[i].file

                // Додавання файлу до форми
                formData.append('files[]', file, this.filesData[i].filename)
                
                // Підготовка даних для відправки на сервер
                var data = {
                    filename: this.filesData[i].filename,
                    description: this.filesData[i].description
                }
                filesDataArray.push(data)

                console.log("debug", file, data)
            }
            // Очищення списку обраних файлів
            this.files = [];
            this.selectedFiles = [];

            // Додавання метаданих файлів до форми

            console.log("filesDataArray", filesDataArray)
            formData.append("filesData", JSON.stringify(filesDataArray))

            for (var key of formData.entries()) {
                console.log("debug", key[0] + ', ' + key[1]);
            }
            // Очищення FilePond
            this.$refs.pond.removeFiles()

            // Відправлення події з параметрами
            this.$emit('uploadFiles', this.obj, formData);
        },
        removefile(error, file){
            // Видалення файлу з масиву даних
            for(var item in this.filesData){
                if(this.filesData[item].id == file.id){
                    this.filesData.splice(item, 1)
                }
            }
        },
        renameFile(i, e){
            // Перейменування файлу
            this.filesData[i] = e
        }
    },
    computed:{
        tabIndex() {
            return storeS.tabIndex
        }
    }
}

</script>

<style>
span.text-muted.description > *{
    margin: 0 !important;
}
</style>