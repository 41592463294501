<template>

    <!-- компонент таблицы -->
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        @getdata="getdata"
        @changelimit="changelimit"
        @changeFilter="changeFilter" 
        @open="open"
    />

    <!-- создание услуги -->
    <!-- <created 
        v-if="showModalAdd" 
        @close="showModalAdd = false"
        :number="number"
    ></created> -->

    <!-- карточка точки -->
    <!-- <viewcard  
        v-if="showModal == true" 
        @close="showModal = false" 
        :objcard="objcard"
        @remove="remove"
        @toEdit="toEdit"
    /> -->

    <viewRequest 
        v-if="showRequest"
        @close="closeOne"
        :shortapplic="objcard"
    />

    <viewTask 
        v-if="showTask"
        @close="closeOne"
        :shortapplic="objcard"
    />

    <viewPresent 
        v-if="showPresent"
        @close="closeOne"
        :shortapplic="objcard"
    />

    <viewTickets 
        v-if="showTicket"
        @close="closeOne"
        :obj="objcard"
    />

</template>

<script>
import tablecustom from '@/components/globaltable/index';
// import viewRequest from '@/views/application/view/viewapplic'
// import viewTask from '@/views/tasks/view/viewtask'
// import viewPresent from '@/views/presentations/view/index'
// import viewTickets from '@/views/newtickets/view/index'
// import created from '@/views/services/createservice'
// import viewcard from '@/views/services/view'
// import infoblocks from '@/components/info/blocks'
import { Clients, Applications, Presentations, Tasks, Tickets, Dumps, ColdCalls } from '@/API'
import { storeS } from '@/store';
import { mutateTypeHistoryAcc } from '@/usabilityScripts/globalMutate.js'

let apiServe = new Clients();
let apiApplications = new Applications();
let apiPresentations = new Presentations();
let apiTasks = new Tasks();
let apiTickets = new Tickets();
let apiDumps = new Dumps()
let apiCold = new ColdCalls()

export default {
    props: ['number', 'client'],
    components: { 
        tablecustom, 
        // viewTickets,
        // viewTask,
        // viewPresent,
        // viewRequest,
        // infoblocks,
        // viewcard,
        // created
    },
    data() {
        return {
            form: {},
            // showRequest: false,
            // showTask: false,
            // showPresent: false,
            // showTicket: false,
            showModal: false,
            showModalAdd: false,
            infoblockShow: false,
            objcard: '',
            objPages: "",
            dataParams: {
                status: true,
                page: "history"
            },
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                type: '',
                pr1: '',
                pr2: '',
                company: ''
            },
            rows: [],
            columns: [
                {
                    name: 'ID',
                    text: "id",
                    align: "left",
                    width: 300,
                    status: true,
                },
                {
                    name: this.$t("type"),
                    text: "type",
                    align: "left",
                    width: 200,
                    status: true,
                    mutate: (item) => this.mutateType(item).name,
                    mutateClass: (item) => this.mutateType(item).color
                },
                {
                    name: this.$t("createDate"),
                    text: "createDatetime",
                    align: "right",
                    width: 200,
                    status: true,
                },
                {
                    name: this.$t("closeDatetime"),
                    text: "closeDatetime",
                    align: "right",
                    width: 200,
                    status: true,
                    mutate: (item) => item == null ? '-' : item,
                },
            ],
            information: [
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info"
                },
                {
                    title: this.$t('successfully'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success"
                },
                {
                    title: this.$t('notSuccessful'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger"
                },
            ]
        }
    },
    created(){
        this.getdata()
        console.log(this.company)
    },
    methods: {
        getdata(page){
            apiServe.getAccountHistory(this.number).then(result => {
                if(result.status == 'done') {
                    this.rows = result.data.items
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t('error'));
                }
            })
        },
        open: function(e){
            switch(e.type){
                case "request":
                    apiApplications.getRequest(e.id).then(result => {
                        if(result.status == 'done'){
                            this.objcard = result.data;
                            this.eventBus.emit('modalInModal', {
                                status: true,
                                data: this.objcard,
                                type: e.type,
                                closeOne: true,
                                formForReturnInClientCard: this.client
                            })
                        } else {
                            this.$toast.error(this.$t('err'));
                        }
                    })
                break;
                case "requestByCall":
                    apiApplications.getRequest(e.id).then(result => {
                        if(result.status == 'done'){
                            this.objcard = result.data;
                            this.eventBus.emit('modalInModal', {
                                status: true,
                                data: this.objcard,
                                type: e.type,
                                closeOne: true,
                                formForReturnInClientCard: this.client
                            })
                        } else {
                            this.$toast.error(this.$t('err'));
                        }
                    })
                break;
                case "call":
                    apiApplications.getRequest(e.id).then(result => {
                        if(result.status == 'done'){
                            this.objcard = result.data;
                            this.eventBus.emit('modalInModal', {
                                status: true,
                                data: this.objcard,
                                type: e.type,
                                closeOne: true,
                                formForReturnInClientCard: this.client
                            })
                        } else {
                            this.$toast.error(this.$t('err'));
                        }
                    })
                break;
                case "task":
                    apiTasks.getTask(e.id).then(result => {
                        if(result.status == 'done'){
                            this.objcard = result.data;
                            this.eventBus.emit('modalInModal', {
                                status: true,
                                data: this.objcard,
                                type: e.type,
                                closeOne: true,
                                formForReturnInClientCard: this.client
                            })
                        } else {
                            this.$toast.error(this.$t('err'));
                        }
                    })
                break;
                case "presentation":
                    apiPresentations.getCard(e.id).then(result => {
                        if(result.status == 'done'){
                            this.objcard = result.data;
                            this.eventBus.emit('modalInModal', {
                                status: true,
                                data: this.objcard,
                                type: e.type,
                                closeOne: true,
                                formForReturnInClientCard: this.client
                            })
                        } else {
                            this.$toast.error(this.$t('err'));
                        }
                    })
                break;
                case "ticket":
                    apiTickets.getTicket(e.id).then(result => {
                        if(result.status == 'done'){
                            this.objcard = result.data;
                            this.eventBus.emit('modalInModal', {
                                status: true,
                                data: this.objcard,
                                type: e.type,
                                closeOne: true,
                                formForReturnInClientCard: this.client
                            })
                        } else {
                            this.$toast.error(this.$t('err'));
                        }
                    })
                break;
                case "dump":
                apiDumps.getDump(e.id).then(result => {
                        if(result.status == 'done'){
                            this.objcard = result.data;
                            this.eventBus.emit('modalInModal', {
                                status: true,
                                data: this.objcard,
                                type: e.type,
                                closeOne: true,
                                formForReturnInClientCard: this.client
                            })
                        } else {
                            this.$toast.error(this.$t('err'));
                        }
                    })
                break;
                case "coldCall":
                apiCold.getCall(e.id).then(result => {
                        if(result.status == 'done'){
                            this.objcard = result.data;
                            this.eventBus.emit('modalInModal', {
                                status: true,
                                data: this.objcard,
                                type: 'coldRequest',
                                closeOne: true,
                                formForReturnInClientCard: this.client
                            })
                        } else {
                            this.$toast.error(this.$t('err'));
                        }
                    })
                break;
            }
            // apiServe.getCardService(e.nomenclatureId).then(result => {
            //     if(result.status == 'done') {
            //         this.objcard = result.data
            //         this.showModal = true
            //     }
            // })
        },
        searchB(e){
            this.objParams.search = e
            this.getdata()
        },
        changelimit: function(limit){
            this.objParams.pagelimit = limit;
            this.getdata();
        },
        changeFilter(name, value){
        if(!name.type && value != undefined){
            if(name == 'pr'){
                this.objParams['pr1'] = value[0];
                this.objParams['pr2'] = value[1];
            } else {
                this.objParams[name] = value;
            }
            this.getdata();
            }
        },
        mutateType(e){
            return mutateTypeHistoryAcc(e)
        },
    },
    mounted(){
        
    },
    computed: {
        checks(){
            return storeS.checks
        },
        perms(){
            return storeS.perms
        }
    }
}
</script>