<template>
    <!-- 
        Модальне вікно створення товару 
    -->

    <modal :title="$t('Product')" :stylebody="'var(--vz-body-bg)'">
        <template v-slot:head-bottom>
            <!-- Кнопка для закриття модального вікна -->
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <template v-slot:body>
            <!-- Основна частина форми для створення товару -->
            <b-row>
                <b-col
                    lg="9"
                    md="6"    
                >
                    <!-- Блок із заголовком та полем для введення назви товару -->
                    <b-card>
                        <div class="mb-3">
                            <h5 class="kb-title">
                                <b>{{ $t('productTitle') }}</b>
                            </h5>
                            <!-- Поле для введення назви товару -->
                            <input
                                type="text"
                                class="form-control"
                                id="project-title-input"
                                :placeholder="$t('productTitle')"
                                v-model="form.nomenclatureName"
                            />
                        </div>
                        <!-- Блок із заголовком та редактором для опису товару -->
                        <div class="mb-3">
                            <label class="form-label">{{ $t('productDescription') }}</label>
                            <!-- Quill редактор для введення опису товару -->
                            <QuillEditor v-model:content="form.description" theme="snow" contentType="html" toolbar="full"/>
                        </div>
                    </b-card>

                    <!-- Вкладки для різних параметрів товару -->
                    <b-tabs pills>

                        <!-- Блок з основними параметрами товару -->
                        <b-tab :title="$t('main')" active>
                            <div style="margin:20px 0px;">
                                <b-card>
                                    <div class="card-body">
                                         <!-- Поля для введення інформації про виробника -->
                                        <div class="mb-3">
                                            <label class="form-label" for="manufacturer-name-input">
                                                {{ $t('ManufacturerName') }}
                                            </label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="manufacturer-name-input"
                                                v-model="form.manufacturerName"
                                                :placeholder="$t('EnterManufacturerName')"
                                            />
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="mb-3">
                                                    <label class="form-label" for="manufacturer-brand-input"
                                                        >{{ $t('ManufacturerBrand') }}</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="manufacturer-brand-input"
                                                        v-model="form.manufacturerTrademark"
                                                        :placeholder="$t('EnterManufacturerBrand')"
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-6">
                                                <!-- Блок із полями для введення ціни товару -->
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <div class="mb-3">
                                                            <label class="form-label" for="product-price-input">
                                                                {{ $t('priceProvider') }}
                                                            </label>

                                                            <!-- Поле для введення ціни товару у валюті постачальника -->
                                                            <div class="input-group mb-2">
                                                                <span
                                                                    class="input-group-text"
                                                                    id="product-price-addon"
                                                                >
                                                                    {{ this.currency.isoenter }}
                                                                </span>
                                                                <input
                                                                    type="number"
                                                                    class="form-control"
                                                                    id="product-price-input"
                                                                    :placeholder="$t('enterPrice')"
                                                                    v-model="form.providerPrice"
                                                                    aria-label="Price"
                                                                    aria-describedby="product-price-addon"
                                                                />
                                                            </div>

                                                            <!-- Виведення ціни товару в основній валюті -->
                                                            <span class="text-muted fs-12" v-if="form.providerPrice != ''">
                                                                {{ form.providerPrice }} {{ currency.isoenter }} -> 
                                                                    <span class="text-success fw-semibold">
                                                                        {{ (form.providerPrice * currency.course).toFixed(2) }} {{ currency.iso }}
                                                                    </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="mb-3">
                                                            <label class="form-label" for="product-price-input"
                                                                >{{ $t('price') }}</label
                                                            >
                                                            <!-- Поле для введення ціни товару в основній валюті -->
                                                            <div class="input-group mb-2">
                                                                <span
                                                                    class="input-group-text"
                                                                    id="product-price-addon"
                                                                    >{{ this.currency.isoenter }}</span
                                                                >
                                                                <input
                                                                    type="number"
                                                                    :class="`form-control ${Number(form.price) >= (Number(form.priceprovider)+(Number(form.priceprovider)*50/100)) ? 'text-success' : 'text-danger'}`"
                                                                    id="product-price-input"
                                                                    :placeholder="$t('enterPrice')"
                                                                    v-model="form.price"
                                                                    aria-label="Price"
                                                                    aria-describedby="product-price-addon"
                                                                />
                                                            </div>

                                                            <!-- Виведення ціни товару у валюті постачальника -->
                                                            <span class="text-muted fs-12" v-if="form.price != ''">
                                                                {{ form.price }} {{ currency.isoenter }} -> 
                                                                <span class="text-success fw-semibold">
                                                                    {{ (form.price * currency.course).toFixed(2) }} {{ currency.iso }}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-card>
                            </div>
                        </b-tab>
                    </b-tabs>
                </b-col>
                
                <b-col
                    lg="3"
                    md="6"
                >
                    <!-- Блок для завантаження файлів -->
                    <files 
                        :obj="this.obj" 
                        :objFiles="this.obj?.files" 
                        @uploadFiles="uploadFiles"
                        @downloadFile="downloadFile"
                        @deleteFile="deleteFile"
                        @getFile="getFile"
                    />

                    <!-- Блок для введення посилання на товар -->
                    <b-card>
                        <h5 class="kb-title">
                            <b>{{ $t('linkToProduct') }}</b>
                        </h5>
                        <input type="text" class="form-control" v-model="form.storeLink">
                    </b-card>

                    <!-- Блок для вибору постачальника -->
                    <b-card>
                        <div class="card-header">
                            <h5 class="card-title mb-0">{{$t('Provider')}}</h5>
                        </div>
                        <div class="card-body">
                            <p class="text-muted mb-2">
                                <!-- Посилання для створення нового постачальника -->
                                <a @click="createdProvider()" class="link float-end text-decoration-underline">
                                    {{ $t('Add') }}
                                </a>
                                {{ $t('SelectProvider') }}
                            </p>
                            <!-- Мультівибір для вибору постачальника -->
                            <Multiselect
                                v-model="form.counterpartyId"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="providers"
                            />
                        </div>
                    </b-card>

                    <!-- Блок для вибору категорії товару -->
                    <b-card>
                        <div class="card-header">
                            <h5 class="card-title mb-0">{{$t('ProductCategories')}}</h5>
                        </div>
                        <div class="card-body">
                            <p class="text-muted mb-2">
                                <!-- Посилання для створення нової категорії -->
                                <a @click="createdCategory()" class="link float-end text-decoration-underline">
                                    {{ $t('Add') }}
                                </a>
                                {{ $t('SelectCategory') }}
                            </p>
                            <!-- Мультівибір для вибору категорії товару -->
                            <Multiselect
                                v-model="form.productsCategoryId"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="true"
                                :options="categories"
                            />
                        </div>
                    </b-card>

                    <!-- Блок для введення короткого опису товару -->
                    <b-card>
                        <div class="card-header">
                            <h5 class="card-title mb-0">{{$t('ProductShortDescription')}}</h5>
                        </div>
                        <div class="card-body">
                            <p class="text-muted mb-2">{{ $t('AddProductShortDescription') }}</p>
                            <!-- Текстова область для введення короткого опису товару -->
                            <textarea
                                class="form-control"
                                rows="3"
                                v-model="form.shortDescription"
                            ></textarea>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>

            <!-- Кнопка для додавання товару -->
            <button 
                type="button" 
                v-on:click="addproduct" 
                class="btn btn-success" 
                v-if="this.perm === false"
            >
                {{ $t('Add') }}
            </button>

            <!-- Кнопка для редагування товару -->
            <button 
                type="button" 
                @click="edit" 
                class="btn btn-success" 
                v-if="this.perm === true"
            >
                {{ $t('Edit') }}
            </button>
        </template>
    </modal>

    <!-- Компонент для створення нової категорії -->
    <createdCategory 
        v-if="modalCreatedCategoryBox == true" 
        @close="modalCreatedCategoryBox = false;getCategories();" 
    />

    <!-- Компонент для створення нового постачальника -->
    <createdProvider 
        v-if="modalCreatedProviderBox == true" 
        @close="modalCreatedProviderBox = false;getProviders();" 
    />

</template>

<script>
import { ref, watch } from "vue";
import modal from '@/components/modal'
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import DropZone from "@/components/widgets/dropZone";
import useVuelidate from "@vuelidate/core";
import createdCategory from '@/views/ecommerce/categories/create'
import createdProvider from '@/views/ecommerce/providers/create'
import files from '@/components/files/index'
import { Products, ProvidersProducts, CategoriesProducts, Attributes, productFiles } from '@/API.js';
import { storeS } from '@/store.js'

let apiServe = new Products();
let apiProviders = new ProvidersProducts();
let apiCategories = new CategoriesProducts();
let apiAttributes = new Attributes();
let apiproductFiles = new productFiles();

export default {
    data() {
        return {
            currency: { // Об'єкт для валютних параметрів
                isoenter: '',
                iso: '',
                course: ''
            },
            form:{ // Об'єкт для форми товару
                nomenclatureName: "", // назва товару
                description: "", // опис товару
                storeLink: "", // посилання в магазині
                counterpartyId: "", // id постачальника
                productsCategoryId: "", // id категорії
                shortDescription: "", // короткий опис товару
                manufacturerName: "", // виробник
                manufacturerTrademark: "", // виробник
                providerPrice: "", // ціна постачальника
                price: "", // ціна товару
                minPrice: "" // мінімальна ціна
            },
            modalCreatedCategoryBox: false, // Прапорець для відображення вікна створення категорії
            modalCreatedProviderBox: false, // Прапорець для відображення вікна створення постачальника
            providers: [], // Постачальники
            categories: [], // Категорії
            attr: [], // Атрибути
            editor: ClassicEditor,
            objParams:{ // Параметри для запиту 
                page: '1',
                pagelimit: '1000',
                search: '',
            },
        };
    },
    props: ['obj','id_provider', 'perm'],
    components: { ckeditor: CKEditor.component, modal, Multiselect, DropZone, createdCategory, createdProvider, files},
    created(){

        this.getProviders()

        // Перевірка на режим редагування
        if(this.obj || this.perm === true) {
            this.form = this.obj
        } 

        this.storeProviders();
        this.storeCategories();
        
        // Налаштування валютних параметрів
        if(this.currencyShop.enteringTheCostOfGoods == 'USD' && this.currencyShop.conclusionOfGoods == 'UAH'){
            this.currency.isoenter = '$';
            this.currency.iso = '₴';
            this.currency.course = this.currencyShop.course.USD
        } else if(this.currencyShop.enteringTheCostOfGoods == 'USD' && this.currencyShop.conclusionOfGoods == 'USD'){
            this.currency.isoenter = '$';
            this.currency.iso = '$';
            this.currency.course = 1;
        }
    },
    methods: {
        addproduct() {
            // Метод для додавання нового товару

            if (!this.form.nomenclatureName) {
                //перевіряємо чи вказана назва товару
                this.$toast.error(this.$t('EnterNameTicket'));
            }
            if (!this.form.description) {
                //перевіряємо чи вказаний опис товару
                this.$toast.error(this.$t('EnterDesc'));
            }
            if (!this.form.storeLink) {
                //перевіряємо чи вказане посилання в магазин
                this.$toast.error(this.$t('EnterstoreLink'));
            }
            if (!this.form.counterpartyId) {
                //перевіряємо чи вказаний постачальник
                this.$toast.error(this.$t('EnterProvider'));
            }
            if (!this.form.productsCategoryId) {
                //перевіряємо чи вказана категорія
                this.$toast.error(this.$t('EnterCategory'));
            }
            // if (!this.form.shortDescription) {
            //     //перевіряємо чи вказаний короткий опис товару
            //     this.$toast.error(this.$t('EnterShortDesc'));
            // }
            if (!this.form.manufacturerName) {
                //перевіряємо чи вказана назва виробника
                this.$toast.error(this.$t('EntermanufacturerName'));
            }
            if (!this.form.manufacturerTrademark) {
                //перевіряємо чи вказана марка
                this.$toast.error(this.$t('EnterManufacturerTrademark'));
            }
            if (!this.form.providerPrice) {
                //перевіряємо чи вказана ціна постачальника
                this.$toast.error(this.$t('EnterPriceProv'));
            }
            if (!this.form.price) {
                //перевіряємо чи вказана ціна продажу товара
                this.$toast.error(this.$t('EnterPrice'));
            }
            this.form.minPrice = '0'

            // Відправлення запиту для додавання товару
            if( this.form.nomenclatureName 
                && this.form.description 
                && this.form.storeLink
                && this.form.counterpartyId
                && this.form.productsCategoryId
                && this.form.shortDescription
                && this.form.manufacturerName
                && this.form.manufacturerTrademark
                && this.form.providerPrice
                && this.form.price
            ){
                apiServe.addProduct(this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('сreatedT'));
                        this.eventBus.emit('saveProduct', true)
                        this.$emit('close')
                    } else {
                        this.$toast.error(this.$t('error') + " #AddProduct");
                    }
                })
            }
        },
        createdProvider(){
            // Відображення модального вікна для створення нового постачальника
            this.modalCreatedProviderBox = true
        },
        getProviders(){
            // Отримання списку постачальників

            apiProviders.getAllProviders(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.providers = result.data.items;
                    for(var item in this.providers){
                        this.providers[item].value = this.providers[item].providerId
                        this.providers[item].label = this.providers[item].name
                    }
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        getCategories(){
            // Отримання списку категорій товарів
            
            apiCategories.getAllCategories(this.objParams).then(result => {
                if(result.status == 'done') {
                    this.categories = result.data;
                    for(var item in this.categories){
                        this.categories[item].label = this.categories[item].categoryName
                        this.categories[item].value = this.categories[item].categoryId
                    }
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        minimalprice(){
            // Обчислення мінімальної ціни товару

            let result = Number(this.form.priceprovider) + (Number(this.form.price) - Number(this.form.priceprovider)) - (Number(this.form.price) - Number(this.form.priceprovider))*20/100;
            this.form.minPrice = result.toFixed(2);
			return this.form.minPrice
        },
        uploadFiles(obj, formData){
            // Завантаження файлів

            apiproductFiles.uploadFiles(obj.nomenclatureId, formData).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('FileUploadedSuccessfully'));
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        deleteFile(e){
            // Видалення файлу

            apiproductFiles.deleteFile(e.fileId).then(result => {
                if(result.status == 'done'){
                    this.showImg = false
                    this.dialogShow = false
                    this.$toast.success(this.$t('FileDeleted'));
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        createdCategory(){
            // Відображення модального вікна для створення нової категорії товару
            this.modalCreatedCategoryBox = true
        },
        edit: function(){
            // Редагування товару

            let id = this.form.nomenclatureId
            delete this.form.nomenclatureId

            apiServe.editCategoryProducts(id, this.form).then(result=>{
                if(result.status === 'done') {
                    this.$toast.success(this.$t('alertChanged'));
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error'))
                    this.$emit('close')
                }
            })
        },
        storeCategories(){
            // Збереження категорій товару у відповідний масив

            this.stProductCategories.forEach(element => {
                this.categories.push({
                    label: element.categoryName,
                    value: element.categoryId
                })
            });
        },
        storeProviders(){
            // Збереження постачальників у відповідний масив

            this.stProviders.forEach(element => {
                this.providers.push({
                    label: element.name,
                    value: element.providerId
                })
            });
        }
    },
    setup() {
        let files = ref([]);
        let dropzoneFile = ref("");

        const drop = (e) => {
            dropzoneFile.value = e.dataTransfer.files[0];
            files.value.push(dropzoneFile.value);
        };

        const selectedFile = () => {
            dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
            files.value.push(dropzoneFile.value);
        };

        watch(
            () => [...files.value],
            (currentValue) => {
                return currentValue;
            }
        );
        return { dropzoneFile, files, drop, selectedFile, v$: useVuelidate() };
    },
    mounted(){
        // Підписка на події для оновлення списку категорій і постачальників
        this.eventBus.on('saveProductsCateg', (status) => {
            if(status == true) {
                this.getCategories();
            }
        }),
        this.eventBus.on('saveProvider', (status) => {
            if(status == true) {
                this.getProviders();
            }
        })
    },
    computed: {
        user() {
            /*
                Повертає дані користувача з глобального стору.
            */
            return storeS.userbase
        },
        currencyShop(){
            /*
                Повертає дані валюти з глобального стору.
            */    
            return storeS.currencyShop
        },
        stProductCategories(){
            /*
                Повертає дані категорій з глобального стору.
            */
            return storeS.productCategories
        },
        stProviders(){
            /*
                Повертає дані постачальників з глобального стору.
            */
            return storeS.providers
        }
    }
}
</script>

<style scoped>
.link {
    cursor: pointer;
}
</style>